import { AxiosRequestConfig } from 'axios'
import { authenticationManager } from '../services/authenticationManager'

export const configurarRequisicaoDoAxiosAsync = async (config: AxiosRequestConfig) => {
  if (!config.headers) {
    return config
  }
  config.headers.Authorization = `Bearer ${await authenticationManager.getTokenAsync()}`
  return config
}
