import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { listarAtualizacoesParaInvestidoresAsync } from '../api/listarAtualizacoesParaInvestidoresAsync'
import { deletarAtualizacaoParaInvestidoresAsync } from '../api/deletarAtualizacaoParaInvestidoresAsync'
import { criarAtualizacaoParaInvestidoresAsync } from '../api/criarAtualizacaoParaInvestidoresAsync'

type Props = {
  campanhaId?: number
}

export const useAtualizacaoParaInvestidores = (request?: Props) => {
  const queryClient = useQueryClient()

  const campanhaId = request?.campanhaId

  const queryKey = ['atualizacoes-para-investidores', campanhaId]

  const options = {
    enabled: !!campanhaId,
  }

  async function getAsync() {
    if (!campanhaId) {
      return null
    }
    const response = await listarAtualizacoesParaInvestidoresAsync(campanhaId)
    return response
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync, options)

  const mutationParaDeletar = useMutation(deletarAtualizacaoParaInvestidoresAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  const mutationParaCriar = useMutation(criarAtualizacaoParaInvestidoresAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data,
    mutationParaDeletar,
    mutationParaCriar,
  }
}
