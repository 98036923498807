import { useQuery } from '@tanstack/react-query'
import { obterSituacaoNaAdviceDoInvestidorAsync } from '../api/obterSituacaoNaAdviceDoInvestidorAsync'

export const useInvestidorSituacaoNaAdvice = (investidorId: string | undefined) => {
  const options = {
    retry: false,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterSituacaoNaAdviceDoInvestidorAsync(investidorId)
  }

  const response = useQuery(['situacao-na-advice', investidorId], getAsync, options)

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
  }
}
