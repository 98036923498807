import React, { FormEvent, useEffect, useState } from 'react'
import { useInvestidorBackoffice } from '../../store/investidorBackoffice'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Input } from '../../@beegin/react/components/Input'
import { Select } from '../../@beegin/react/components/Select'
import { Button } from '../../@beegin/react/components/Button'
import { useAuth } from '../../hooks/auth'

export function FormularioDeInvestidor() {
  const { isInAnyOfTheseRoles } = useAuth()

  let { investidorId } = useParams()

  const [form, setForm] = useState({
    origem: '',
    assessorId: '',
    statusBackoffice: 0,
    observacoesDoBackoffice: '',
  })

  const { isLoading, data: investidor, mutation } = useInvestidorBackoffice(investidorId)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const promise = mutation.mutateAsync(form)
    toast.promise(promise, {
      pending: 'Atualizando dados...',
      success: 'Dados atualizados com sucesso!',
      error: 'Erro ao atualizar dados',
    })
  }

  useEffect(() => {
    if (!investidor) {
      return
    }
    setForm({
      origem: investidor.origem,
      assessorId: investidor.assessorId,
      statusBackoffice: investidor.statusBackoffice ?? 0,
      observacoesDoBackoffice: investidor.observacoesDoBackoffice,
    })
  }, [investidor])

  if (isLoading || mutation.isLoading) {
    return (
      <PilhaDeItens gap={3} maxWidth='600px'>
        <Esqueleto height='40px' />
        <Esqueleto height='40px' />
        <Esqueleto height='40px' />
        <Esqueleto height='40px' />
        <Esqueleto height='40px' />
      </PilhaDeItens>
    )
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} maxWidth='600px'>
        <Input
          disabled={desabilitarInputs}
          label='Origem'
          value={form.origem}
          onChange={(e) => setForm({ ...form, origem: e.target.value as any as string })}
        />
        <Select
          disabled={desabilitarInputs}
          label='Status do backoffice'
          value={form.statusBackoffice}
          options={[
            { label: 'Nenhum', value: 'Nenhum' },
            { label: 'Pendente', value: 'Pendente' },
            { label: 'Em Análise', value: 'EmAnalise' },
            { label: 'Aprovado', value: 'Aprovado' },
          ]}
          onChange={(e) =>
            setForm({
              ...form,
              statusBackoffice: e.target.value as any as number,
            })
          }
        />
        <Input
          disabled={desabilitarInputs}
          multiline
          rows={10}
          label='Observações'
          value={form.observacoesDoBackoffice}
          onChange={(e) =>
            setForm({
              ...form,
              observacoesDoBackoffice: e.target.value as string,
            })
          }
        />
        <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
          Salvar
        </Button>
      </PilhaDeItens>
    </form>
  )
}
