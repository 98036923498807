import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { columns } from './columns'
import { useCampanhas } from '../../store/campanhas'
import { useState } from 'react'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { Main, Title, BlocoBranco } from './styles'

export function ListarCampanhas() {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const { isLoading, data } = useCampanhas({ page, size })

  return (
    <Menu>
      <Main>
        <Title>Campanhas</Title>
        <BlocoBranco>
          <PilhaDeItens>
            <div>
              <Link to='/campanhas/criar'>
                <Button size='small' variant='contained'>
                  Criar
                </Button>
              </Link>
            </div>
          </PilhaDeItens>
          <br />
          <Esqueleto width='100%' height='600px' active={isLoading}>
            <Tabela
              page={page}
              pageRowsSize={size}
              onPageChange={(page: number) => setPage(page)}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              rows={data ?? []}
              columns={columns}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
