import { useQuery } from '@tanstack/react-query'
import { listarCampanhasAsync } from '../api/listarCampanhasAsync'

type Props = {
  page?: number
  size?: number
  status?: string[]
}

export const useCampanhas = (request?: Props) => {
  const queryKey = ['campanhas', { ...request }]

  async function getAsync() {
    const response = await listarCampanhasAsync(request)
    return response.campanhas
  }

  const { isLoading, isError, data } = useQuery([queryKey], getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
