import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Tabela } from '../../components/Tabela'
import { EstatisticasInvestidores } from '../../components/EstatisticasInvestidores'
import { useInvestidores } from '../../store/investidores'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Select } from '../../@beegin/react/components/Select'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { obterErrorResponse } from '../../utils/obterErrorResponse'
import { columns } from './columns'
import { BlocoBranco, Main, TableTitle, Title } from './styles'
import { cpfEstaValido } from '../../@beegin/react/utils/cpfEstaValido'
import { cnpjEstaValido } from '../../@beegin/react/utils/cnpjEstaValido'
import { useAssessores } from '../../store/assessores'
import { useEscritoriosDeAssessor } from '../../store/escritoriosDeAssessor'
import { useAuth } from '../../hooks/auth'
import axios from 'axios'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import dayjs from 'dayjs'

type Form = {
  id: string
  nome: string
  email: string
  cpf: string
  cnpj: string
}

type Filtros = {
  statusDoCadastro: string[]
  escritorioId: string
  assessorId: string
  cpf: string
  cnpj: string
  id: string
  nome: string
  email: string
}

const initialState: Filtros = {
  statusDoCadastro: [],
  escritorioId: '',
  assessorId: '',
  cpf: '',
  cnpj: '',
  id: '',
  nome: '',
  email: '',
}

export function ListarInvestidores() {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [form, setForm] = useState<Form>(initialState)
  const [filtros, setFiltros] = useState<Filtros>(initialState)

  const [exportModal, setExportModal] = useState(false)

  const [selectedDate, setSelectedDate] = useState<Date | null>(dayjs().toDate())

  const cpfDigitadoEstaValido = cpfEstaValido(form.cpf)
  const cnpjDigitadoEstaValido = cnpjEstaValido(form.cnpj)

  const cpfValidoParaBusca = cpfDigitadoEstaValido ? form.cpf : ''
  const cnpjValidoParaBusca = cnpjDigitadoEstaValido ? form.cnpj : ''

  const { investidores, isLoading, isError, refetch, error } = useInvestidores({
    ...filtros,
    cnpj: cnpjValidoParaBusca,
    cpf: cpfValidoParaBusca,
    page,
    size,
  })

  const { isLoading: carregandoEscritoriosDeAssessor, data: escritoriosDeAssessor } =
    useEscritoriosDeAssessor()

  const { isLoading: carregandoAssessores, data: assessores } = useAssessores({
    size: 1000,
    escritorioId: filtros.escritorioId,
  })

  const naoHaAssessores = !filtros.escritorioId || assessores?.assessores?.length === 0

  const { token } = useAuth()
  const handleMigrationReportDownload = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_URL_API_INVESTIDORES}/migracao-de-cadastro/listar`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'relatorio-repostas-migracao.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  const handleGenialExport = async () => {
    try {
      await axios({
        url: `${process.env.REACT_APP_URL_API_INVESTIDORES}/api/genialexportacao`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          data: selectedDate,
        },
      })
      toast.success('Exportação realizada com sucesso.')
    } catch (error) {
      console.error('Error: ', error)
      toast.error(obterErrorResponse(error, 'Erro na exportação Genial.'))
    }
    setExportModal(false)
  }

  useEffect(() => {
    setPage(0)
  }, [filtros])

  useEffect(() => {
    setFiltros((x) => ({ ...x, assessorId: '' }))
  }, [filtros.escritorioId])

  useEffect(() => {
    if (!isError) {
      return
    }
    toast.error(obterErrorResponse(error, 'Erro ao listar investidores.'))
  }, [error, isError])

  useEffect(() => {
    if (cpfDigitadoEstaValido) {
      setFiltros((x) => ({ ...x, cpf: cpfValidoParaBusca }))
    }
  }, [cpfDigitadoEstaValido])

  useEffect(() => {
    if (cnpjDigitadoEstaValido) {
      setFiltros((x) => ({ ...x, cnpj: cnpjValidoParaBusca }))
    }
  }, [cnpjDigitadoEstaValido])

  useEffect(() => {
    refetch()
  }, [page, refetch])

  useEffect(() => {
    setFiltros({ ...filtros, id: form.id, nome: form.nome, email: form.email })
  }, [filtros.assessorId, filtros.escritorioId, filtros.statusDoCadastro])

  const today = new Date()

  const Modal = () => (
    <Dialog
      open={exportModal}
      onClose={() => setExportModal(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      style={{ padding: '20px' }}
    >
      <DialogTitle id='alert-dialog-title'>Exportação Genial</DialogTitle>
      <DialogContent style={{ padding: '20px' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label='Selecione uma data'
            value={selectedDate}
            onChange={(newValue: Date | null) => {
              if (newValue) {
                setSelectedDate(newValue)
              }
            }}
            format='dd/MM/yyyy'
            maxDate={today}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button style={{ backgroundColor: '#999999' }} onClick={() => setExportModal(false)}>
          Cancelar
        </Button>
        <Button style={{ backgroundColor: '#2472ff' }} onClick={handleGenialExport} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Menu>
      <Main>
        <Title>Investidores</Title>
        <EstatisticasInvestidores />
        <BlocoBranco>
          <TableTitle>Investidores</TableTitle>
          <PilhaDeItens direction='row' margin='0 0 16px 0'>
            <Input
              fullWidth
              label='ID'
              value={form.id}
              onChange={(e) => setForm({ ...form, id: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros({ ...filtros, id: form.id })
                }
              }}
            />
            <Input
              fullWidth
              label='Nome'
              value={form.nome}
              onChange={(e) => setForm({ ...form, nome: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros({ ...filtros, nome: form.nome })
                }
              }}
            />
            <Input
              fullWidth
              label='E-mail'
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros({ ...filtros, email: form.email })
                }
              }}
            />
            <Select
              multiple
              label='Status do cadastro'
              value={filtros.statusDoCadastro}
              onChange={(e: any) => setFiltros({ ...filtros, statusDoCadastro: e.target.value })}
              options={[
                { value: 'Nenhum', label: 'Nenhum' },
                { value: 'CadastroIniciado', label: 'CadastroIniciado' },
                { value: 'CadastroCompleto', label: 'CadastroCompleto' },
                { value: 'EmAnalise', label: 'EmAnalise' },
                { value: 'Aprovado', label: 'Aprovado' },
                { value: 'Revisao', label: 'Revisao' },
                { value: 'Erro', label: 'Erro' },
                { value: 'Reprovado', label: 'Reprovado' },
              ]}
            />
          </PilhaDeItens>
          <PilhaDeItens direction='row' margin='0 0 16px 0'>
            <Esqueleto width='100%' active={carregandoEscritoriosDeAssessor} height='56px'>
              <Select
                label='Escritório'
                value={filtros.escritorioId}
                onChange={(e: any) => setFiltros({ ...filtros, escritorioId: e.target.value })}
                options={[
                  { value: '', label: '-' },
                  ...(escritoriosDeAssessor?.map((item) => ({
                    value: item.id,
                    label: item.nome,
                  })) ?? []),
                ]}
              />
            </Esqueleto>
            <Esqueleto width='100%' active={carregandoAssessores} height='56px'>
              <Select
                helperText={naoHaAssessores ? 'Escritório sem assessores' : ''}
                disabled={naoHaAssessores}
                label='Assessor'
                value={filtros.assessorId}
                onChange={(e: any) => setFiltros({ ...filtros, assessorId: e.target.value })}
                options={[
                  { value: '', label: '-' },
                  ...(assessores?.assessores?.map((item) => ({
                    value: item.id,
                    label: item.nome,
                  })) ?? []),
                ]}
              />
            </Esqueleto>
          </PilhaDeItens>
          <PilhaDeItens direction='row' margin='0 0 16px 0'>
            <MaskedInput
              disabled={form.cnpj.length > 0}
              error={!cpfDigitadoEstaValido && form.cpf.length > 0}
              helperText={!cpfDigitadoEstaValido && form.cpf.length > 0 && 'CPF inválido.'}
              label='CPF'
              value={form.cpf}
              onValueChange={(e) => setForm({ ...form, cpf: e.value })}
              mascara='cpf'
            />
            <MaskedInput
              disabled={form.cpf.length > 0}
              error={!cnpjDigitadoEstaValido && form.cnpj.length > 0}
              helperText={!cnpjDigitadoEstaValido && form.cnpj.length > 0 && 'CNPJ inválido.'}
              label='CNPJ'
              value={form.cnpj}
              onValueChange={(e) => setForm({ ...form, cnpj: e.value })}
              mascara='cnpj'
            />
            <Button style={{ backgroundColor: '#2472ff' }} onClick={handleMigrationReportDownload}>
              Relatório de Respostas Migração
            </Button>
            <Button style={{ backgroundColor: '#2472ff' }} onClick={() => setExportModal(true)}>
              Exportação Genial
            </Button>
          </PilhaDeItens>
          <Esqueleto width='100%' height='600px' active={isLoading}>
            <Tabela
              page={page}
              pageRowsSize={size}
              onPageChange={(page: number) => setPage(page)}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              rows={investidores ?? []}
              columns={columns}
            />
          </Esqueleto>
        </BlocoBranco>
        <Modal />
      </Main>
    </Menu>
  )
}
