import { useQuery } from '@tanstack/react-query'
import { listarAssessoresAsync } from '../api/listarAssessoresAsync'

type Request = {
  page?: number
  size?: number
  escritorioId?: string
  nomeDoAssessor?: string
  emailDoAssessor?: string
  nomeFantasiaDoEscritorio?: string
  razaoSocialDoEscritorio?: string
}

export const useAssessores = (request: Request) => {
  const queryKey = ['assessores', request]

  const getAsync = async () => {
    return await listarAssessoresAsync(request)
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
