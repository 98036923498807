import { FormularioDeNovaCessaoDePosicao } from './formularioDeNovaCessaoDePosicao'

export const validarFormulario = (form: FormularioDeNovaCessaoDePosicao): void => {
  if (!form.investidorIdDoComprador) {
    throw new Error('O comprador deve ser selecionado.')
  }
  if (!form.investidorIdDoVendedor) {
    throw new Error('O vendedor deve ser selecionado.')
  }
  if (!form.investimentoId) {
    throw new Error('O investimento deve ser selecionado.')
  }
  if (!form.dataDaCessao) {
    throw new Error('A data de cessão deve ser selecionada.')
  }
  if (!form.valorCedido) {
    throw new Error('O valor cedido deve ser preenchido.')
  }
  if (form.investidorIdDoComprador === form.investidorIdDoVendedor) {
    throw new Error('O comprador e o vendedor não podem ser a mesma pessoa')
  }
}
