import BusinessIcon from '@mui/icons-material/Business'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import PersonIcon from '@mui/icons-material/Person'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Permissao } from '../../constants/permissoes'

type LinkDoMenu = {
  titulo: string
  icone: JSX.Element
  link: string
  permissoes: Permissao[]
}

export const linksDoMenu: LinkDoMenu[] = [
  {
    titulo: 'Campanhas',
    icone: <BusinessIcon />,
    link: '/campanhas',
    permissoes: ['Campanhas.Read', 'Campanhas.ReadWrite'],
  },
  {
    titulo: 'Investimentos',
    icone: <AttachMoneyIcon />,
    link: '/investimentos',
    permissoes: ['Intencoes.Read', 'Intencoes.ReadWrite'],
  },
  {
    titulo: 'Cessões de posição',
    icone: <MoveDownIcon />,
    link: '/cessoes-de-posicao',
    permissoes: ['Cessao.Read', 'Cessao.ReadWrite'],
  },
  {
    titulo: 'Fila de espera',
    icone: <HourglassTopIcon />,
    link: '/filas-de-espera',
    permissoes: ['FilaDeEspera.Read', 'FilaDeEspera.ReadWrite'],
  },
  {
    titulo: 'Assessores',
    icone: <ConnectWithoutContactIcon />,
    link: '/assessores',
    permissoes: ['Assessores.Read', 'Assessores.ReadWrite'],
  },
  {
    titulo: 'Investidores',
    icone: <PersonIcon />,
    link: '/investidores',
    permissoes: ['Investidores.Read', 'Investidores.ReadWrite'],
  },
  {
    titulo: 'Protocolo de investidor na Advice',
    icone: <HistoryEduIcon />,
    link: '/protocolo-de-investidor-na-advice',
    permissoes: ['Advice.Read', 'Advice.ReadWrite'],
  },
  {
    titulo: 'Informes de rendimentos',
    icone: <ContentPasteSearchIcon />,
    link: '/informes-de-rendimentos',
    permissoes: ['InformeRendimentos.Read', 'InformeRendimentos.ReadWrite'],
  },
  {
    titulo: 'Regulatório',
    icone: <InsertDriveFileOutlinedIcon />,
    link: '/documentos-de-regulamentacao',
    permissoes: ['DocumentosDeRegulamentacao.Read', 'DocumentosDeRegulamentacao.ReadWrite'],
  },
  {
    titulo: 'Usuários',
    icone: <PersonIcon />,
    link: '/usuarios',
    permissoes: ['Administrador'],
  },
]
