import styled from 'styled-components'

export const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const SemLogo = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`
