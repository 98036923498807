import { requestClient } from './beeginRequestClient'

type Request = {
  campanhaId: number
  id: number
}

export async function deletarAtualizacaoParaInvestidoresAsync({
  campanhaId,
  id,
}: Request): Promise<void> {
  await requestClient.apis.campanhaApi.delete(
    `/campanhas/${campanhaId}/atualizacoes-para-investidores/${id}`,
  )
}
