import { usuariosApi } from '../services/api'

type ObterPermissoesDoUsuarioResponse = {
  permissoes: string[]
}

export async function obterUsuarioPermissoesAsync(
  id: string,
): Promise<ObterPermissoesDoUsuarioResponse> {
  const response = await usuariosApi.get<string[]>(`/usuarios/${id}/permissoes`)
  return {
    permissoes: response.data,
  }
}
