import { useQuery } from '@tanstack/react-query'
import { listarFilasDeEsperaAsync } from '../api/listarFilasDeEsperaAsync'

type Request = {
  page: number
  size: number
  investidorId?: string
  campanhaId?: string
  id?: number
}

export const useFilasDeEspera = (request: Request) => {
  const queryKey = ['filas-de-espera', { ...request }]

  const getAsync = async () => {
    return await listarFilasDeEsperaAsync(request)
  }

  const { isLoading, isError, data, refetch, error } = useQuery(queryKey, getAsync, {
    retry: false,
  })

  return {
    isLoading,
    isError,
    data,
    refetch,
    error,
  }
}
