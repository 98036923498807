import { requestClient } from './beeginRequestClient'
import { DocumentoDeRegulamentacao } from '../types/DocumentoDeRegulamentacao'

type ObterDocumentosDeRegulamentacaoResponse = {
  documentos: DocumentoDeRegulamentacao[]
}

export async function listarDocumentosDeRegulamentacaoAsync(): Promise<ObterDocumentosDeRegulamentacaoResponse> {
  const response =
    await requestClient.apis.regulamentacoes.get<ObterDocumentosDeRegulamentacaoResponse>(
      '/api/regulamentacoes',
    )
  return response.data
}
