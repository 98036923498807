import { requestClient } from '../api/beeginRequestClient'
import {
  api,
  informeDeRendimentosApi,
  regulamentacoesApi,
  investidoresApi,
  adviceApi,
  campanhaApi,
} from '../services/api'

export const configurarRequestClient = () => {
  requestClient.apis = {
    invest: api,
    informeDeRendimentos: informeDeRendimentosApi,
    regulamentacoes: regulamentacoesApi,
    investidores: investidoresApi,
    adviceApi: adviceApi,
    campanhaApi: campanhaApi,
  }
}
