import { IterableEnum } from '../types/IterableEnum'

export const estadoCivil: IterableEnum = {
  solteiro: {
    title: 'Solteiro',
    asNumber: 1,
    asString: 'Solteiro',
  },
  casado: {
    title: 'Casado',
    asNumber: 2,
    asString: 'Casado',
  },
  separado: {
    title: 'Separado',
    asNumber: 3,
    asString: 'Separado',
  },
  divorciado: {
    title: 'Divorciado',
    asNumber: 4,
    asString: 'Divorciado',
  },
  viuvo: {
    title: 'Viúvo',
    asNumber: 5,
    asString: 'Viuvo',
  },
  uniaoEstavel: {
    title: 'União estável',
    asNumber: 6,
    asString: 'UniaoEstavel',
  },
  outros: {
    title: 'Outros',
    asNumber: 7,
    asString: 'Outros',
  },
}
