import { Endereco } from '../../types/InvestidorDTO'

export const initialState: Endereco = {
  cep: '',
  uf: '',
  cidade: '',
  pais: '',
  bairro: '',
  logradouro: '',
  enderecoNumero: '',
  complemento: '',
}
