import { useQuery } from '@tanstack/react-query'
import { listarPermissoesAsync } from '../api/listarPermissoesAsync'

export const usePermissoes = () => {
  const queryKey = ['usuario']

  const response = useQuery(queryKey, async () => {
    return await listarPermissoesAsync()
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
  }
}
