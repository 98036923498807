import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { columns } from './columns'
import { useEffect, useState } from 'react'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Main, Title, BlocoBranco } from './styles'
import { useUsuarios } from '../../store/usuarios'

type Filtros = {
  id: string
  nome: string
  email: string
}

const initialState = {
  id: '',
  nome: '',
  email: '',
}

export function ListarUsuarios() {
  const [form, setForm] = useState<Filtros>(initialState)
  const [filtros, setFiltros] = useState<Filtros>(initialState)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const { isLoading, data } = useUsuarios({
    page,
    size,
    ...filtros,
  })

  useEffect(() => {
    setPage(0)
  }, [filtros])

  return (
    <Menu>
      <Main>
        <Title>Usuários</Title>
        <BlocoBranco>
          <PilhaDeItens direction='row' mobileDirection='column' margin='0 0 16px 0'>
            <Input
              fullWidth
              label='ID'
              value={form.id}
              onChange={(e) => setForm({ ...form, id: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='Nome'
              value={form.nome}
              onChange={(e) => setForm({ ...form, nome: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='Email'
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
          </PilhaDeItens>
          <Esqueleto width='100%' height='600px' active={isLoading} doNotRenderChildrenWhenLoading>
            <Tabela
              columns={columns}
              rows={data?.usuarios ?? []}
              page={page}
              pageRowsSize={size}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              onPageChange={(page: number) => setPage(page)}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
