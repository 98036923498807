import styled from 'styled-components'

// interface IFlexProps {
//   direction?: string
//   justifyContent?: string
//   alignItems?: string
//   margin?: string
//   directionOnMobile?: string
//   justifyContentOnMobile?: string
//   alignItemsOnMobile?: string
//   marginOnMobile?: string
//   gap?: string
//   mobileGap?: string
// }

// export const Flex = styled.div<IFlexProps>`
//   display: flex;
//   flex-direction: ${(props) => props.direction ?? 'initial'};
//   justify-content: ${(props) => props.justifyContent ?? 'initial'};
//   align-items: ${(props) => props.alignItems ?? 'initial'};
//   margin: ${(props) => props.margin ?? 'initial'};
//   ${({ gap }) => gap && `gap: ${gap};`}

//   @media (max-width: 768px) {
//     flex-direction: ${(props) => props.directionOnMobile ?? 'initial'};
//     justify-content: ${(props) => props.justifyContentOnMobile ?? 'initial'};
//     align-items: ${(props) => props.alignItemsOnMobile ?? 'initial'};
//     margin: ${(props) => props.marginOnMobile ?? 'initial'};
//     ${({ mobileGap }) => mobileGap && `gap: ${mobileGap};`}
//   }
// `

interface IFlexProps {
  direction?: string
  justifyContent?: string
  alignItems?: string
  margin?: string
  directionOnMobile?: string
  justifyContentOnMobile?: string
  alignItemsOnMobile?: string
  marginOnMobile?: string
  gap?: string
  gapOnMobile?: string
  padding?: string
  paddingOnMobile?: string
  width?: string
  widthOnMobile?: string
  wrap?: string
  wrapOnMobile?: string
}

export const Flex = styled.div<IFlexProps>`
  display: flex;
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  @media (max-width: 768px) {
    ${({ directionOnMobile }) => directionOnMobile && `flex-direction: ${directionOnMobile};`}
    ${({ justifyContentOnMobile }) =>
      justifyContentOnMobile && `justify-content: ${justifyContentOnMobile};`}
    ${({ alignItemsOnMobile }) => alignItemsOnMobile && `align-items: ${alignItemsOnMobile};`}
    ${({ marginOnMobile }) => marginOnMobile && `margin: ${marginOnMobile};`}
    ${({ paddingOnMobile }) => paddingOnMobile && `padding: ${paddingOnMobile};`}
    ${({ gapOnMobile }) => gapOnMobile && `gap: ${gapOnMobile};`}
    ${({ widthOnMobile }) => widthOnMobile && `width: ${widthOnMobile};`}
    ${({ wrapOnMobile }) => wrapOnMobile && `flex-wrap: ${wrapOnMobile};`}
  }
`
