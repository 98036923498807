type Response = {
  error: boolean
  helperText: string
}

export function getErrorMessageProps(
  errors: {
    [key: string]: string
  },
  field: string,
): Response {
  const key: any = Object.keys(errors).find((k) => k.toLowerCase() === field.toLowerCase())
  if (!key) {
    return {} as Response
  }
  const error = errors[key]
  return {
    error: !!error,
    helperText: error,
  }
}
