import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editarStatusDaFilaDeEsperaAsync } from '../api/editarStatusDaFilaDeEsperaAsync'
import { obterFilaDeEsperaAsync } from '../api/obterFilaDeEsperaAsync'

export const useFilaDeEspera = (id?: string) => {
  const queryClient = useQueryClient()

  const queryKey = ['fila-de-espera', id]

  const options = {
    enabled: !!id,
  }

  async function getAsync() {
    if (!id) {
      return null
    }
    return await obterFilaDeEsperaAsync(id)
  }

  const response = useQuery(queryKey, getAsync, options)

  async function editarAsync({ status }: { status: string }) {
    if (!id) {
      return
    }
    await editarStatusDaFilaDeEsperaAsync(id, { status })
  }

  const editarStatusMutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['filas-de-espera'])
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch,
    editarStatusMutation: editarStatusMutation,
  }
}
