import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { EstatisticasAssessores } from '../../components/EstatisticasAssessores'
import { columns } from './columns'
import { useEstatisticasAssessores } from '../../store/estatisticasAssessores'
import { useEffect, useState } from 'react'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { BlocoBranco, Main, TableTitle, Title } from './styles'
import { useAssessores } from '../../store/assessores'

type Filtros = {
  nomeDoAssessor: string
  emailDoAssessor: string
  nomeFantasiaDoEscritorio: string
  razaoSocialDoEscritorio: string
}

const initialState: Filtros = {
  nomeDoAssessor: '',
  emailDoAssessor: '',
  nomeFantasiaDoEscritorio: '',
  razaoSocialDoEscritorio: '',
}

export function ListarAssessores() {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [form, setForm] = useState(initialState)
  const [filtros, setFiltros] = useState(initialState)

  const { isLoading, data } = useAssessores({ ...filtros, page, size })

  const { data: estatisticas } = useEstatisticasAssessores()

  let assessores = data?.assessores ?? []

  useEffect(() => {
    setPage(0)
  }, [filtros])

  useEffect(() => {
    setPage(0)
  }, [size])

  return (
    <Menu>
      <Main>
        <Title>Assessores</Title>
        {estatisticas && <EstatisticasAssessores data={estatisticas} />}
        <BlocoBranco>
          <TableTitle>Listagem Assessores</TableTitle>
          <PilhaDeItens direction='row' mobileDirection='column' margin='0 0 16px 0'>
            <Input
              fullWidth
              label='Nome do assessor'
              value={form.nomeDoAssessor}
              onChange={(e) => setForm({ ...form, nomeDoAssessor: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='E-mail do assessor'
              value={form.emailDoAssessor}
              onChange={(e) => setForm({ ...form, emailDoAssessor: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='Nome fantasia do escritório'
              value={form.nomeFantasiaDoEscritorio}
              onChange={(e) => setForm({ ...form, nomeFantasiaDoEscritorio: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='Razão social do escritório'
              value={form.razaoSocialDoEscritorio}
              onChange={(e) => setForm({ ...form, razaoSocialDoEscritorio: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
          </PilhaDeItens>
          <Esqueleto width='100%' height='600px' active={isLoading}>
            <Tabela
              page={page}
              pageRowsSize={size}
              onPageChange={(pageNumber: number) => setPage(pageNumber)}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              rows={assessores ?? []}
              columns={columns}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
