import styled from 'styled-components'
import { BarChart, Bar, XAxis, ResponsiveContainer, LabelList } from 'recharts'

interface BarProps {
  data: string
  cadastros: number
}

const data: BarProps[] = [
  // Add your data here
  { data: '07/05', cadastros: 25 },
  { data: '08/05', cadastros: 59 },
  { data: '09/05', cadastros: 47 },
  { data: '10/05', cadastros: 105 },
  { data: '11/05', cadastros: 50 },
  { data: '12/05', cadastros: 15 },
  { data: '13/05', cadastros: 50 },
  // ...
]

const TickerValor = styled.text`
  // font-family: Ubuntu;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: #212427;
`

const TickerData = styled.tspan`
  // font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #212427;
`

export const GraficoBarraVerticalSimples: React.FC = () => {
  const CustomXTick = (props: { x: number; y: number; payload: { value: string } }) => {
    const { x, y, payload } = props
    return (
      <text x={x} y={y} textAnchor='middle' dy={20}>
        <TickerData x={x}>{payload.value}</TickerData>
      </text>
    )
  }

  interface RenderLabelProps {
    x?: string | number
    y?: string | number
    width?: string | number
    value?: string | number
  }

  /* eslint-disable react/prop-types */
  const renderLabel = (props: RenderLabelProps) => {
    const { x, y, width, value } = props
    const radius = 16
    if (!x || !y || !width || !value) return null
    if (typeof width !== 'number') return null
    if (typeof value !== 'number') return null // Updated line
    // Verify the type of x and y before using them
    if (typeof x === 'number' && typeof y === 'number' && width) {
      return (
        <TickerValor
          x={x + width / 2}
          y={y - radius}
          fill='#666'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {value}
        </TickerValor>
      )
    }
    return null
  }

  return (
    <ResponsiveContainer width='100%' height={200}>
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: -20,
          left: -18,
          bottom: 5,
        }}
      >
        <XAxis dataKey='data' axisLine={false} tickLine={false} tick={CustomXTick} />
        <Bar barSize={30} dataKey='cadastros' fill='#0852DD' radius={16}>
          <LabelList dataKey='cadastros' content={renderLabel} position='top' />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
