/* eslint-disable react/prop-types */
import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Tabela, ColumnProps } from '../../components/Tabela'
import { useEffect, useState } from 'react'
import { Link } from '../../components/link'
import { formatarData } from '../../utils/formatarData'
import { Button } from '../../@beegin/react/components/Button'
import { useDocumentosDeRegulamentacao } from '../../store/documentosDeRegulamentacao'
import DeleteIcon from '@mui/icons-material/Delete'
import { DocumentoDeRegulamentacao } from '../../types/DocumentoDeRegulamentacao'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Flex } from '../../components/Flex'
import { toast } from 'react-toastify'
import { obterRespostaDeErro } from '../../utils/obterRespostaDeErro'
import { useMutationToast } from '../../hooks/useMutationToast'

export function ListarDocumentosDeRegulamentacao() {
  const { data, isLoading, deletarMutation } = useDocumentosDeRegulamentacao({})

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [currentPageData, setCurrentPageData] = useState<DocumentoDeRegulamentacao[] | undefined>(
    [],
  )

  useEffect(() => {
    const start = page * size
    const end = start + size
    setCurrentPageData(data?.documentos?.slice(start, end))
  }, [page, size, data])

  const [documentoId, setDocumentoId] = useState('')

  useMutationToast({
    mutation: deletarMutation,
    sucesso: 'Documento deletado.',
    erro: obterRespostaDeErro(deletarMutation.error, 'Erro ao deletar documento.'),
    carregando: 'Deletando documento...',
  })

  function selecionarDocumento(id: string): void {
    if (deletarMutation.isLoading) {
      return
    }
    toast.info('Clique novamente se deseja deletar.')
    setDocumentoId(id)
  }

  async function deletarDocumento(id: string): Promise<void> {
    if (deletarMutation.isLoading) {
      return
    }
    await deletarMutation.mutateAsync(id)
    setDocumentoId('')
  }

  const columns: ColumnProps[] = [
    { accessorKey: 'id', header: 'ID', size: 200 },
    {
      accessorKey: 'nome',
      header: 'Nome',
      cell: (props: any) => {
        return (
          <a href={props.row.original.url} target='_blank' rel='noreferrer'>
            <Flex alignItems='center'>
              {props.getValue()}
              <OpenInNewIcon sx={{ marginLeft: '4px' }} fontSize='small' />
            </Flex>
          </a>
        )
      },
    },
    {
      accessorKey: 'data',
      header: 'Data',

      cell: (props: any) => formatarData(new Date(props.getValue())),
    },
    {
      accessorKey: 'id',
      header: 'Ações',
      cell: (props: any) => {
        if (documentoId === props.getValue()) {
          return (
            <div onClick={() => deletarDocumento(props.getValue())}>
              <DeleteIcon color='error' style={{ cursor: 'pointer' }} />
            </div>
          )
        }
        return (
          <div onClick={() => selecionarDocumento(props.getValue())}>
            <DeleteIcon color='disabled' style={{ cursor: 'pointer' }} />
          </div>
        )
      },
    },
  ]

  return (
    <Menu>
      <h1>Documentos de regulamentação</h1>
      <PilhaDeItens>
        <Link to={'/documentos-de-regulamentacao/criar'}>
          <Button size='small' variant='contained'>
            Adicionar
          </Button>
        </Link>
      </PilhaDeItens>
      <br />
      <Esqueleto height='600px' active={isLoading}>
        <Tabela
          rows={currentPageData ?? []}
          columns={columns}
          rowsPerPageOptions={[10, 30, 50, 100]}
          page={page}
          pageRowsSize={size}
          onPageChange={(pageNumber: number) => setPage(pageNumber)}
          onPageSizeChange={(pageSize: number) => setSize(pageSize)}
        />
      </Esqueleto>
    </Menu>
  )
}
