import { GraficoBarraHorizontal } from '../GraficoBarraHorizontal'
import { GraficoBarraVerticalSimples } from '../GraficoBarraVerticalSimples'
import {
  BlocoBranco,
  ColunaDeBlocos,
  LinhaDeBlocos,
  Estatisticas,
  BlocoAzulClaro,
  BlocoAzulEscuro,
  TituloBloco,
  ValorBloco,
  SubtituloBloco,
  HeaderGrafico,
  TituloGrafico,
  DataGrafico,
  SubtituloGrafico,
} from './styles'

export function EstatisticasInvestidores() {
  return (
    <Estatisticas>
      <ColunaDeBlocos>
        <BlocoBranco>
          <HeaderGrafico>
            <TituloGrafico>
              Funil conversão do período
              <SubtituloGrafico>(todas as campanhas)</SubtituloGrafico>
            </TituloGrafico>
            <DataGrafico>Últimos 7 dias</DataGrafico>
          </HeaderGrafico>
          <GraficoBarraHorizontal />
        </BlocoBranco>
        <LinhaDeBlocos>
          <BlocoAzulClaro>
            <TituloBloco>
              Total de
              <br />
              Investidores <SubtituloBloco>(base total)</SubtituloBloco>
            </TituloBloco>
            <ValorBloco>23.800</ValorBloco>
          </BlocoAzulClaro>
          <BlocoAzulEscuro>
            <TituloBloco>
              Investidores
              <br />
              Ativos
            </TituloBloco>
            <ValorBloco>2.600</ValorBloco>
          </BlocoAzulEscuro>
        </LinhaDeBlocos>
      </ColunaDeBlocos>
      <ColunaDeBlocos>
        <LinhaDeBlocos>
          <BlocoAzulClaro>
            <TituloBloco>
              Investidores
              <br />
              com Assessor
            </TituloBloco>
            <ValorBloco>2.345</ValorBloco>
          </BlocoAzulClaro>
          <BlocoAzulEscuro>
            <TituloBloco>
              Cadastros
              <br />
              Completos
            </TituloBloco>
            <ValorBloco>2.500</ValorBloco>
          </BlocoAzulEscuro>
        </LinhaDeBlocos>
        <BlocoBranco>
          <HeaderGrafico>
            <TituloGrafico>Cadastros</TituloGrafico>
            <DataGrafico>Últimos 7 dias</DataGrafico>
          </HeaderGrafico>
          <GraficoBarraVerticalSimples />
        </BlocoBranco>
      </ColunaDeBlocos>
    </Estatisticas>
  )
}
