import { useQuery } from '@tanstack/react-query'
import { obterTipoDeInvestidorAsync } from '../api/obterTipoDeInvestidorAsync'

export const useTipoDeInvestidor = (investidorId?: string) => {
  const queryKey = ['tipo-de-investidor', investidorId]

  const options = {
    retry: true,
    enabled: !!investidorId,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterTipoDeInvestidorAsync(investidorId)
  }

  const response = useQuery(queryKey, getAsync, options)

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
  }
}
