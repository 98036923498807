import { useQuery } from '@tanstack/react-query'
import { obterHistoricoDoPerfilDeInvestidorAsync } from '../api/obterHistoricoDoPerfilDeInvestidorAsync'

export const useHistoricoDoPerfilDoInvestidor = (investidorId: string | undefined) => {
  const queryKey = ['historico-do-perfil-de-investidor', investidorId]

  const getAsync = async () => {
    if (!investidorId) {
      return []
    }
    const response = await obterHistoricoDoPerfilDeInvestidorAsync(investidorId)
    return response?.historicoDePerfis.map((item, index) => ({
      id: index,
      ...item,
    }))
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
