import { usuariosApi } from '../services/api'
import { ListarUsuariosRequest } from '../types/ListarUsuariosRequest'
import { ListarUsuariosResponse } from '../types/ListarUsuariosResponse'

export async function listarUsuariosAsync(
  request: ListarUsuariosRequest,
): Promise<ListarUsuariosResponse> {
  const query = new URLSearchParams()
  if (request.page) {
    query.append('page', request.page.toString())
  }
  if (request.size) {
    query.append('size', request.size.toString())
  }
  if (request.id) {
    query.append('id', request.id.toString())
  }
  if (request.email) {
    query.append('email', request.email.toString())
  }
  if (request.nome) {
    query.append('nome', request.nome.toString())
  }

  const response = await usuariosApi.get<ListarUsuariosResponse>(`/usuarios?${query.toString()}`)
  return response.data
}
