export const setores: string[] = [
  'Aeronautica',
  'Agricultura',
  'Agtech',
  'Alimentos e Bebidas',
  'Arquitetura e Urbanismo',
  'Automotivo',
  'Bens de Consumo',
  'Consultoria',
  'Cosméticos e Beleza',
  'Edtech',
  'Educação',
  'Energia',
  'Fintech',
  'Hardware',
  'Healthtech',
  'Imobiliário',
  'Indústria',
  'Infraestrutura',
  'Jurídico',
  'Legaltech',
  'Logística',
  'Marketing',
  'Mídia e Entretenimento',
  'Óleo e Gás',
  'Proptech',
  'Publicidade',
  'Recursos Humanos',
  'Saúde',
  'Seguros',
  'Serviços Gerais',
  'Social',
  'Tecnologia',
  'Varejo de Alimentos',
  'Varejo de Moda',
  'Pet Care',
]
