import { ObterInformacoesDeNegociacaoDaCampanhaResponse } from '../types/ObterInformacoesDeNegociacaoDaCampanhaResponse'
import { requestClient } from './beeginRequestClient'

export async function obterInformacoesDeNegociacaoDaCampanhaAsync(
  slug: string,
): Promise<ObterInformacoesDeNegociacaoDaCampanhaResponse> {
  const response =
    await requestClient.apis.campanhaApi.get<ObterInformacoesDeNegociacaoDaCampanhaResponse>(
      `/campanhas/${slug}/informacoes-de-negociacao`,
    )
  return response.data
}
