import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { formatarData } from '../../utils/formatarData'
import { Flex } from '../../components/Flex'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { toast } from 'react-toastify'
import { ColumnProps } from '../../components/Tabela'

export const columns: ColumnProps[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    enableResizing: false,
    cell: (props: any) => (
      <p
        style={{
          width: '80px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.getValue()}
      </p>
    ),
  },
  {
    header: 'Nome',
    accessorKey: 'nome',
  },
  {
    header: 'Email',
    accessorKey: 'email',
    enableResizing: false,
    cell: (props: any) => (
      <p
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.getValue()}
      </p>
    ),
  },
  {
    header: 'Data',
    accessorKey: 'dataDeCadastro',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy'),
  },
  {
    header: 'Nome fantasia do escritório',
    accessorKey: 'escritorio.nomeFantasia',
  },
  {
    header: 'Razão social do escritório',
    accessorKey: 'escritorio.razaoSocial',
  },
  {
    header: 'Perfil',
    accessorKey: 'administradorDoEscritorio',
    cell: (props: any) => (props.getValue() ? 'Administrador' : 'Assessor'),
  },
  {
    header: 'Link Assessor',
    accessorKey: 'escritorio.linkParaCadastrarAssessorRelacionadoAoEscritorio',
    enableResizing: false,
    cell: (props: any) => {
      return (
        <Flex
          alignItems='center'
          gap='4px'
          style={{
            maxWidth: '185px',
          }}
        >
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => {
              copiarParaAreaDeTransferencia(props.getValue())
              toast.success('Copiado para área de transferência')
            }}
          />
          <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {props.getValue()}
          </p>
        </Flex>
      )
    },
  },
  {
    header: 'Link Investidor',
    accessorKey: 'linkParaCadastrarInvestidorRelacionadoAoAssessor',
    enableResizing: false,
    cell: (props: any) => {
      return (
        <Flex alignItems='center' gap='4px' style={{ maxWidth: '185px' }}>
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => {
              copiarParaAreaDeTransferencia(props.getValue())
              toast.success('Copiado para área de transferência')
            }}
          />
          <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {props.getValue()}
          </p>
        </Flex>
      )
    },
  },
]
