import { useMutation } from '@tanstack/react-query'

import { editarProtocoloDeInvestidorPessoaFisicaNaAdviceAsync } from '../api/editarProtocoloDeInvestidorPessoaFisicaNaAdviceAsync'

export const useProtocoloDeInvestidorPessoaFisicaNaAdvice = () => {
  const mutation = useMutation(editarProtocoloDeInvestidorPessoaFisicaNaAdviceAsync)

  return {
    mutation: mutation,
  }
}
