import styled from 'styled-components'

export const Estatisticas = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
`
export const LinhaDeBlocos = styled.div`
  display: flex;
  gap: 16px;
`

export const BlocoAzulClaro = styled.div`
  background-color: #2571ff;
  border-radius: 8px;
  padding: 24px;
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0;
`

export const BlocoBranco = styled(BlocoAzulClaro)`
  background-color: white;
  font-size: 16px;
  line-height: 24px;
  color: #212427;
  padding: 16px 24px 8px 24px;
  gap: 0;
`

export const BlocoAzulEscuro = styled(BlocoAzulClaro)`
  background-color: #0852dd;
`
export const TituloBloco = styled.span`
  // font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
`
export const ValorBloco = styled.span`
  // font-family: Ubuntu;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
`

export const TituloRanking = styled.span`
  font-weight: 700;
  margin-bottom: 16px;
`
export const LinhaRanking = styled.div`
  display: flex;
  gap: 36px;
`

export const Quantidade = styled.span`
  flex: 1;
  b {
    font-weight: 600;
  }
`

export const NomeEscritorio = styled(Quantidade)``
export const Periodo = styled.span`
  font-weight: 400;
`

export const Divisor = styled.div`
  background-color: #eff0f1;
  height 1px;
  margin: 8px -24px;
`
