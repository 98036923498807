import { Menu } from '../../components/menu'
import { FormEvent, useState } from 'react'
import { Link } from '../../components/link'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Button } from '../../@beegin/react/components/Button'
import { useCampanha } from '../../store/campanha'
import { FormularioDeCriarCampanha } from '../../components/FormularioDeCriarCampanha'
import { initialState } from './initialState'
import { useMutationToast } from '../../hooks/useMutationToast'
import { transformarDataFormatadaEmTimestamp } from '../../utils/formatarData'

export function CriarCampanha() {
  const [form, setForm] = useState(initialState)
  const { mutationParaCriar } = useCampanha()

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await mutationParaCriar.mutateAsync({
      ...form,
      dataDeInicio: transformarDataFormatadaEmTimestamp(form.dataDeInicio ?? ''),
      dataDeFechamento: transformarDataFormatadaEmTimestamp(form.dataDeFechamento ?? ''),
      dataDeConclusao: transformarDataFormatadaEmTimestamp(form.dataDeConclusao ?? ''),
      dataDaDeliberacaoDeEmissaoDasAcoes: transformarDataFormatadaEmTimestamp(
        form.dataDaDeliberacaoDeEmissaoDasAcoes ?? '',
      ),
      porcentagemDestinadaAoCapitalSocial:
        form.porcentagemDestinadaAoCapitalSocial && form.porcentagemDestinadaAoCapitalSocial / 100,
      porcentagemDestinadaParaFormacaoDeReservas:
        form.porcentagemDestinadaParaFormacaoDeReservas &&
        form.porcentagemDestinadaParaFormacaoDeReservas / 100,
    })
    setForm(initialState)
  }

  useMutationToast({
    mutation: mutationParaCriar,
    sucesso: 'Campanha criada com sucesso!',
    erro: 'Erro ao criar campanha.',
  })

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to='/campanhas'>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Criar campanha</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <FormularioDeCriarCampanha form={form} setForm={setForm} />
          <div>
            <Button variant='contained' type='submit' disabled={mutationParaCriar.isLoading}>
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
