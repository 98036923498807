import { Flex } from '../Flex'
import {
  BlocoBranco,
  LinhaDeBlocos,
  Estatisticas,
  BlocoAzulClaro,
  BlocoAzulEscuro,
  TituloBloco,
  ValorBloco,
  TituloRanking,
  LinhaRanking,
  Quantidade,
  NomeEscritorio,
  Periodo,
  Divisor,
} from './styles'
import {
  EscritorioProps,
  EstatisticasAssessoresProps,
} from '../../types/ObterEstatisticasAssessoresResponse'

export function EstatisticasAssessores({ data }: { data: EstatisticasAssessoresProps }) {
  const TabelaRanking = ({ titulo }: { titulo: string }) => {
    let top5: EscritorioProps[]
    switch (titulo) {
      case 'Cadastros totais':
        top5 = data.quantidadeDeInvestidoresPorEscritorio
          .sort((a, b) => b.quantidade - a.quantidade)
          .slice(0, 5)
        break

      case 'Cadastros recentes':
        top5 = data.quantidadeCadastrosDeInvestidorPorEscritorioDosUltimosTrintaDias
          .sort((a, b) => b.quantidade - a.quantidade)
          .slice(0, 5)
        break

      default:
        top5 = []
        break
    }
    return (
      <BlocoBranco>
        <TituloRanking>
          {titulo}
          {titulo !== 'Cadastros totais' && <Periodo> (30 dias)</Periodo>}
        </TituloRanking>
        <LinhaRanking>
          <NomeEscritorio>
            <b>Escritório</b>
          </NomeEscritorio>
          <Quantidade>
            <b>Quantidade</b>
          </Quantidade>
        </LinhaRanking>
        <Divisor />
        <Flex direction='column' gap='16px'>
          {top5.map((escritorio, index) => (
            <LinhaRanking key={escritorio.escritorioId}>
              <NomeEscritorio>
                {index + 1}. {escritorio.nomeDoEscritorio}
              </NomeEscritorio>
              <Quantidade>{escritorio.quantidade}</Quantidade>
            </LinhaRanking>
          ))}
        </Flex>
      </BlocoBranco>
    )
  }

  return (
    <Estatisticas>
      <LinhaDeBlocos>
        <BlocoAzulClaro>
          <TituloBloco>Quantidade de Escritórios</TituloBloco>
          <ValorBloco>{data.quantidadeTotalDeEscritorios}</ValorBloco>
        </BlocoAzulClaro>
        <BlocoAzulEscuro>
          <TituloBloco>Quantidade de assessores</TituloBloco>
          <ValorBloco>{data.quantidadeTotalDeAssessores}</ValorBloco>
        </BlocoAzulEscuro>
        <BlocoAzulClaro>
          <TituloBloco>Investidores com assessor</TituloBloco>
          <ValorBloco>{data.quantidadeTotalDeInvestidoresComAssessor}</ValorBloco>
        </BlocoAzulClaro>
      </LinhaDeBlocos>

      <LinhaDeBlocos>
        <TabelaRanking titulo='Cadastros totais' />
        <TabelaRanking titulo='Cadastros recentes' />
      </LinhaDeBlocos>
    </Estatisticas>
  )
}
