import { requestClient } from './beeginRequestClient'
import { ObterInvestidorEmpresaResponse } from '../types/ObterInvestidorEmpresaResponse'

export async function obterInvestidorEmpresaAsync(
  investidorId: string,
): Promise<ObterInvestidorEmpresaResponse> {
  const response = await requestClient.apis.investidores.get<ObterInvestidorEmpresaResponse>(
    `/investidores/${investidorId}/empresa`,
  )
  return response.data
}
