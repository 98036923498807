import { requestClient } from './beeginRequestClient'

type Props = {
  investidorId: string
  documentoId: string
}

export const deletarDocumentoDoInvestidorAsync = async ({
  investidorId,
  documentoId,
}: Props): Promise<void> => {
  await requestClient.apis.investidores.delete(
    `/investidores/${investidorId}/documentos/${documentoId}`,
  )
}
