import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { Checkbox } from '../Checkbox'

type Props = {
  label: string
  options: {
    disabled?: boolean
    checked: boolean
    label: string
    onChange?: () => void
  }[]
}

export function ConjuntoDeCheckboxes({ label, options }: Props) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {options.map((option) => (
        <Checkbox
          key={option.label}
          label={option.label}
          disabled={option.disabled}
          checked={option.checked}
          onChange={option.onChange}
        />
      ))}
    </FormControl>
  )
}
