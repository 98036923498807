import { useState } from 'react'
import { Button } from '../../@beegin/react/components/Button'
import { useStatusDoCadastroDoInvestidor } from '../../store/statusDoCadastroDoInvestidor'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Flex } from '../Flex'

type Props = {
  investidorId?: string
}

export function FormularioDeStatusDoCadastro({ investidorId }: Props): JSX.Element {
  const [disabled, setDisabled] = useState(false)
  const [passoDaConfirmacao, setPassoDaConfirmacao] = useState<1 | 2 | 3 | 4>(1)

  const { mutation } = useStatusDoCadastroDoInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Status atualizado com sucesso!',
    erro: 'Erro ao atualizar os dados.',
    carregando: 'Carregando...',
  })

  async function alterarAsync(): Promise<void> {
    if (!investidorId) {
      return
    }
    if (passoDaConfirmacao === 1) {
      setPassoDaConfirmacao(2)
      return
    }
    if (passoDaConfirmacao === 2) {
      setPassoDaConfirmacao(3)
      return
    }
    await mutation.mutateAsync({ status: 'Revisao' })
    setPassoDaConfirmacao(4)
  }

  async function onSubmit(): Promise<void> {
    setDisabled(true)
    try {
      await alterarAsync()
    } catch {
      toast.error('Erro ao alterar status')
    } finally {
      setTimeout(() => {
        setDisabled(false)
      }, 5000)
    }
  }

  const desabilitarBotao = disabled || mutation.isLoading

  if (passoDaConfirmacao === 1) {
    return (
      <Flex gap='8px' direction='column'>
        <Button
          color='info'
          carregando={desabilitarBotao}
          disabled={desabilitarBotao}
          onClick={onSubmit}
        >
          Bloquear investidor
        </Button>
      </Flex>
    )
  }
  if (passoDaConfirmacao === 2) {
    return (
      <Flex gap='8px' direction='column'>
        <Button color='info' disabled>
          Bloquear investidor
        </Button>
        <Button
          color='warning'
          carregando={desabilitarBotao}
          disabled={desabilitarBotao}
          onClick={onSubmit}
        >
          Você tem certeza?
        </Button>
      </Flex>
    )
  }
  if (passoDaConfirmacao === 3) {
    return (
      <Flex gap='8px' direction='column'>
        <Button color='info' disabled>
          Bloquear investidor
        </Button>
        <Button color='warning' disabled>
          Você tem certeza?
        </Button>
        <Button
          color='error'
          carregando={desabilitarBotao}
          disabled={desabilitarBotao}
          onClick={onSubmit}
        >
          Você tem certeza mesmo?
        </Button>
      </Flex>
    )
  }
  return <Button disabled>Concluído</Button>
}
