import { requestClient } from './beeginRequestClient'

type Request = {
  cnpj: string
  protocoloNaAdvice: string
}

export async function editarProtocoloDeInvestidorPessoaJuridicaNaAdviceAsync(request: Request) {
  await requestClient.apis.adviceApi.post(
    '/api/protocolo-de-investidor-na-advice/pessoa-juridica',
    request,
  )
}
