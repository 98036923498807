import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { FormularioDeInvestidor } from '../../components/formularioDeInvestidor'
import { FormularioDeRelacaoAssessorInvestidor } from '../../components/FormularioDeRelacaoAssessorInvestidor'
import { defaultMaxWidth } from '../../utils/constants'
import { SituacaoNaAdvice } from './situacaoNaAdvice'
import { Divisor } from '../../components/divisor'
import { Button } from '../../@beegin/react/components/Button'
import { FormularioDeDadosBancariosDoInvestidor } from '../../components/FormularioDeDadosBancariosDoInvestidor'
import { FormularioDeEnderecoDoInvestidor } from '../../components/FormularioDeEnderecoDoInvestidor'
import { FormularioDeDocumentosDoInvestidor } from '../../components/FormularioDeDocumentosDoInvestidor'
import { FormularioDeSuitabilityDoInvestidor } from '../../components/FormularioDeSuitabilityDoInvestidor'
import { Container } from './styles'
import { FormularioDePerfilDeInvestidor } from '../../components/FormularioDePerfilDeInvestidor'
import { FormularioDeDadosPessoaisDoInvestidor } from '../../components/FormularioDeDadosPessoaisDoInvestidor'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { FormularioDeTelefoneDoInvestidor } from '../../components/FormularioDeTelefoneDoInvestidor'
import { FormularioDeContaBancariaSpbDoInvestidor } from '../../components/FormularioDeContaBancariaSpbDoInvestidor'
import { FormularioDeInvestidorPessoaJuridica } from '../../components/FormularioDeInvestidorPessoaJuridica'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { useTipoDeInvestidor } from '../../store/tipoDeInvestidor'

export function ObterInvestidor() {
  let { investidorId } = useParams()

  const { data: tipoDeInvestidor } = useTipoDeInvestidor(investidorId)

  const voltar = useVoltar('/investidores')

  const header = () => {
    const voltarQueryParam = `voltar=/investidores/${investidorId}`
    return (
      <>
        <Flex gap='4px' margin='0 0 4px 0'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </Flex>
        <Flex direction='row' wrap='wrap' gap='4px'>
          <Link to={`/investidores/${investidorId}/historico-do-perfil-de-investidor`}>
            <Button variant='text'>Histórico</Button>
          </Link>
          <Link to={`/investimentos/criar?investidor-id=${investidorId}&${voltarQueryParam}`}>
            <Button variant='text'>Criar investimento</Button>
          </Link>
          <Link to={`/investidores/${investidorId}/protocolo-na-advice?${voltarQueryParam}`}>
            <Button variant='text'>Editar protocolo na Advice</Button>
          </Link>
          <Link to={`/investidores/${investidorId}/status-do-cadastro`}>
            <Button variant='text'>Editar status do cadastro</Button>
          </Link>
          <Link to={`/investimentos?investidor-id=${investidorId}&${voltarQueryParam}`}>
            <Button variant='text'>Investimentos</Button>
          </Link>
          <Link to={`/filas-de-espera?investidor-id=${investidorId}&${voltarQueryParam}`}>
            <Button variant='text'>Filas de espera</Button>
          </Link>
        </Flex>
      </>
    )
  }

  if (!investidorId) {
    return <Menu>{header()}</Menu>
  }

  return (
    <Menu>
      <Container>
        {header()}
        <h1>Investidor</h1>
        <ErrorBoundary>
          <FormularioDeDadosPessoaisDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Endereço</h2>
        <ErrorBoundary>
          <FormularioDeEnderecoDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Telefone</h2>
        <ErrorBoundary>
          <FormularioDeTelefoneDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Dados bancários</h2>
        <ErrorBoundary>
          <FormularioDeDadosBancariosDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Conta bancária SPB</h2>
        <ErrorBoundary>
          <FormularioDeContaBancariaSpbDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Perfil de investidor</h2>
        <ErrorBoundary>
          <FormularioDePerfilDeInvestidor investidorId={investidorId} nomeDaOferta={''} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Documentos</h2>
        <ErrorBoundary>
          <FormularioDeDocumentosDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Suitability</h2>
        <ErrorBoundary>
          <FormularioDeSuitabilityDoInvestidor investidorId={investidorId} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} />
        <h2>Situação na Advice</h2>
        <ErrorBoundary>
          <SituacaoNaAdvice investidorId={investidorId ?? ''} />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} marginVertical={8} />
        <ErrorBoundary>
          <FormularioDeInvestidor />
        </ErrorBoundary>
        <Divisor maxWidth={defaultMaxWidth} marginVertical={8} />
        <ErrorBoundary>
          <h2>Relacionamento com assessor</h2>
          <FormularioDeRelacaoAssessorInvestidor />
        </ErrorBoundary>
        {tipoDeInvestidor?.tipo === 'PJ' && (
          <>
            <Divisor maxWidth={defaultMaxWidth} marginVertical={8} text='Só para PJ 👇' />
            <ErrorBoundary>
              <FormularioDeInvestidorPessoaJuridica investidorId={investidorId} />
            </ErrorBoundary>
          </>
        )}
      </Container>
    </Menu>
  )
}
