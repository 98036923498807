import { investimentosApi } from '../services/api'
import { ObterInvestimentosDoInvestidorResponse } from '../types/ObterInvestimentosDoInvestidorResponse'

type Request = {
  investidorId: string
  ano?: number
}

export async function obterInvestimentosDoInvestidorAsync({
  investidorId,
  ano,
}: Request): Promise<ObterInvestimentosDoInvestidorResponse> {
  const query = new URLSearchParams()
  if (ano) {
    query.append('ano', ano.toString())
  }
  const response = await investimentosApi.get<ObterInvestimentosDoInvestidorResponse>(
    `/investidores/${investidorId}/investimentos?${query}`,
  )
  return response.data
}
