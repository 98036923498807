import React, { FormEvent, useEffect, useState } from 'react'
import { useAssessores } from '../../store/assessores'
import { useEscritoriosDeAssessor } from '../../store/escritoriosDeAssessor'
import { useRelacionanentoAssessorInvestidor } from '../../store/relacionamentoAssessorInvestidor'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Select } from '../../@beegin/react/components/Select'
import { Button } from '../../@beegin/react/components/Button'
import { Flex } from '../Flex'
import { useAuth } from '../../hooks/auth'

export function FormularioDeRelacaoAssessorInvestidor() {
  const { isInAnyOfTheseRoles } = useAuth()

  let { investidorId } = useParams()

  const [form, setForm] = useState({
    assessorId: '',
  })

  const [selectedEscritorio, setSelectedEscritorio] = useState('')

  const { data: assessores } = useAssessores({ size: 1000, escritorioId: selectedEscritorio })
  const { data: escritorios } = useEscritoriosDeAssessor()

  const {
    isLoading,
    data: investidor,
    mutation,
  } = useRelacionanentoAssessorInvestidor(investidorId)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const promise = mutation.mutateAsync(form)
    toast.promise(promise, {
      pending: 'Atualizando dados...',
      success: 'Dados atualizados com sucesso!',
      error: 'Erro ao atualizar dados',
    })
  }

  useEffect(() => {
    if (!investidor) {
      return
    }
    setForm({
      assessorId: investidor.assessorId,
    })
  }, [investidor])

  if (isLoading || mutation.isLoading) {
    return (
      <PilhaDeItens gap={3} maxWidth='600px'>
        <Esqueleto height='40px' />
      </PilhaDeItens>
    )
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  return (
    <>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Flex gap='15px'>
            <Select
              disabled={desabilitarInputs}
              label='Filtrar por escritório'
              value={selectedEscritorio}
              options={[
                { value: '-', label: 'Todos' },
                ...(escritorios?.map((item) => ({
                  value: item.id,
                  label: item.nome,
                })) ?? []),
              ]}
              onChange={(e) => setSelectedEscritorio(e.target.value as any as string)}
            />
            <Select
              disabled={desabilitarInputs}
              label='Assessor'
              value={form.assessorId ?? ''}
              options={[
                { value: '', label: '-' },
                ...(assessores?.assessores?.map((item) => ({
                  value: item.id,
                  label: item.nome,
                })) ?? []),
              ]}
              onChange={(e) => setForm({ ...form, assessorId: e.target.value as any as string })}
            />
          </Flex>
          <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </PilhaDeItens>
      </form>
    </>
  )
}
