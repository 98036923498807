import { Menu } from '../../components/menu'
import { FormEvent, useState } from 'react'
import { Link } from '../../components/link'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Button } from '../../@beegin/react/components/Button'
import { InputDeArquivo } from '../../components/InputDeArquivo'
import { useCampanha } from '../../store/campanha'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useAtualizacaoParaInvestidores } from '../../store/atualizacaoParaInvestidores'
import { Select } from '../../@beegin/react/components/Select'

type TipoDeItem = 'Arquivo' | 'LinkDeVideo' | ''

type Form = {
  titulo: string
  conteudo: string
  tipo: TipoDeItem
}

const initialState: Form = {
  titulo: '',
  conteudo: '',
  tipo: '',
}

export function CriarAtualizacoesParaInvestidores() {
  let { slug } = useParams()

  const [file, setFile] = useState<File | null>(null)
  const [form, setForm] = useState<Form>(initialState)

  const campanha = useCampanha({ slug })

  const atualizacaoParaInvetidores = useAtualizacaoParaInvestidores({
    campanhaId: campanha.data?.id,
  })

  useMutationToast({
    mutation: atualizacaoParaInvetidores.mutationParaCriar,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
    carregando: 'Carregando...',
  })

  function resetarFormulario() {
    setFile(null)
    setForm(initialState)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!campanha.data?.id) {
      toast.error('Erro ao obter campanha.')
      return
    }
    if (form.tipo === '') {
      toast.error('Selecione o tipo.')
      return
    }

    await atualizacaoParaInvetidores.mutationParaCriar.mutateAsync({
      campanhaId: campanha.data?.id,
      arquivo: file,
      titulo: form.titulo,
      conteudo: form.conteudo,
      tipo: form.tipo,
    })

    resetarFormulario()
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to={`/campanhas/${slug}/atualizacoes-para-investidores`}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Criar atualização para investidores</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Select
            label='Tipo'
            value={form.tipo}
            options={[
              {
                label: 'Link de vídeo',
                value: 'LinkDeVideo',
              },
              {
                label: 'Arquivo',
                value: 'Arquivo',
              },
            ]}
            onChange={(e) => setForm({ ...form, tipo: e.target.value as TipoDeItem })}
          />
          <Input
            required
            label='Título'
            value={form.titulo}
            onChange={(e) => setForm({ ...form, titulo: e.target.value })}
          />
          {form.tipo === 'Arquivo' && <InputDeArquivo file={file} setFile={setFile} />}
          {form.tipo === 'LinkDeVideo' && (
            <Input
              required
              label='Link'
              value={form.conteudo}
              onChange={(e) => setForm({ ...form, conteudo: e.target.value })}
            />
          )}
          <div>
            <Button
              variant='contained'
              type='submit'
              disabled={atualizacaoParaInvetidores.mutationParaCriar.isLoading}
            >
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
