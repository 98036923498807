import { Button } from '@mui/material'
import styled from 'styled-components'

export const StyledMaterialButton = styled(Button)`
  a {
    text-decoration: none;
  }
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
`
