import { ObterDocumentosDoInvestidorResponse } from '../types/ObterDocumentosDoInvestidorResponse'
import { requestClient } from './beeginRequestClient'

export const obterDocumentosDoInvestidorAsync = async (
  investidorId: string,
): Promise<ObterDocumentosDoInvestidorResponse> => {
  const response = await requestClient.apis.investidores.get<ObterDocumentosDoInvestidorResponse>(
    `/investidores/${investidorId}/documentos`,
  )
  return response?.data
}
