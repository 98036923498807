import { useSearchParams } from 'react-router-dom'
import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Menu } from '../../components/menu'
import { FormEvent, useEffect, useState } from 'react'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { FormContainer } from '../../components/FormContainer'
import { useInvestimento } from '../../store/investimento'
import { useInvestidor } from '../../store/investidor'
import { useMutationToast } from '../../hooks/useMutationToast'
import { toast } from 'react-toastify'
import { useCampanhas } from '../../store/campanhas'
import { SelectComAutocomplete } from '../../@beegin/react/components/Select'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { useVoltar } from '../../hooks/useVoltar'

type Form = {
  investidorId: string
  campanhaId: string
  valor: number
}

const initialState = {
  investidorId: '',
  campanhaId: '',
  valor: 0,
}

export function CriarInvestimento() {
  const [form, setForm] = useState<Form>(initialState)

  let [searchParams] = useSearchParams()

  const investidorId = searchParams.get('investidor-id')

  const investidor = useInvestidor(investidorId ?? '')

  const campanhas = useCampanhas()

  const investimento = useInvestimento()

  function limparFormulario(): void {
    setForm({ ...form, campanhaId: '', valor: 0 })
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()
    if (!investidorId) {
      toast.error('Não foi possível obter a id do investidor.')
      return
    }
    await investimento.criarInvestimentoMutation.mutateAsync({
      ...form,
      investidorId: investidorId,
    })
    limparFormulario()
  }

  useMutationToast({
    mutation: investimento.criarInvestimentoMutation,
    sucesso: 'Investimento criado!',
    erro: 'Erro ao criar investimento.',
    carregando: 'Carregando...',
  })

  useEffect(() => {
    if (!investidorId) {
      toast.error('Não foi possível obter a id do investidor.')
    }
  }, [investidorId])

  useEffect(() => {
    if (investidor.isError) {
      toast.error('Erro ao obter investidor.')
    }
  }, [investidor.isError])

  const desabilitarForm = investimento.criarInvestimentoMutation.isLoading || investidor.isError

  const desabilitarBotao = investimento.criarInvestimentoMutation.isLoading || investidor.isError

  const opcoesDoSelectDeCampanha =
    campanhas.data
      ?.filter((x) => !!x.nome)
      .sort((a, b) => a.nome!.localeCompare(b.nome!))
      .map((campanha) => ({
        label: campanha.nome,
        value: campanha.id?.toString(),
      })) ?? []

  const voltar = useVoltar('/investimentos')

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Criar investimento</h1>
        <Esqueleto height='80px' active={campanhas.isLoading}>
          <form onSubmit={onSubmit}>
            <PilhaDeItens gap={3} margin='0 0 32px 0'>
              <Input disabled label='ID do investidor' value={investidorId} />
              <Input
                disabled
                label='Nome do investidor'
                value={investidor.data?.dadosPessoais?.nome ?? ''}
              />
              <SelectComAutocomplete
                required
                disabled={desabilitarForm}
                value={form.campanhaId}
                label='Campanha'
                options={opcoesDoSelectDeCampanha}
                onChange={(value: any) => setForm({ ...form, campanhaId: value })}
              />
              <MaskedInput
                required
                error={!!form.valor && form.valor <= 0}
                disabled={desabilitarForm}
                mascara='dinheiro'
                label='Valor'
                value={form.valor}
                onValueChange={(e) => {
                  if (!e.floatValue) {
                    return
                  }
                  setForm({ ...form, valor: e.floatValue })
                }}
              />
            </PilhaDeItens>
            <Button
              type='submit'
              disabled={desabilitarBotao}
              carregando={investimento.criarInvestimentoMutation.isLoading}
            >
              Salvar
            </Button>
          </form>
        </Esqueleto>
      </FormContainer>
    </Menu>
  )
}
