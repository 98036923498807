import { assessoresApi } from '../services/api'

interface ObterRelacionamentoAssessorInvestidorResponse {
  investidorId: string
  assessorId: string
}

export async function obterRelacionamentoAssessorInvestidorAsync(
  investidorId: string,
): Promise<ObterRelacionamentoAssessorInvestidorResponse> {
  const response = await assessoresApi.get<ObterRelacionamentoAssessorInvestidorResponse>(
    `/investidores/${investidorId}/assessor`,
  )
  return response.data
}
