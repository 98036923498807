import { Menu } from '../../components/menu'
import { Button } from '../../@beegin/react/components/Button'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useAuth } from '../../hooks/auth'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { Text } from '../../@beegin/react/components/Text'

export function Configuracoes(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const { token, refreshToken } = useAuth()

  function copiarValor(valor: string): void {
    if (!valor) {
      toast.error('Não há valor para copiar')
      return
    }
    copiarParaAreaDeTransferencia(valor)
    toast.success('Valor copiado!')
  }

  async function fazerRefreshTokenAsync(): Promise<void> {
    setIsLoading(true)
    setError('')
    try {
      await refreshToken()
      toast.success('Novo token adquirido!')
    } catch (err) {
      setError(err as string)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Menu>
      <h1>Configurações</h1>
      <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='800px'>
        <Flex alignItems='center' justifyContent='space-between' gap='8px'>
          <Input label='Seu JWT' value={token ?? ''} multiline rows={20} fullWidth disabled />
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => copiarValor(token ?? '')}
          />
        </Flex>
      </PilhaDeItens>
      <div>
        <Button
          variant='contained'
          type='button'
          onClick={() => fazerRefreshTokenAsync()}
          carregando={isLoading}
          disabled={isLoading}
        >
          Fazer refresh token
        </Button>
        {error && <Text color='red'>{error}</Text>}
      </div>
    </Menu>
  )
}
