import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editarDocumentoDoInvestidorAsync } from '../api/editarDocumentoDoInvestidorAsync'
import { obterDocumentosDoInvestidorAsync } from '../api/obterDocumentosDoInvestidorAsync'
import { EditarDocumentoMutation } from '../types/EditarDocumentoMutation'
import { deletarDocumentoDoInvestidorAsync } from '../api/deletarDocumentoDoInvestidorAsync'

export const useDocumentosDoInvestidor = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['documentos', investidorId]

  const options = {
    retry: false,
    enabled: !!investidorId,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterDocumentosDoInvestidorAsync(investidorId)
  }

  const response = useQuery(queryKey, getAsync, options)

  const editarAsync = async (request: EditarDocumentoMutation) => {
    if (!investidorId) {
      return
    }
    await editarDocumentoDoInvestidorAsync({
      arquivo: request.arquivo,
      investidorId: investidorId,
      tipo: request.tipo,
    })
  }

  const editarDocumentoMutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  const deletarAsync = async (documentoId: string) => {
    if (!investidorId) {
      return
    }
    await deletarDocumentoDoInvestidorAsync({ investidorId, documentoId })
  }

  const deletarDocumentoMutation = useMutation(deletarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    editarDocumentoMutation: editarDocumentoMutation,
    deletarDocumentoMutation: deletarDocumentoMutation,
  }
}
