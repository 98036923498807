import { investidoresApi } from '../services/api'

type EditarInvestidorNaVisaoDoBackofficeRequest = {
  origem: string
  assessorId: string
  statusBackoffice: number | null
  observacoesDoBackoffice: string
}

export async function editarInvestidorNaVisaoDoBackofficeAsync(
  investidorId: string,
  request: EditarInvestidorNaVisaoDoBackofficeRequest,
): Promise<void> {
  await investidoresApi.put(`/investidores/${investidorId}/observacoes`, request)
}
