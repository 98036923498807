export const formatarDinheiro = (
  valor: number,
  digitos: number = 2,
  style: 'currency' | 'decimal' = 'currency',
): string => {
  try {
    return new Intl.NumberFormat('pt-BR', {
      style: style,
      currency: 'BRL',
      minimumFractionDigits: digitos,
      maximumFractionDigits: digitos,
    }).format(valor)
  } catch {
    return valor?.toString() ?? ''
  }
}
