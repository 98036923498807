import { Text } from '../../@beegin/react/components/Text'
import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Menu } from '../../components/menu'
import { FormEvent, useEffect, useState } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import { FormContainer } from '../../components/FormContainer'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useProtocoloDeInvestidorPessoaFisicaNaAdvice } from '../../store/protocoloDeInvestidorPessoaFisicaNaAdvice'
import { guidEstaValido } from '../../@beegin/react/utils/guiaEstaValido'
import { useParams } from 'react-router-dom'
import { useInvestidor } from '../../store/investidor'
import { toast } from 'react-toastify'
import { useVoltar } from '../../hooks/useVoltar'

type Form = {
  investidorId: string
  protocoloNaAdvice: string
}

export function EditarProtocoloDeInvestidorPessoaFisicaNaAdvice() {
  const voltar = useVoltar('/')

  let { investidorId } = useParams()

  const investidor = useInvestidor(investidorId ?? '')

  useEffect(() => {
    if (!investidorId) {
      toast.error('Não foi possível obter a id do investidor.')
    }
  }, [investidorId])

  useEffect(() => {
    if (investidor.isError) {
      toast.error('Erro ao obter investidor.')
    }
  }, [investidor.isError])

  const { mutation } = useProtocoloDeInvestidorPessoaFisicaNaAdvice()

  useMutationToast({
    mutation: mutation,
    sucesso: 'Registrado com sucesso!',
    erro: 'Erro ao editar registro.',
    carregando: 'Carregando...',
  })

  const [form, setForm] = useState<Form>({
    investidorId: '',
    protocoloNaAdvice: '',
  })

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!investidorId) {
      toast.error('Erro ao obter id do investidor.')
      return
    }
    await mutation.mutateAsync({ ...form, investidorId: investidorId })
  }

  const protocoloValido = guidEstaValido(form.protocoloNaAdvice)

  const desabilitarForm = mutation.isLoading
  const desabilitarBotao = !protocoloValido || desabilitarForm

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Protocolo de investidor na Advice</h1>
        <p>
          Somente para pessoa física. Para pessoa jurídica,{' '}
          <Link to='/protocolo-de-investidor-na-advice'>
            <Text type='span' color='#060b7a'>
              clique aqui
            </Text>
          </Link>
          .
        </p>
        <p>
          Só use em caso de erro na integração (timeout na API deles e não conseguimos pegar o
          protocolo).
        </p>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0'>
            <Input disabled label='ID do investidor' value={investidorId} />
            <Input
              disabled
              label='Nome do investidor'
              value={investidor.data?.dadosPessoais?.nome ?? ''}
            />
            <Input
              required
              disabled={desabilitarForm}
              label='Protocolo'
              value={form.protocoloNaAdvice}
              onChange={(e) => setForm({ ...form, protocoloNaAdvice: e.target.value })}
              error={!!form.protocoloNaAdvice && !protocoloValido}
              helperText={!!form.protocoloNaAdvice && !protocoloValido && 'Protocolo inválido.'}
            />
          </PilhaDeItens>
          <Button type='submit' carregando={desabilitarForm} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </form>
      </FormContainer>
    </Menu>
  )
}
