import { requestClient } from './beeginRequestClient'

type Request = {
  investidorId: string
  status: string
}

export async function editarStatusDoCadastroDoInvestidorAsync({
  investidorId,
  status,
}: Request): Promise<void> {
  await requestClient.apis.investidores.put(`/investidores/${investidorId}/status-do-cadastro`, {
    status: status,
  })
}
