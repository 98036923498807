import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { MascarasDisponiveis, obterPropsMascaraDoInput } from './mascaras'
import { styles } from './styles'

type InputProps = TextFieldProps & {
  carregando?: boolean
}

const propsParaCarregamento = {
  endAdornment: (
    <InputAdornment position="end">
      <CircularProgress size="18px" />
    </InputAdornment>
  ),
}

export function Input({ carregando,value, ...props }: InputProps) {
  return (
    <TextField
      value={value ?? ''}
      variant="outlined"
      sx={styles}
      InputProps={carregando ? propsParaCarregamento : undefined}
      {...props}
    />
  )
}

type MaskedInputProps = InputProps & {
  mascara: MascarasDisponiveis
}

export function MaskedInput({
  mascara,
  ...props
}: Omit<NumberFormatProps<MaskedInputProps>, 'onChange'>) {
  const propsDaMascara = obterPropsMascaraDoInput(mascara)
  return <NumberFormat customInput={Input} {...propsDaMascara} {...props} />
}
