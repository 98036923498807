import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { criarCessaoDePosicaoAsync } from '../api/criarCessaoDePosicaoAsync'
import { listarCessoesDePosicaoAsync } from '../api/listarCessoesDePosicaoAsync'

type Request = {
  disabled?: boolean
}

export const useCessaoDePosicao = ({ disabled = false }: Request) => {
  const options = {
    enabled: !disabled,
  }

  const queryClient = useQueryClient()

  const queryKey = ['cessao-de-posicao']

  async function getAsync() {
    const response = await listarCessoesDePosicaoAsync()
    return response.cessoes
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync, options)

  const mutation = useMutation(criarCessaoDePosicaoAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data,
    mutation,
  }
}
