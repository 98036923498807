import { Menu } from '../../components/menu'

export function Index() {
  return (
    <Menu>
      <div style={{ maxWidth: '400px' }}>
        <p>Olá, tenha um ótimo dia! 😉</p>
      </div>
    </Menu>
  )
}
