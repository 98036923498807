import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { FormEvent, useState } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import { useUsuario } from '../../store/usuario'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Select } from '../../@beegin/react/components/Select'
import { useUsuarioSenha } from '../../store/usuarioSenha'

type Form = {
  veiculoDaMensagem: number
  novaSenha: string
}

export function EditarUsuarioSenha() {
  const [form, setForm] = useState<Form>({ veiculoDaMensagem: 1, novaSenha: '' })

  let { usuarioId } = useParams()

  const { data, isLoading } = useUsuario(usuarioId)
  const { mutationParaEditarSenha, mutationParaRecuperarSenha } = useUsuarioSenha(usuarioId)

  useMutationToast({
    mutation: mutationParaEditarSenha,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  useMutationToast({
    mutation: mutationParaRecuperarSenha,
    sucesso: 'Pedido enviado!',
    erro: 'Erro ao enviar pedido.',
  })

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  const carregando = isLoading
  const desabilitarInputs =
    mutationParaRecuperarSenha.isLoading || mutationParaEditarSenha.isLoading
  const desabilitarBotao = mutationParaRecuperarSenha.isLoading || mutationParaEditarSenha.isLoading

  async function onSubmitRecuperacao(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!usuarioId) {
      toast.error('Não há a ID do usuário.')
      return
    }
    await mutationParaRecuperarSenha.mutateAsync({
      usuarioId,
      veiculoDaMensagem: form.veiculoDaMensagem,
    })
    setForm({ veiculoDaMensagem: 1, novaSenha: '' })
  }

  async function onSubmitEdicao(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!usuarioId) {
      toast.error('Não há a ID do usuário.')
      return
    }
    await mutationParaEditarSenha.mutateAsync({
      usuarioId,
      senha: form.novaSenha,
    })
    setForm({ veiculoDaMensagem: 1, novaSenha: '' })
  }

  const header = () => {
    return (
      <Flex gap='4px' margin='0 0 4px 0'>
        <Link to={voltar}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </Flex>
    )
  }

  if (carregando) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Editar senha do usuário</h1>
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Input value={data?.id ?? ''} label='ID' disabled />
          <Input value={data?.nomeCompleto ?? ''} label='Nome' disabled />
          <Input value={data?.email ?? ''} label='E-mail' disabled />
        </PilhaDeItens>
        <h2>Enviar pedido de recuperação</h2>
        <form onSubmit={onSubmitRecuperacao}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <Select
              required
              label='Veículo da mensagem'
              value={form.veiculoDaMensagem}
              options={[
                { label: 'E-mail', value: 1 },
                { label: 'WhatsApp', value: 2 },
                { label: 'SMS', value: 3 },
              ]}
              onChange={(e) => setForm({ ...form, veiculoDaMensagem: e.target.value as number })}
              disabled={desabilitarInputs}
            />
          </PilhaDeItens>
          <Button type='submit' carregando={desabilitarBotao} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </form>
        <br />
        <h2>Alterar senha manualmente</h2>
        <form onSubmit={onSubmitEdicao}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <Input
              required
              value={form?.novaSenha ?? ''}
              onChange={(e) => setForm({ ...form, novaSenha: e.target.value })}
              label='Nova senha'
              disabled={desabilitarInputs}
            />
          </PilhaDeItens>
          <Button type='submit' carregando={desabilitarBotao} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </form>
      </>
    </Menu>
  )
}
