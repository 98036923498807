import { Menu } from '../../components/menu'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { Link } from '../../components/link'
import { useNavigate } from 'react-router-dom'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Button } from '../../@beegin/react/components/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDocumentosDeRegulamentacao } from '../../store/documentosDeRegulamentacao'
import { toast } from 'react-toastify'
import { obterRespostaDeErro } from '../../utils/obterRespostaDeErro'
import { useMutationToast } from '../../hooks/useMutationToast'

export function CriarDocumentoDeRegulamentacao() {
  const tamanhoMaximoDoArquivoEmMb = 5

  const inputDeArquivoRef = useRef(null)
  const [nome, setNome] = useState('')
  const [arquivo, setArquivo] = useState<File | null>(null)

  const navigate = useNavigate()

  const { criarMutation } = useDocumentosDeRegulamentacao({ enabled: false })

  useMutationToast({
    mutation: criarMutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: obterRespostaDeErro(criarMutation.error, 'Erro ao atualizar os dados.'),
    carregando: 'Criando documento...',
  })

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!arquivo) {
      return
    }
    await criarMutation.mutateAsync({
      arquivo: arquivo,
      nome: nome,
    })
  }

  function validarSeBotaoDeveEstarDesabilitado(): boolean {
    if (!nome) {
      return true
    }
    if (!arquivo) {
      return true
    }
    if (criarMutation.isLoading) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (criarMutation.isSuccess) {
      navigate('/documentos-de-regulamentacao')
    }
  }, [criarMutation.isSuccess, navigate])

  useEffect(() => {
    if (!arquivo) {
      return
    }
    if (arquivo.size / (1024 * 1024) > tamanhoMaximoDoArquivoEmMb) {
      toast.error('O arquivo não pode ter mais de 5mb.')
      setArquivo(null)
    }
  }, [arquivo])

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to='/documentos-de-regulamentacao'>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Criar documento de regulamentação</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Input required label='Nome' value={nome} onChange={(e) => setNome(e.target.value)} />
          <div>
            {arquivo ? (
              <Button
                variant='text'
                color='error'
                endIcon={<DeleteIcon />}
                onClick={() => setArquivo(null)}
              >
                Remover documento selecionado
              </Button>
            ) : (
              <Button
                variant='text'
                startIcon={<AttachFileIcon />}
                // @ts-ignore
                onClick={() => inputDeArquivoRef?.current?.click()}
              >
                Escolher documento
                <input
                  required
                  accept='application/pdf'
                  type='file'
                  hidden
                  ref={inputDeArquivoRef}
                  multiple={false}
                  onChange={(e) =>
                    setArquivo(
                      e.target.files && e.target.files?.length > 0 ? e.target.files[0] : null,
                    )
                  }
                />
              </Button>
            )}
          </div>
          <div>
            <Button
              variant='contained'
              type='submit'
              disabled={validarSeBotaoDeveEstarDesabilitado()}
            >
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
