import { useQuery } from '@tanstack/react-query'
import { obterInvestimentosDoInvestidorAsync } from '../api/obterInvestimentosDoInvestidorAsync'

type Request = {
  investidorId: string
  ano?: number
}

export const useInvestimentosDoInvestidor = ({ investidorId, ano }: Request) => {
  const queryKey = ['investimentos-do-investidor', investidorId, ano]

  const response = useQuery(
    queryKey,
    async () => {
      if (!investidorId) {
        return
      }
      return await obterInvestimentosDoInvestidorAsync({ investidorId, ano })
    },
    { enabled: !!investidorId },
  )

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch,
  }
}
