import { Menu } from '../../components/menu'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Link } from '../../components/link'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Button } from '../../@beegin/react/components/Button'
import { useCampanha } from '../../store/campanha'
import { FormularioDeCriarCampanha } from '../../components/FormularioDeCriarCampanha'
import { initialState } from './initialState'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useParams } from 'react-router-dom'
import { Select } from '../../@beegin/react/components/Select'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Divisor } from '../../components/divisor'
import { InputDeArquivo } from '../../components/InputDeArquivo'
import { useCampanhaLogo } from '../../store/campanhaLogo'
import { toast } from 'react-toastify'
import { formatarData, transformarDataFormatadaEmTimestamp } from '../../utils/formatarData'
import { Flex } from '../../components/Flex'

export function EditarCampanha() {
  const [file, setFile] = useState<File | null>(null)
  const [form, setForm] = useState(initialState)

  let { slug } = useParams()

  const { data, mutationParaEditar, isLoading } = useCampanha({ slug })

  useMutationToast({
    mutation: mutationParaEditar,
    sucesso: 'Campanha editada com sucesso!',
    erro: 'Erro ao editar campanha.',
    carregando: 'Carregando...',
  })

  const { mutation: logoMutation } = useCampanhaLogo(slug ?? '')

  useMutationToast({
    mutation: logoMutation,
    sucesso: 'Logo editada com sucesso!',
    erro: 'Erro ao editar logo.',
    carregando: 'Carregando...',
  })

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await mutationParaEditar.mutateAsync({
      ...form,
      dataDeInicio: transformarDataFormatadaEmTimestamp(form.dataDeInicio ?? ''),
      dataDeFechamento: transformarDataFormatadaEmTimestamp(form.dataDeFechamento ?? ''),
      dataDeConclusao: transformarDataFormatadaEmTimestamp(form.dataDeConclusao ?? ''),
      dataDaDeliberacaoDeEmissaoDasAcoes: transformarDataFormatadaEmTimestamp(
        form.dataDaDeliberacaoDeEmissaoDasAcoes ?? '',
      ),
      porcentagemDestinadaAoCapitalSocial:
        form.porcentagemDestinadaAoCapitalSocial && form.porcentagemDestinadaAoCapitalSocial / 100,
      porcentagemDestinadaParaFormacaoDeReservas:
        form.porcentagemDestinadaParaFormacaoDeReservas &&
        form.porcentagemDestinadaParaFormacaoDeReservas / 100,
    })
  }

  async function onSubmitLogo(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!file) {
      toast.error('O arquivo não pode ser nulo')
      return
    }
    await logoMutation.mutateAsync(file)
  }

  const iniciarFormulario = useCallback(async () => {
    if (!slug) {
      return
    }
    if (!data) {
      return
    }

    setForm({
      ...data,
      dataDeInicio: data.dataDeInicio ? formatarData(new Date(data.dataDeInicio)) : '',
      dataDeFechamento: data.dataDeFechamento ? formatarData(new Date(data.dataDeFechamento)) : '',
      dataDeConclusao: data.dataDeConclusao ? formatarData(new Date(data.dataDeConclusao)) : '',
      dataDaDeliberacaoDeEmissaoDasAcoes: data.dataDaDeliberacaoDeEmissaoDasAcoes
        ? formatarData(new Date(data.dataDaDeliberacaoDeEmissaoDasAcoes))
        : '',
      porcentagemDestinadaAoCapitalSocial:
        data.porcentagemDestinadaAoCapitalSocial && data.porcentagemDestinadaAoCapitalSocial * 100,
      porcentagemDestinadaParaFormacaoDeReservas:
        data.porcentagemDestinadaParaFormacaoDeReservas &&
        data.porcentagemDestinadaParaFormacaoDeReservas * 100,
    })
  }, [data, slug])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  const header = (): JSX.Element => {
    const voltarQueryParam = `voltar=/campanhas/${slug}/editar`
    return (
      <Flex direction='row' gap='8px' wrap='wrap'>
        <Link to={`/campanhas/${slug}`}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
        <Link to={`/campanhas/${slug}/relatorios`}>
          <Button variant='text'>Relatórios</Button>
        </Link>
        <Link to={`/campanhas/${slug}/atualizacoes-para-investidores`}>
          <Button variant='text'>Atualizações para investidores</Button>
        </Link>
        <Link to={`/campanhas/${slug}/informacoes-de-negociacao`}>
          <Button variant='text'>Informações de negociação</Button>
        </Link>
        <Link to={`/investimentos?campanha-id=${data?.id}&${voltarQueryParam}`}>
          <Button variant='text'>Investimentos</Button>
        </Link>
        <Link to={`/filas-de-espera?campanha-id=${data?.id}&${voltarQueryParam}`}>
          <Button variant='text'>Filas de espera</Button>
        </Link>
      </Flex>
    )
  }

  if (isLoading || !data) {
    return (
      <Menu>
        {header()}
        <h1>Editar campanha</h1>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Esqueleto width='100%' height='40px' />
          <Esqueleto width='100%' height='40px' />
          <Esqueleto width='100%' height='40px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      {header()}
      <h1>Editar campanha</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Select
            label='Status'
            value={form.status}
            options={[
              { value: 'Encerrada', label: 'Encerrada' },
              { value: 'Concluída', label: 'Concluída' },
              { value: 'Em Espera', label: 'Em Espera' },
              { value: 'Aberta', label: 'Aberta' },
              { value: 'Cancelada', label: 'Cancelada' },
              { value: 'Inativo', label: 'Inativo' },
              { value: 'Liquidação', label: 'Liquidação' },
            ]}
            onChange={(e) => setForm({ ...form, status: e.target.value as string })}
          />
          <FormularioDeCriarCampanha form={form} setForm={setForm} />
          <div>
            <Button variant='contained' type='submit' disabled={mutationParaEditar.isLoading}>
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
      <Divisor />
      <h3>Logo da campanha</h3>
      <form onSubmit={onSubmitLogo}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          {data.urlDaLogo && (
            <img
              src={data.urlDaLogo}
              height='64'
              width='64'
              style={{ borderRadius: '50px', objectFit: 'cover' }}
            />
          )}
          <InputDeArquivo file={file} setFile={setFile} accept='image/png, image/jpg, image/jpeg' />
          <div>
            <Button variant='contained' type='submit' disabled={logoMutation.isLoading}>
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
