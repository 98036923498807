import LaunchIcon from '@mui/icons-material/Launch'
import { Flex } from '../../components/Flex'
import { Link } from '../../components/link'
import { formatarData } from '../../utils/formatarData'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'

type Investimento = {
  id: number
  nomeDoInvestidor: string
  investidorId: number
  nomeDoAssessor: string
  nomeDaCampanha: string
  dataDeCriacao: string
  status: string
  valor: number
}

export const columns = [
  {
    id: 'id',
    header: '',
    accessorFn: (row: Investimento) => row.id,
    maxSize: 20,
    cell: (props: any) => {
      return (
        <Link to={`/investimentos/${props.getValue()}/editar`}>
          <Flex alignItems='center' gap='4px'>
            <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
          </Flex>
        </Link>
      )
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    size: 70,
  },
  {
    accessorKey: 'nomeDoInvestidor',
    header: 'Investidor',
    cell: (props: any) => (
      <Flex gap='5px' alignItems='center'>
        <Link
          to={`/investidores/${props.row.original.investidorId}`}
          style={{ alignItems: 'center', display: 'flex' }}
        >
          <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
        </Link>
        {props.getValue()}
      </Flex>
    ),
  },
  {
    accessorKey: 'nomeDoAssessor',
    header: 'Assessor',
  },
  {
    accessorKey: 'nomeDaCampanha',
    header: 'Campanha',
  },
  {
    accessorKey: 'dataDeCriacao',
    header: 'Data de criação',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'valor',
    header: 'Valor',
    cell: (props: any) => formatarDinheiro(props.getValue()),
  },
]
