import { investimentosApi } from '../services/api'

type CriarCessaoDePosicaoRequest = {
  investidorIdDoComprador: string
  investidorIdDoVendedor: string
  investimentoId: number
  dataDaCessao: string
  valorCedido: number
  arquivo: File
}

export async function criarCessaoDePosicaoAsync(
  request: CriarCessaoDePosicaoRequest,
): Promise<void> {
  const fd = new FormData()
  fd.append('investimentoId', request.investimentoId.toString())
  fd.append('valorCedido', request.valorCedido.toString())
  fd.append('investidorIdDoComprador', request.investidorIdDoComprador)
  fd.append('dataDaCessao', request.dataDaCessao)
  fd.append('arquivo', request.arquivo)
  await investimentosApi.post('/cessoes-de-investimento', fd)
}
