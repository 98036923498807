import { useMutation } from '@tanstack/react-query'

import { editarProtocoloDeInvestidorPessoaJuridicaNaAdviceAsync } from '../api/editarProtocoloDeInvestidorPessoaJuridicaNaAdviceAsync'

export const useProtocoloDeInvestidorPessoaJuridicaNaAdvice = () => {
  const mutation = useMutation(editarProtocoloDeInvestidorPessoaJuridicaNaAdviceAsync)

  return {
    mutation: mutation,
  }
}
