import { requestClient } from './beeginRequestClient'

type ObterRelatoriosDeCampanhaResponse = {
  relatorios: {
    id: number
    nome: string
    url: string
  }[]
}

export async function listarRelatoriosDeCampanhaAsync(
  campanhaId: number,
): Promise<ObterRelatoriosDeCampanhaResponse> {
  const response = await requestClient.apis.campanhaApi.get<ObterRelatoriosDeCampanhaResponse>(
    `/campanhas/${campanhaId}/relatorios`,
  )
  return response.data
}
