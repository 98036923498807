import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import { formatarData } from '../../utils/formatarData'

export const columns = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 60,
  },
  {
    accessorKey: 'nomeDaCampanha',
    header: 'Campanha',
  },
  {
    accessorKey: 'nomeDoVendedor',
    header: 'Vendedor',
  },
  {
    accessorKey: 'nomeDoComprador',
    header: 'Comprador',
  },
  {
    accessorKey: 'valor',
    header: 'Valor cedido',
    cell: (props: any) => formatarDinheiro(props.getValue()),
  },
  {
    accessorKey: 'dataDaCessao',
    header: 'Data da cessão',

    cell: (props: any) => {
      if (!props.getValue()) {
        return ''
      }
      try {
        return formatarData(new Date(props.getValue())) ?? ''
      } catch {
        return props.getValue()
      }
    },
  },
  {
    accessorKey: 'acoes',
    header: 'Ações',
    cell: (props: any) => (
      <a href={props.row.original.contrato} target='_blank' rel='noreferrer'>
        <CloudDownloadIcon color='primary' />
      </a>
    ),
  },
]
