import { requestClient } from './beeginRequestClient'

type Request = {
  campanhaId: number
  arquivo: File
  nome: string
}

export async function criarRelatorioDeCampanhaAsync(request: Request): Promise<void> {
  const fd = new FormData()
  fd.append('arquivo', request.arquivo)
  fd.append('nome', request.nome)
  await requestClient.apis.campanhaApi.post(`/campanhas/${request.campanhaId}/relatorios`, fd)
}
