import { useQuery } from '@tanstack/react-query'
import { listarInformesDeRendimentosAsync } from '../api/listarInformesDeRendimentosAsync'

interface PaginationProps {
  page?: number
  size?: number
  cnpj?: string
  cpf?: string
  nome?: string
}

export const useInformesDeRendimentos = (pagination: PaginationProps) => {
  const queryKey = ['informes-de-rendimentos', { ...pagination }]

  const getAsync = async () => {
    return await listarInformesDeRendimentosAsync({ ...pagination })
  }

  const { isLoading, isError, data, refetch, isRefetching, error } = useQuery(queryKey, getAsync)

  return {
    refetch,
    isRefetching,
    isLoading,
    isError,
    data,
    error,
  }
}
