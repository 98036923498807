import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { Menu } from '../../components/menu'
import { useFilasDeEspera } from '../../store/filasDeEspera'
import { columns } from './columns'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { Main, Title, BlocoBranco } from '../listarAssessores/styles'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'

type Filtros = {
  id: number | null
}

const initialState: Filtros = {
  id: null,
}

export function ListarFilasDeEspera() {
  const [form, setForm] = useState<Filtros>(initialState)
  const [filtros, setFiltros] = useState<Filtros>(initialState)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const { data, isLoading, isError } = useFilasDeEspera({
    page,
    size,
    campanhaId: query.get('campanha-id') ?? undefined,
    investidorId: query.get('investidor-id') ?? undefined,
    id: filtros.id ? filtros.id : undefined,
  })

  useEffect(() => {
    if (isError) {
      toast.error('Erro ao obter dados.')
    }
  }, [isError])

  return (
    <Menu>
      <Main>
        <Title>Fila de espera</Title>
        <BlocoBranco>
          <PilhaDeItens direction='row'>
            <Input
              type='number'
              fullWidth
              label='ID'
              value={form.id}
              onChange={(e) => setForm({ ...form, id: parseInt(e.target.value) })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
          </PilhaDeItens>
          <br />
          <Esqueleto width='100%' height='600px' active={isLoading} doNotRenderChildrenWhenLoading>
            <Tabela
              page={page}
              pageRowsSize={size}
              onPageChange={(page: number) => setPage(page)}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              rows={data?.filasDeEspera ?? []}
              columns={columns}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
