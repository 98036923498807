import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { formatarPercentual } from '../../@beegin/react/utils/formatarPercentual'
import {
  Barra,
  BarraDeIntencoes,
  CorDaLegenda,
  Percentuais,
  Percentual,
  PercentualTitulo,
  PercentualValor,
} from './styles'

interface IBarraDeCaptacaoProps {
  valorMaximo: number
  valorInvestido: number
  carregando?: boolean
  atingiuValorMinimo: boolean
}

export function BarraDeCaptacao({
  valorMaximo = 0,
  valorInvestido = 0,
  carregando = false,
  atingiuValorMinimo = false,
}: IBarraDeCaptacaoProps) {
  if (carregando) {
    return <Esqueleto height='120px' width='100%' margin='24px 0 0 0' />
  }
  if (!valorMaximo) {
    return null
  }
  return (
    <>
      <Barra>
        <BarraDeIntencoes
          width={(valorInvestido / valorMaximo) * 100}
          backgroundColor={atingiuValorMinimo ? '#2DB350' : '#2571FF'}
        />
      </Barra>
      <Percentuais>
        <Percentual>
          <PercentualTitulo>
            <CorDaLegenda color={atingiuValorMinimo ? '#2DB350' : '#2570FF'} />
            Investido
          </PercentualTitulo>
          <PercentualValor color={atingiuValorMinimo ? '#2DB350' : '#2571FF'}>
            {formatarPercentual((valorInvestido / valorMaximo) * 100)}
          </PercentualValor>
        </Percentual>
        <Percentual>
          <PercentualTitulo>
            <CorDaLegenda color='#E6E8EB' />
            Disponível
          </PercentualTitulo>
          <PercentualValor color='#383D47'>
            {formatarPercentual(100 - (valorInvestido / valorMaximo) * 100)}
          </PercentualValor>
        </Percentual>
      </Percentuais>
    </>
  )
}
