import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { FormEvent } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import { useUsuario } from '../../store/usuario'
import { useUsuarioLogin } from '../../store/usuarioLogin'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { toast } from 'react-toastify'

export function EditarUsuarioLogin(): JSX.Element {
  let { usuarioId } = useParams()

  const { data: usuario } = useUsuario(usuarioId)
  const { data, isLoading } = useUsuarioLogin(usuarioId)

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  const carregando = isLoading

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  function copiarValor(valor: string) {
    copiarParaAreaDeTransferencia(valor)
    toast.success('Copiado para área de transferência!')
  }

  const header = () => {
    return (
      <>
        <Flex gap='8px' margin='0 0 4px 0'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </Flex>
      </>
    )
  }

  if (carregando) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Usuário</h1>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <Input value={usuario?.id ?? ''} label='ID' disabled />
            <Input value={usuario?.nomeCompleto ?? ''} label='Nome' disabled />
            <Input label='E-mail' value={usuario?.email ?? ''} disabled />
            <Flex alignItems='center' justifyContent='space-between' gap='8px'>
              <Input label='ID' value={data?.url ?? ''} multiline rows={20} fullWidth disabled />
              <ContentCopyIcon
                color='primary'
                cursor='pointer'
                onClick={() => copiarValor(data?.url ?? '')}
              />
            </Flex>
          </PilhaDeItens>
        </form>
      </>
    </Menu>
  )
}
