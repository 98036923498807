import React from 'react'
import './index.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuItem from '@mui/material/MenuItem'
import { Table, Th, Tr, Td, TableFooter, Select, PaginationButton } from './styles'
import { useReactTable, getCoreRowModel, flexRender, PaginationState } from '@tanstack/react-table'
import { Flex } from '../Flex'
interface TabelaProps {
  columns: any
  rows: any
  page: number
  pageRowsSize: number
  rowsPerPageOptions?: number[]
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

export interface ColumnProps {
  accessorKey?: string
  header: any
  enableResizing?: boolean
  cell?: (props: any) => any
  size?: number
  maxSize?: number
  minSize?: number
}

export function Tabela({
  columns,
  rows,
  page,
  pageRowsSize,
  rowsPerPageOptions = [10, 25, 50, 100],
  onPageSizeChange,
  onPageChange,
}: TabelaProps) {
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: page,
    pageSize: pageRowsSize,
  })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const table = useReactTable({
    data: rows,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  })

  const previousPage = () => {
    onPageChange(table.getState().pagination.pageIndex - 1)
    table.setPageIndex(table.getState().pagination.pageIndex - 1)
  }

  const nextPage = () => {
    onPageChange(table.getState().pagination.pageIndex + 1)
    table.setPageIndex(table.getState().pagination.pageIndex + 1)
  }

  const handlePageSizeChange = (pageSize: number) => {
    onPageSizeChange(pageSize)
    onPageChange(0)
    table.setPageSize(Number(pageSize))
    table.setPageIndex(0)
  }

  return (
    <div className='p-2'>
      <div className='overflow-x-auto'>
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    {...{
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                      }}
                    />
                  </Th>
                ))}
              </Tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    {...{
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </Table>
        {table.getRowModel().rows.length === 0 && 'Tabela vazia'}
        <TableFooter>
          <Flex gap='10px'>
            Linhas por página:
            <Select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                handlePageSizeChange(Number(e.target.value))
              }}
            >
              {rowsPerPageOptions.map((pageSize: number) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
          </Flex>
          <Flex gap='10px'>
            <PaginationButton
              className='border rounded p-1'
              onClick={() => previousPage()}
              disabled={table.getState().pagination.pageIndex === 0}
            >
              <ChevronLeftIcon />
            </PaginationButton>
            <PaginationButton className='border rounded p-1' onClick={() => nextPage()}>
              <ChevronRightIcon />
            </PaginationButton>
          </Flex>
        </TableFooter>
      </div>
    </div>
  )
}
