import axios from 'axios'

interface IEndereco {
  logradouro: string
  bairro: string
  cidade: string
  uf: string
  todosOsValoresEstaoPreenchidos:boolean
}

const enderecoVazio: IEndereco = {
  logradouro: '',
  bairro: '',
  cidade: '',
  uf: '',
  todosOsValoresEstaoPreenchidos: false
}

export async function obterEnderecoPeloCepAsync(
  cep: string,
): Promise<IEndereco> {
  const cepTratado = cep.replace(/\D/g, '')

  if (cepTratado.length !== 8) {
    return enderecoVazio
  }

  try {
    const response: any = await axios.get(
      `https://viacep.com.br/ws/${cepTratado}/json/`,
    )
    if (!response || !response?.data) {
      return enderecoVazio
    }
    if (response.data.erro) {
      return enderecoVazio
    }
    return {
      logradouro: response.data.logradouro,
      bairro: response.data.bairro,
      cidade: response.data.localidade,
      uf: response.data.uf,
      todosOsValoresEstaoPreenchidos: validarSeTodosEstaoPreenchidos(response.data)
    }
  } catch {
    return enderecoVazio
  }
}


const validarSeTodosEstaoPreenchidos = (endereco: any): boolean => {
  if (!endereco.logradouro) {
    return false
  }
  if (!endereco.bairro) {
    return false
  }
  if (!endereco.localidade) {
    return false
  }
  if (!endereco.uf) {
    return false
  }
  return true
}
