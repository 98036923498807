import { useQuery } from '@tanstack/react-query'
import { obterInvestidoresAsync } from '../api/obterInvestidoresAsync'

interface Props {
  page?: number
  size?: number
  statusDoCadastro?: string[]
  statusNoBackoffice?: number[]
  escritorioId?: string
  assessorId?: string
  busca?: string
  sorteamento?: string
  cpf?: string
  cnpj?: string
  id?: string
  nome?: string
  email?: string
  disabled?: boolean
}

export const useInvestidores = ({
  page = 0,
  size = 10,
  statusDoCadastro = [],
  escritorioId,
  assessorId,
  cpf,
  cnpj,
  id,
  nome,
  email,
  disabled = false,
}: Props) => {
  const queryKey = [
    'investidores',
    page,
    size,
    statusDoCadastro,
    escritorioId,
    assessorId,
    cpf,
    cnpj,
    id,
    nome,
    email,
    disabled,
  ]

  const options = {
    retry: false,
    enabled: !disabled,
  }

  const getAsync = async () => {
    return await obterInvestidoresAsync({
      page,
      size,
      statusDoCadastro,
      escritorioId,
      assessorId,
      cpf,
      cnpj,
      id,
      nome,
      email,
    })
  }

  const {
    isLoading,
    isError,
    data: investidores,
    refetch,
    error,
  } = useQuery(queryKey, getAsync, options)

  return {
    isLoading,
    isError,
    investidores,
    refetch,
    error,
  }
}
