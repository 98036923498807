import { assessoresApi } from '../services/api'
import { ListarAssessoresResponse } from '../types/ListarAssessoresResponse'

type Request = {
  page?: number
  size?: number
  escritorioId?: string
  nomeDoAssessor?: string
  emailDoAssessor?: string
  nomeFantasiaDoEscritorio?: string
  razaoSocialDoEscritorio?: string
}

export async function listarAssessoresAsync(request: Request): Promise<ListarAssessoresResponse> {
  const query = new URLSearchParams()
  if (request.page) {
    query.append('page', request.page.toString())
  }
  if (request.size) {
    query.append('size', request.size.toString())
  }
  if (request.escritorioId) {
    query.append('escritorio-id', request.escritorioId)
  }
  if (request.escritorioId) {
    query.append('escritorio-id', request.escritorioId)
  }
  if (request.nomeDoAssessor) {
    query.append('nome-do-assessor', request.nomeDoAssessor)
  }
  if (request.emailDoAssessor) {
    query.append('email-do-assessor', request.emailDoAssessor)
  }
  if (request.nomeFantasiaDoEscritorio) {
    query.append('nome-fantasia-do-escritorio', request.nomeFantasiaDoEscritorio)
  }
  if (request.razaoSocialDoEscritorio) {
    query.append('razao-social-do-escritorio', request.razaoSocialDoEscritorio)
  }
  const response = await assessoresApi.get<ListarAssessoresResponse>(
    `/assessores?${query.toString()}`,
  )
  return response.data
}
