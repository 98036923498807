import { useParams } from 'react-router-dom'
import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Menu } from '../../components/menu'
import { FormContainer } from '../../components/FormContainer'
import { FormularioDeStatusDoCadastro } from '../../components/FormularioDeStatusDoCadastro'
import { useAuth } from '../../hooks/auth'
import { FormularioDeStatusDoCadastroParaAdministrador } from '../../components/FormularioDeStatusDoCadastroParaAdministrador'

export function EditarStatusDoCadastro() {
  const { isInRole } = useAuth()

  let { investidorId } = useParams()

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to={`/investidores/${investidorId}`}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Editar status do cadastro</h1>
        {isInRole('Administrador') ? (
          <FormularioDeStatusDoCadastroParaAdministrador investidorId={investidorId} />
        ) : (
          <>
            <p>
              Essa funcionalidade vai alterar o status do cadastro do investidor para{' '}
              <strong>Bloqueado</strong> (antigo "Revisao"), que é o número 5.
            </p>
            <p>Com grandes poderes vêm grandes responsabilidades. Use isso com cuidado.</p>
            <FormularioDeStatusDoCadastro investidorId={investidorId} />
          </>
        )}
      </FormContainer>
    </Menu>
  )
}
