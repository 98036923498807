import { EditarInvestidorEmpresaRequest } from '../../types/EditarInvestidorEmpresaRequest'

export const initialState: EditarInvestidorEmpresaRequest = {
  id: '',
  razaoSocial: '',
  nomeFantasia: '',
  dataDaConstituicao: '',
  cnpj: '',
  cnae: '',
  faturamentoMedioMensal: 0,
  valorPatrimonial: 0,
  dataReferenciaDaSituacaoPatrimonial: '',
  endereco: {
    cep: '',
    uf: '',
    cidade: '',
    pais: '',
    bairro: '',
    logradouro: '',
    enderecoNumero: '',
    complemento: '',
  },
  telefone: {
    codigoPais: 0,
    ddd: 0,
    celular: 0,
    observacao: '',
    ramal: 0,
    tipoTelefone: '',
  },
  controladores: [],
  administradores: [],
}
