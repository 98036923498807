import { Menu } from '../../components/menu'
import { FormEvent, useState } from 'react'
import { Link } from '../../components/link'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Button } from '../../@beegin/react/components/Button'
import { obterErrorResponse } from '../../utils/obterErrorResponse'
import { criarEmailsDosInformesDeRendimentosAsync } from '../../api/criarEmailsDosInformesDeRendimentosAsync'

export function CriarEmailDoInformesDeRendimentos() {
  const navigate = useNavigate()

  const [anoCalendario, setAnoCalendario] = useState(new Date().getFullYear())

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const t = toast.loading('Enviando...')
    try {
      await criarEmailsDosInformesDeRendimentosAsync({ anoCalendario })
      toast.update(t, {
        render: 'Enviado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      })
      navigate('/informes-de-rendimentos')
    } catch (err) {
      toast.update(t, {
        render: obterErrorResponse(err, 'Erro ao fazer pedido.'),
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      })
    }
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to='/informes-de-rendimentos'>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Enviar e-mail dos informes de rendimentos</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <p>
            🚨 Mais uma vez, cuidado ao clicar nesse botão. Ele irá enviar um e-mail para todos os
            investidores com informe de rendimentos em status "Documento gerado" no ano calendário
            que você selecionar. Depois, irá alterar o status para "E-mail enviado". Seja cuidadoso.
            👀
          </p>
          <Input
            type='number'
            label='Ano calendário'
            value={anoCalendario}
            onChange={(e) => setAnoCalendario(parseInt(e.target.value))}
          />
          <div>
            <Button variant='contained' type='submit'>
              Enviar e-mails
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
