import { investidoresApi } from '../services/api'

type Response = {
  status: string
}

export async function obterStatusDoCadastroDoInvestidorAsync(
  investidorId: string,
): Promise<Response> {
  const response = await investidoresApi.get<Response>(
    `/investidores/${investidorId}/status-do-cadastro`,
  )
  return {
    status: response.data.status,
  }
}
