import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'
import { ConjuntoDeCheckboxes } from '../../@beegin/react/components/ConjuntoDeCheckboxes'
import { usePermissoes } from '../../store/permissoes'
import { useUsuarioPermissoes } from '../../store/usuarioPermissoes'

type Form = {
  permissoes: string[]
}

export function EditarUsuarioPermissoes() {
  const [form, setForm] = useState<Form>({ permissoes: [] })

  let { usuarioId } = useParams()

  const { data: permissoes } = usePermissoes()

  const { data, isLoading, mutation } = useUsuarioPermissoes(usuarioId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  const carregando = isLoading
  const desabilitarInputs = mutation.isLoading
  const desabilitarBotao = mutation.isLoading

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!usuarioId) {
      toast.error('Não há a ID do usuário antigo.')
      return
    }
    await mutation.mutateAsync({
      usuarioId: usuarioId,
      permissoes: form.permissoes,
    })
  }

  const iniciarFormulario = useCallback(async () => {
    if (!usuarioId) {
      return
    }
    if (!data) {
      return
    }

    setForm({ permissoes: data.permissoes })
  }, [data, usuarioId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  const header = () => {
    return (
      <Flex gap='4px' margin='0 0 4px 0'>
        <Link to={voltar}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </Flex>
    )
  }

  if (carregando) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Editar permissões do usuário</h1>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <ConjuntoDeCheckboxes
              label=''
              options={
                permissoes?.map((x) => ({
                  disabled: desabilitarInputs,
                  checked: form.permissoes.some((y) => y === x.nome),
                  label: x.nome,
                  onChange: () =>
                    setForm({
                      ...form,
                      permissoes: form.permissoes.some((y) => y === x.nome)
                        ? [...form.permissoes.filter((y) => y !== x.nome)]
                        : [...form.permissoes, x.nome],
                    }),
                })) ?? []
              }
            />
          </PilhaDeItens>
          <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </form>
      </>
    </Menu>
  )
}
