import { useRef } from 'react'
import { Button } from '../../@beegin/react/components/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import AttachFileIcon from '@mui/icons-material/AttachFile'

type Props = {
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  accept?: string
}

export function InputDeArquivo({ file, setFile, accept }: Props): JSX.Element {
  const inputDeArquivoRef = useRef(null)

  if (file) {
    return (
      <Button variant='text' color='error' endIcon={<DeleteIcon />} onClick={() => setFile(null)}>
        Remover documento selecionado
      </Button>
    )
  }

  return (
    <>
      <Button
        variant='text'
        startIcon={<AttachFileIcon />}
        // @ts-ignore
        onClick={() => inputDeArquivoRef?.current?.click()}
      >
        Escolher documento
        <input
          accept={accept}
          type='file'
          hidden
          ref={inputDeArquivoRef}
          multiple={false}
          onChange={(e) =>
            setFile(e.target.files && e.target.files?.length > 0 ? e.target.files[0] : null)
          }
        />
      </Button>
    </>
  )
}
