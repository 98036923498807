import { investimentosApi } from '../services/api'
import { ListarFilasDeEsperaResponse } from '../types/ListarFilasDeEsperaResponse'

type Request = {
  page: number
  size: number
  investidorId?: string
  campanhaId?: string
  status?: string
  id?: number
}

export async function listarFilasDeEsperaAsync({
  page,
  size,
  investidorId,
  campanhaId,
  status,
  id,
}: Request): Promise<ListarFilasDeEsperaResponse> {
  const query = new URLSearchParams()
  query.append('page', page.toString())
  query.append('size', size.toString())
  if (id) {
    query.append('id', id.toString())
  }
  if (investidorId) {
    query.append('investidor-id', investidorId)
  }
  if (campanhaId) {
    query.append('campanha-id', campanhaId)
  }
  if (status) {
    query.append('status', status)
  }
  const response = await investimentosApi.get<ListarFilasDeEsperaResponse>(
    `/filas-de-espera?${query}`,
  )
  return response.data
}
