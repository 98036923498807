import styled from 'styled-components'
import MaterialSelect from '@mui/material/Select'

export const Table = styled.table`
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
`

export const Th = styled.th`
  padding: 2px 4px;
  position: relative;
  font-weight: 600;
  text-align: start;
  font-size: 16px;
  height: 40px;
  border-bottom: 1px solid #eff0f1;
  padding: 0.25rem;
  padding-bottom: 0.8rem;
`

export const Tr = styled.tr`
  width: fit-content;
  height: 30px;
  // display: flex;
`

export const Td = styled.td`
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  user-select: text;
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  padding: 0.25rem;
`

export const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-top: 10px;

  div {
    align-items: center;
  }
`
export const Select = styled(MaterialSelect)`
  height: 30px;
  border: none;
`
export const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`
