import React from 'react'
import styled from 'styled-components'

interface SpacingProps {
  margin?: string
  padding?: string
  mobileMargin?: string
  mobilePadding?: string
  id?: string
  children?: React.ReactNode
}

export const StyledSpacing = styled.div<SpacingProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  @media (max-width: 768px) {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding};`}
  }
`

export function Spacing({
  margin,
  padding,
  mobileMargin,
  mobilePadding,
  children,
  id,
}: SpacingProps) {
  return (
    <StyledSpacing
      id={id}
      margin={margin}
      padding={padding}
      mobileMargin={mobileMargin}
      mobilePadding={mobilePadding}
    >
      {children}
    </StyledSpacing>
  )
}
