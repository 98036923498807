import { investimentosApi } from '../services/api'
import { ListarInvestimentosResponse } from '../types/ListarInvestimentosResponse'

type Request = {
  page: number
  size: number
  investidorId?: string
  campanhaId?: string
  status?: string
  id?: string
  nomeDoInvestidor?: string
}

export async function listarInvestimentosAsync({
  page,
  size,
  investidorId,
  campanhaId,
  status,
  id,
  nomeDoInvestidor,
}: Request): Promise<ListarInvestimentosResponse> {
  const query = new URLSearchParams()
  query.append('page', page.toString())
  query.append('size', size.toString())
  if (id) {
    query.append('id', id)
  }
  if (investidorId) {
    query.append('investidor-id', investidorId)
  }
  if (campanhaId) {
    query.append('campanha-id', campanhaId)
  }
  if (status) {
    query.append('status', status)
  }
  if (nomeDoInvestidor) {
    query.append('nome-do-investidor', nomeDoInvestidor.toLowerCase())
  }
  const response = await investimentosApi.get<ListarInvestimentosResponse>(
    `/investimentos?${query}`,
  )
  return response.data
}
