import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Index } from '../pages/index'
import { ObterInvestidor } from '../pages/obterInvestidor'
import { ListarInvestidores } from '../pages/listarInvestidores'
import { ListarHistoricoDoPerfilDoInvestidor } from '../pages/listarHistoricoDoPerfilDoInvestidor'
import { CessoesDePosicao } from '../pages/listarCessoesDePosicao'
import { CriarCessaoDePosicao } from '../pages/criarCessaoDePosicao'
import { ListarInformesDeRendimentos } from '../pages/listarInformesDeRendimentos'
import { CriarInformesDeRendimentos } from '../pages/criarInformesDeRendimentos'
import { CriarEmailDoInformesDeRendimentos } from '../pages/criarEmailDoInformesDeRendimentos'
import { ListarDocumentosDeRegulamentacao } from '../pages/listarDocumentosDeRegulamentacao'
import { CriarDocumentoDeRegulamentacao } from '../pages/criarDocumentoDeRegulamentacao'
import { ListarAssessores } from '../pages/listarAssessores'
import { ListarFilasDeEspera } from '../pages/listarFilasDeEspera'
import { EditarFilaDeEspera } from '../pages/editarFilaDeEspera'
import { ListarInvestimentos } from '../pages/listarInvestimentos'
import { EditarInvestimento } from '../pages/editarInvestimento'
import { CriarInvestimento } from '../pages/criarInvestimento'
import { EditarProtocoloDeInvestidorPessoaJuridicaNaAdvice } from '../pages/editarProtocoloDeInvestidorPessoaJuridicaNaAdvice'
import { EditarProtocoloDeInvestidorPessoaFisicaNaAdvice } from '../pages/editarProtocoloDeInvestidorPessoaFisicaNaAdvice'
import { Sair } from '../pages/sair'
import { ListarCampanhas } from '../pages/listarCampanhas'
import { CriarCampanha } from '../pages/criarCampanha'
import { EditarCampanha } from '../pages/editarCampanha'
import { ListarRelatoriosDeCampanha } from '../pages/listarRelatoriosCampanha'
import { CriarRelatorioDeCampanha } from '../pages/criarRelatorioDeCampanha'
import { ListarAtualizacoesParaInvestidores } from '../pages/listarAtualizacoesParaInvestidores'
import { CriarAtualizacoesParaInvestidores } from '../pages/criarAtualizacoesParaInvestidores'
import { ObterCampanha } from '../pages/ObterCampanha'
import { EditarStatusDoCadastro } from '../pages/editarStatusDoCadastro'
import { EditarCampanhaInformacoesDeNegociacao } from '../pages/editarCampanhaInformacoesDeNegociacao'
import { ValidadorDePermissaoPorPagina } from '../components/ValidadorDePermissaoPorPagina'
import { EditarUsuarioEmail } from '../pages/editarUsuarioEmail'
import { ListarUsuarios } from '../pages/listarUsuarios'
import { EditarUsuarioLogin } from '../pages/editarUsuarioLogin'
import { EditarUsuarioPermissoes } from '../pages/editarUsuarioPermissoes'
import { EditarUsuarioSenha } from '../pages/editarUsuarioSenha'
import { ObterUsuario } from '../pages/obterUsuario'
import { Configuracoes } from '../pages/configuracoes'

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/sair' element={<Sair />} />
        <Route
          path='/assessores'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Assessores.Read', 'Assessores.ReadWrite']}>
              <ListarAssessores />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/documentos-de-regulamentacao'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={[
                'DocumentosDeRegulamentacao.Read',
                'DocumentosDeRegulamentacao.ReadWrite',
              ]}
            >
              <ListarDocumentosDeRegulamentacao />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/documentos-de-regulamentacao/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['DocumentosDeRegulamentacao.ReadWrite']}>
              <CriarDocumentoDeRegulamentacao />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/cessoes-de-posicao'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Cessao.Read', 'Cessao.ReadWrite']}>
              <CessoesDePosicao />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/cessoes-de-posicao/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Cessao.ReadWrite']}>
              <CriarCessaoDePosicao />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investidores'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={['Investidores.Read', 'Investidores.ReadWrite']}
            >
              <ListarInvestidores />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investidores/:investidorId'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={['Investidores.Read', 'Investidores.ReadWrite']}
            >
              <ObterInvestidor />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investidores/:investidorId/status-do-cadastro'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Investidores.ReadWrite']}>
              <EditarStatusDoCadastro />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investidores/:investidorId/protocolo-na-advice'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Advice.ReadWrite']}>
              <EditarProtocoloDeInvestidorPessoaFisicaNaAdvice />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/informes-de-rendimentos'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={['InformeRendimentos.Read', 'InformeRendimentos.ReadWrite']}
            >
              <ListarInformesDeRendimentos />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/informes-de-rendimentos/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['InformeRendimentos.ReadWrite']}>
              <CriarInformesDeRendimentos />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/informes-de-rendimentos/e-mails/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['InformeRendimentos.ReadWrite']}>
              <CriarEmailDoInformesDeRendimentos />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investidores/:investidorId/historico-do-perfil-de-investidor'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={['Investidores.Read', 'Investidores.ReadWrite']}
            >
              <ListarHistoricoDoPerfilDoInvestidor />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Campanhas.Read', 'Campanhas.ReadWrite']}>
              <ListarCampanhas />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Campanhas.ReadWrite']}>
              <CriarCampanha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Campanhas.Read', 'Campanhas.ReadWrite']}>
              <ObterCampanha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/editar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Campanhas.ReadWrite']}>
              <EditarCampanha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/informacoes-de-negociacao'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Campanhas.Read', 'Campanhas.ReadWrite']}>
              <EditarCampanhaInformacoesDeNegociacao />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/relatorios'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={[
                'AtualizacoesParaInvestidores.Read',
                'AtualizacoesParaInvestidores.ReadWrite',
              ]}
            >
              <ListarRelatoriosDeCampanha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/relatorios/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['AtualizacoesParaInvestidores.ReadWrite']}>
              <CriarRelatorioDeCampanha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/atualizacoes-para-investidores'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={[
                'AtualizacoesParaInvestidores.Read',
                'AtualizacoesParaInvestidores.ReadWrite',
              ]}
            >
              <ListarAtualizacoesParaInvestidores />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/campanhas/:slug/atualizacoes-para-investidores/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['AtualizacoesParaInvestidores.ReadWrite']}>
              <CriarAtualizacoesParaInvestidores />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/filas-de-espera'
          element={
            <ValidadorDePermissaoPorPagina
              permissoes={['FilaDeEspera.Read', 'FilaDeEspera.ReadWrite']}
            >
              <ListarFilasDeEspera />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/filas-de-espera/:filaDeEsperaId/editar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['FilaDeEspera.ReadWrite']}>
              <EditarFilaDeEspera />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investimentos'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Intencoes.Read', 'Intencoes.ReadWrite']}>
              <ListarInvestimentos />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investimentos/criar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Intencoes.ReadWrite']}>
              <CriarInvestimento />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/investimentos/:investimentoId/editar'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Intencoes.ReadWrite']}>
              <EditarInvestimento />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/protocolo-de-investidor-na-advice'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Advice.ReadWrite']}>
              <EditarProtocoloDeInvestidorPessoaJuridicaNaAdvice />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <ListarUsuarios />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios/:usuarioId'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <ObterUsuario />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios/:usuarioId/email'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <EditarUsuarioEmail />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios/:usuarioId/login'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <EditarUsuarioLogin />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios/:usuarioId/permissoes'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <EditarUsuarioPermissoes />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/usuarios/:usuarioId/senha'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <EditarUsuarioSenha />
            </ValidadorDePermissaoPorPagina>
          }
        />
        <Route
          path='/configuracoes'
          element={
            <ValidadorDePermissaoPorPagina permissoes={['Administrador']}>
              <Configuracoes />
            </ValidadorDePermissaoPorPagina>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
