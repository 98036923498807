import { FormEvent, useCallback, useEffect, useState } from 'react'
import { ConjuntoDeRadios } from '../../@beegin/react/components/ConjuntoDeRadios'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { PerguntaDoSuitability } from '../../types/PerguntaDoSuitability'
import { Spacing } from '../Spacing'
import { Text } from '../../@beegin/react/components/Text'
import { useSimulacaoDoSuitability } from '../../store/simulacaoDoSuitability'
import { Checkbox } from '../../@beegin/react/components/Checkbox'
import { ObterSimulacaoDeResultadoDoSuitabilityRequest } from '../../types/ObterSimulacaoDeResultadoDoSuitabilityRequest'
import { useSuitability } from '../../store/suitability'
import { useInvestidor } from '../../store/investidor'
import { useTipoDeInvestidor } from '../../store/tipoDeInvestidor'
import { Flex } from '../Flex'
import { Button } from '../../@beegin/react/components/Button'
import { useMutationToast } from '../../hooks/useMutationToast'

interface Props {
  investidorId: string
}

export function FormularioDeSuitabilityDoInvestidor({ investidorId }: Props) {
  const [aceitoInadequacao, setAceitouInadequacao] = useState(false)
  const [form, setForm] = useState<PerguntaDoSuitability[]>([])
  const [simulacaoForm, setSimulacaoForm] =
    useState<ObterSimulacaoDeResultadoDoSuitabilityRequest>()

  const { data: perguntasPadrao } = useSuitability()

  const { data: investidor, isError, isLoading, mutation } = useInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const { data: tipoDeInvestidor } = useTipoDeInvestidor(investidorId)

  const { data: simulacao, isLoading: simulacaoIsLoading } =
    useSimulacaoDoSuitability(simulacaoForm)

  const iniciarFormulario = useCallback(async () => {
    if (!investidorId) {
      return
    }
    if (!investidor) {
      return
    }
    if (!investidor.suitability) {
      return
    }
    if (!perguntasPadrao) {
      return
    }

    const respostas = investidor.suitability.perguntasComRespostas

    const perguntasComRespostasModel = perguntasPadrao.perguntas.map((pergunta) => {
      const respostasDisponiveis = perguntasPadrao.perguntas
        .find((x) => x.id === pergunta.id)
        ?.respostasDisponiveis.map((x) => ({
          id: x.id,
          titulo: x.titulo,
        }))

      const respostaId = respostas?.find((x) => x.perguntaId == pergunta.id)?.respostaId

      return {
        perguntaId: pergunta.id,
        pergunta: pergunta.titulo,
        respostaId: respostaId,
        resposta: respostasDisponiveis?.find((x) => x.id == respostaId)?.titulo,
        respostasDisponiveis: respostasDisponiveis,
      }
    }) as PerguntaDoSuitability[]

    setForm(perguntasComRespostasModel)
    setAceitouInadequacao(investidor.suitability.perfilDeInvestimentoFoiAceito ?? false)
  }, [investidor, investidorId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  useEffect(() => {
    const timer = setTimeout(() => {
      setSimulacaoForm(() => ({
        perguntasComRespostas: form.map((x) => ({
          perguntaId: x.perguntaId,
          respostaId: x.respostaId,
        })),
      }))
    }, 1000)
    return () => clearTimeout(timer)
  }, [form])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await mutation.mutateAsync({
      suitability: {
        perfilDeInvestimentoFoiAceito: aceitoInadequacao,
        perguntasComRespostas: form,
      },
    })
  }

  const desabilitarInputs = tipoDeInvestidor?.tipo?.toLocaleLowerCase() === 'pj' ? false : true
  const desabilitarBotao = tipoDeInvestidor?.tipo?.toLocaleLowerCase() === 'pj' ? false : true

  if (isError) {
    return <p>Erro.</p>
  }

  if (!investidor || isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  if (form.length === 0) {
    return (
      <Spacing margin='0 0 32px 0'>
        <Text>O suitability não foi preenchido.</Text>
      </Spacing>
    )
  }

  return (
    <>
      <Spacing margin='0 0 32px 0'>
        <form onSubmit={onSubmit} id='formulario-de-suitability'>
          <PilhaDeItens gap={3}>
            {form.map((pergunta) => (
              <ConjuntoDeRadios
                disabled={desabilitarInputs}
                direction='column'
                key={pergunta.perguntaId}
                label={pergunta.pergunta}
                value={pergunta.respostaId}
                options={
                  pergunta.respostasDisponiveis?.map((resposta) => ({
                    value: resposta.id.toString(),
                    label: resposta.titulo,
                  })) ?? []
                }
                onChange={(e) => {
                  setForm((form) =>
                    [
                      ...form.filter((x) => x.perguntaId !== pergunta.perguntaId),
                      { ...pergunta, respostaId: parseInt(e.target.value) },
                    ].sort((a, b) => a.perguntaId - b.perguntaId),
                  )
                }}
              />
            ))}
          </PilhaDeItens>
        </form>
      </Spacing>
      {simulacaoIsLoading && (
        <>
          <Esqueleto width='100%' margin='0 0 8px 0' />
          <Esqueleto width='40%' margin='0 0 32px 0' />
        </>
      )}
      {simulacao?.perfil && (
        <>
          <Text fontWeight='400' fontSize='18px' lineHeight='23.4px' margin='0 0 8px 0'>
            Seu perfil de risco é:
          </Text>
          <Text fontWeight='600' fontSize='18px' lineHeight='23.4px' margin='0 0 32px 0'>
            {simulacao.perfil}
          </Text>
        </>
      )}
      {simulacao?.precisaAceitarPerfilDeInvestimento && (
        <>
          <Text fontWeight='700' fontSize='14px' lineHeight='16px' margin='0 0 12px 0'>
            Inadequação do perfil de investimento
          </Text>
          <Spacing margin='0 0 32px 0'>
            <Checkbox
              align='start'
              label={
                <Text fontWeight='400' fontSize='12px' lineHeight='16px'>
                  Muito embora tenha fornecido as informações necessárias e aceitado a definição do
                  meu perfil de investidor junto à Beegin, desejo dar continuidade à operação com
                  Ativos Tokenizados ciente de suas características e riscos de perda envolvidos,
                  mesmo sabendo que esta operação é inadequada em relação ao que me seria
                  recomendado de acordo com o meu perfil, considerando os meus objetivos, situação
                  financeira e conhecimento em matéria de investimentos.
                </Text>
              }
              onChange={() => setAceitouInadequacao(!aceitoInadequacao)}
              checked={aceitoInadequacao}
            />
          </Spacing>
        </>
      )}
      <Flex justifyContent='flex-end'>
        <Button
          form='formulario-de-suitability'
          type='submit'
          carregando={mutation.isLoading}
          disabled={desabilitarBotao}
        >
          Salvar
        </Button>
      </Flex>
    </>
  )
}
