import { FormEvent, useEffect, useState } from 'react'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Select } from '../../@beegin/react/components/Select'
import { tipoDeTelefone } from '../../enums/tipoDeTelefone'
import { TipoDeTelefone } from '../../types/TipoDeTelefone'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Button } from '../../@beegin/react/components/Button'
import { initialState } from './initialState'
import { Flex } from '../Flex'
import { useInvestidor } from '../../store/investidor'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeTelefoneDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const [form, setForm] = useState(initialState)

  const { data, isError, isLoading, mutation } = useInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  useEffect(() => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ ...data.telefone })
  }, [investidorId, data])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await mutation.mutateAsync({ telefone: form })
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  if (isError) {
    return <p>Erro.</p>
  }

  if (!data || isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 32px 0'>
        <Select
          required
          label='Tipo de telefone'
          value={form.tipoTelefone as unknown as string}
          options={Object.values(tipoDeTelefone).map((item) => ({
            label: item.title,
            value: item.asString,
          }))}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, tipoTelefone: e.target.value as TipoDeTelefone })}
        />
        <MaskedInput
          required
          mascara={{ format: '####' }}
          label='DDI'
          value={form.codigoPais}
          disabled={desabilitarInputs}
          onValueChange={(e) => setForm({ ...form, codigoPais: parseInt(e.value) })}
        />
        <MaskedInput
          required
          mascara='ddd'
          label='DDD'
          value={form.ddd}
          disabled={desabilitarInputs}
          onValueChange={(e) => setForm({ ...form, ddd: parseInt(e.value) })}
        />
        <MaskedInput
          required
          mascara={{ format: '# ####-####' }}
          label='Telefone'
          value={form.celular}
          disabled={desabilitarInputs}
          onValueChange={(e) => setForm({ ...form, celular: parseFloat(e.value) })}
        />
      </PilhaDeItens>
      <Flex justifyContent='flex-end'>
        <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
          Salvar
        </Button>
      </Flex>
    </form>
  )
}
