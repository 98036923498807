import { useQuery } from '@tanstack/react-query'
import { obterPerguntasDoSuitabilityAsync } from '../api/obterPerguntasDoSuitabilityAsync'

export const useSuitability = () => {
  const queryKey = ['suitability']

  const options = {
    retry: true,
  }

  const getAsync = async () => {
    return await obterPerguntasDoSuitabilityAsync()
  }

  const response = useQuery(queryKey, getAsync, options)

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
  }
}
