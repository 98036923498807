import React from 'react'
import { TextProps } from './props'
import { StyledParagraph, StyledSpan } from './styles'

export function Text({
  type = 'p',
  fontSize,
  mobileFontSize,
  margin,
  mobileMargin,
  color,
  fontWeight,
  mobileFontWeight,
  lineHeight,
  mobileLineHeight,
  textAlign,
  mobileTextAlign,
  children,
  onClick,
  cursor,
}: TextProps) {
  const props = {
    cursor: cursor,
    onClick: onClick,
    lineHeight: lineHeight,
    mobileLineHeight: mobileLineHeight,
    fontWeight: fontWeight,
    mobileFontWeight: mobileFontWeight,
    color: color,
    fontSize: fontSize,
    mobileFontSize: mobileFontSize,
    margin: margin,
    mobileMargin: mobileMargin,
    textAlign: textAlign,
    mobileTextAlign: mobileTextAlign,
  }

  if (type === 'span') {
    return <StyledSpan {...props}>{children}</StyledSpan>
  }

  return <StyledParagraph {...props}>{children}</StyledParagraph>
}
