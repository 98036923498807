import { ObterSimulacaoDeResultadoDoSuitabilityRequest } from '../types/ObterSimulacaoDeResultadoDoSuitabilityRequest'
import { requestClient } from './beeginRequestClient'

type ObterInvestidorSuitabilitySimulacaoResponse = {
  pontuacao: number
  perfil: 'Conservador' | 'Moderado' | 'Agressivo'
  precisaAceitarPerfilDeInvestimento: boolean
}

export const obterSimulacaoDeResultadoDoSuitabilityAsync = async (
  request: ObterSimulacaoDeResultadoDoSuitabilityRequest,
): Promise<ObterInvestidorSuitabilitySimulacaoResponse> => {
  const response = await requestClient.apis.investidores.post('/suitability/simulacao', request)
  return response?.data as ObterInvestidorSuitabilitySimulacaoResponse
}
