import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { criarInvestimentoAsync } from '../api/criarInvestimentoAsync'
import { editarStatusDoInvestimentoAsync } from '../api/editarStatusDoInvestimentoAsync'
import { obterInvestimentoAsync } from '../api/obterInvestimentoAsync'

export const useInvestimento = (id?: string) => {
  const queryClient = useQueryClient()

  const queryKey = ['investimento', id]

  const options = {
    enabled: !!id,
  }

  async function getAsync() {
    if (!id) {
      return null
    }
    return await obterInvestimentoAsync(id)
  }

  const response = useQuery(queryKey, getAsync, options)

  const criarInvestimentoMutation = useMutation(criarInvestimentoAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['investimentos'])
    },
  })

  const editarStatusMutation = useMutation(editarStatusDoInvestimentoAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
      await queryClient.invalidateQueries(['investimentos'])
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch,
    criarInvestimentoMutation: criarInvestimentoMutation,
    editarStatusMutation: editarStatusMutation,
  }
}
