import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { listarDocumentosDeRegulamentacaoAsync } from '../api/listarDocumentosDeRegulamentacaoAsync'
import { criarDocumentoDeRegulamentacaoAsync } from '../api/criarDocumentoDeRegulamentacaoAsync'
import { deletarDocumentoDeRegulamentacaoAsync } from '../api/deletarDocumentoDeRegulamentacaoAsync'

type Props = {
  enabled?: boolean
}

type CriarMutation = {
  nome: string
  arquivo: File
}

export const useDocumentosDeRegulamentacao = ({ enabled = true }: Props) => {
  const queryClient = useQueryClient()

  const queryKey = ['documentos-de-regulamentacao']

  const { isLoading, isError, data } = useQuery(queryKey, listarDocumentosDeRegulamentacaoAsync, {
    enabled,
  })

  async function criarAsync({ nome, arquivo }: CriarMutation) {
    const formData = new FormData()
    formData.append('arquivo', arquivo)
    formData.append('nomeDoDocumento', nome)
    await criarDocumentoDeRegulamentacaoAsync(formData)
  }

  const criarMutation = useMutation(criarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  const deletarMutation = useMutation(deletarDocumentoDeRegulamentacaoAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data,
    criarMutation,
    deletarMutation,
  }
}
