import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'

type Props = RadioGroupProps & {
  label: string
  disabled?: boolean
  direction?: 'row' | 'column'
  options: {
    value: string
    label: string
  }[]
  helperText?:string
  error?:boolean
}

export function ConjuntoDeRadios({
  label,
  disabled = false,
  direction = 'row',
  helperText,
  error,
  options,
  ...props
}: Props) {
  return (
    <FormControl error={error}>
      <FormLabel sx={{ marginBottom: '8px' }}>{label}</FormLabel>
      <RadioGroup row={direction === 'row'} {...props}>
        {options.map((option) => (
          <FormControlLabel
            disabled={disabled}
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            sx={{
              marginBottom: '4px',
              '&:last-child': { marginBottom: 0 },
            }}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
