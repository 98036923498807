import { requestClient } from './beeginRequestClient'

type Request = {
  campanhaId: number
  arquivo: File | null
  titulo: string
  conteudo: string
  tipo: 'Arquivo' | 'LinkDeVideo'
}

export async function criarAtualizacaoParaInvestidoresAsync(request: Request): Promise<void> {
  const fd = new FormData()
  if (request.arquivo) {
    fd.append('arquivo', request.arquivo)
  }
  fd.append('titulo', request.titulo)
  fd.append('conteudo', request.conteudo)
  fd.append('tipo', request.tipo)
  await requestClient.apis.campanhaApi.post(
    `/campanhas/${request.campanhaId}/atualizacoes-para-investidores`,
    fd,
  )
}
