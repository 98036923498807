import styled from 'styled-components'

export const Estatisticas = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
`
export const ColunaDeBlocos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`
export const LinhaDeBlocos = styled.div`
  display: flex;
  gap: 16px;
`

export const BlocoAzulClaro = styled.div`
  background-color: #2571ff;
  border-radius: 8px;
  padding: 24px;
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0;
`

export const BlocoBranco = styled(BlocoAzulClaro)`
  background-color: white;
  // height: 230px;
`

export const BlocoAzulEscuro = styled(BlocoAzulClaro)`
  background-color: #0852dd;
`
export const TituloBloco = styled.span`
  // font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
`

export const SubtituloBloco = styled.span`
  // font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`

export const ValorBloco = styled.span`
  // font-family: Ubuntu;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
`
export const HeaderGrafico = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const TituloGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #212427;
`

export const SubtituloGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 5px;
`

export const DataGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #636873;
`
