import Skeleton from '@mui/material/Skeleton'
import { EsqueletoProps } from './props'
import { Container } from './styles'

export function Esqueleto({
  active = true,
  width = '100%',
  height = '40px',
  margin,
  mobileMargin,
  children = <></>,
  doNotRenderChildrenWhenLoading = false
}: EsqueletoProps) {
  if (active) {
    return (
      <Container
        width={width}
        height={height}
        margin={margin}
        mobileMargin={mobileMargin}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={width}
          height={height}
        >
          {doNotRenderChildrenWhenLoading ? null : children}
        </Skeleton>
      </Container>
    )
  }
  return children
}
