import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useVoltar } from '../../hooks/useVoltar'
import { useFilaDeEspera } from '../../store/filaDeEspera'
import { toast } from 'react-toastify'
import { FormContainer } from '../../components/FormContainer'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Input } from '../../@beegin/react/components/Input'
import { Select } from '../../@beegin/react/components/Select'
import { FormEvent, useCallback, useEffect, useState } from 'react'

type Form = {
  status: string
}

export function EditarFilaDeEspera() {
  let { filaDeEsperaId } = useParams()

  const voltar = useVoltar('/filas-de-espera')

  const [form, setForm] = useState<Form>({
    status: '',
  })

  const { data, isLoading, editarStatusMutation } = useFilaDeEspera(filaDeEsperaId)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!filaDeEsperaId) {
      toast.error('Erro ao obter id da espera.')
      return
    }
    if (!data?.campanhaId) {
      toast.error('Erro ao obter id da campanha.')
      return
    }
    await editarStatusMutation.mutateAsync({
      status: form.status,
    })
  }

  const iniciarFormulario = useCallback(async () => {
    if (!filaDeEsperaId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ status: data.status ?? '' })
  }, [data, filaDeEsperaId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  useMutationToast({
    mutation: editarStatusMutation,
    sucesso: 'Fila de espera editada!',
    erro: 'Erro ao editar fila de espera.',
    carregando: 'Carregando...',
  })

  const desabilitarInputs = editarStatusMutation.isLoading

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to={voltar}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Editar fila de espera</h1>
        <Esqueleto height='80px' active={isLoading}>
          <form onSubmit={onSubmit}>
            <PilhaDeItens gap={3} margin='0 0 32px 0'>
              <Input disabled label='ID' value={filaDeEsperaId} />
              <Select
                required
                disabled={desabilitarInputs}
                label='Status'
                value={form.status}
                onChange={(e) => setForm({ ...form, status: e.target.value as string })}
                options={[
                  { label: 'Lista de espera', value: 'ListaDeEspera' },
                  { label: 'Proposta convertida', value: 'PropostaConvertida' },
                  { label: 'Desistência', value: 'Desistencia' },
                  { label: 'Campanha finalizada', value: 'CampanhaFinalizada' },
                ]}
              />
            </PilhaDeItens>
            <Button type='submit' carregando={desabilitarInputs}>
              Salvar
            </Button>
          </form>
        </Esqueleto>
      </FormContainer>
    </Menu>
  )
}
