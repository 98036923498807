export const cpfEstaValido = (value: string) => {
  if (!value) {
    return false
  }

  let sum
  let rest
  sum = 0

  value = value.replace(/\D/g, '')

  for (let n = 0; n <= 9; n++) {
    if (
      value ===
      String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n) +
        String(n)
    ) {
      return false
    }
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(value.substring(i - 1, i)) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(value.substring(9, 10))) {
    return false
  }

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(value.substring(i - 1, i)) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(value.substring(10, 11))) {
    return false
  }

  return true
}
