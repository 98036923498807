import { investidoresApi } from '../services/api'

interface ObterInvestidorNaVisaoDoBackofficeResponse {
  id: string
  nome: string
  assessorId: string
  origem: string
  statusBackoffice: number | null
  observacoesDoBackoffice: string
}

export async function obterInvestidorNaVisaoDoBackofficeAsync(
  investidorId: string,
): Promise<ObterInvestidorNaVisaoDoBackofficeResponse> {
  const response = await investidoresApi.get<ObterInvestidorNaVisaoDoBackofficeResponse>(
    `/investidores/${investidorId}/observacoes`,
  )
  return response.data
}
