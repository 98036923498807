import { Flex } from '../../components/Flex'
import { Link } from '../../components/link'
import { formatarData } from '../../utils/formatarData'
import LaunchIcon from '@mui/icons-material/Launch'
import { ColumnProps } from '../../components/Tabela'

export const columns: ColumnProps[] = [
  {
    accessorKey: 'id',
    header: '',
    maxSize: 20,
    cell: (props: any) => {
      return (
        <Link to={`/investidores/${props.getValue()}`}>
          <Flex alignItems='center' gap='4px'>
            <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
          </Flex>
        </Link>
      )
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    size: 1,
    cell: (props: any) => (
      <p
        style={{
          maxWidth: '50px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.getValue()}
      </p>
    ),
  },
  {
    accessorKey: 'nome',
    header: 'Nome',
    cell: (props: any) => (
      <p
        style={{
          maxWidth: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.getValue()}
      </p>
    ),
  },
  {
    accessorKey: 'email',
    header: 'E-mail',
    enableResizing: false,
    cell: (props: any) => (
      <p
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.getValue()}
      </p>
    ),
  },
  { accessorKey: 'cpfOuCnpj', header: 'CPF/CNPJ' },
  {
    accessorKey: 'nomeDoEscritorio',
    header: 'Escritório',
  },
  {
    accessorKey: 'nomeDoAssessor',
    header: 'Assessor',
  },
  {
    accessorKey: 'dataDoCadastro',
    header: 'Data',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy'),
  },
  { accessorKey: 'status', header: 'Status' },
  {
    accessorKey: 'statusBackoffice',
    header: 'Status BO',
  },
  { accessorKey: 'origem', header: 'Origem' },
]
