import axios from 'axios'
import { configurarRequisicaoDoAxiosAsync } from '../configuration/configurarRequisicaoDoAxiosAsync'

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_BEEGIN,
})

export const investimentosApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_INVESTIMENTOS,
})

export const informeDeRendimentosApi = axios.create({
  baseURL: process.env.REACT_APP_URL_INFORME_DE_RENDIMENTOS,
})

export const regulamentacoesApi = axios.create({
  baseURL: process.env.REACT_APP_URL_REGULAMENTACOES,
})

export const investidoresApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_INVESTIDORES,
})

export const adviceApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_ADVICE,
})

export const campanhaApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_CAMPANHAS,
})

export const assessoresApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_ASSESSORES,
})

export const usuariosApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API_USUARIOS,
})

api.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

informeDeRendimentosApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

regulamentacoesApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

investidoresApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

adviceApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

campanhaApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

assessoresApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

investimentosApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})

usuariosApi.interceptors.request.use(async (config) => {
  return await configurarRequisicaoDoAxiosAsync(config)
})
