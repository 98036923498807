import styled from 'styled-components'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'

interface BarProps {
  data: string
  reservas: number
  valorInvestido: number
}

const data: BarProps[] = [
  // Add your data here
  { data: '07/05', reservas: 20, valorInvestido: 4000 },
  { data: '08/05', reservas: 18, valorInvestido: 3500 },
  { data: '09/05', reservas: 15, valorInvestido: 3200 },
  { data: '10/05', reservas: 20, valorInvestido: 4000 },
  { data: '11/05', reservas: 15, valorInvestido: 3200 },
  { data: '12/05', reservas: 8, valorInvestido: 3000 },
  { data: '13/05', reservas: 15, valorInvestido: 3200 },
  // ...
]

const TickerValor = styled.tspan`
  // font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #212427;
`

const TickerData = styled.tspan`
  // font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #212427;
`

const TickerReservas = styled.text`
  // font-family: Ubuntu;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  color: #212427;
`

export const GraficoBarraVertical: React.FC = () => {
  const CustomXTick = (props: { x: number; y: number; payload: { value: string } }) => {
    const { x, y, payload } = props
    const dataItem = data.find((item) => item.data === payload.value)
    const valorInvestido = dataItem ? dataItem.valorInvestido : 0
    return (
      <text x={x} y={y} textAnchor='middle' dy={20}>
        <TickerValor x={x}>{formatarDinheiro(valorInvestido)}</TickerValor>
        <TickerData x={x} dy={25}>
          {payload.value}
        </TickerData>
      </text>
    )
  }

  const CustomYTick = (props: { x: number; y: number; payload: { value: string } }) => {
    const { x, y, payload } = props
    return (
      <TickerReservas x={x} y={y} textAnchor='end' dy={4}>
        {payload.value}
      </TickerReservas>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={212}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: -20,
          left: -40,
          bottom: 25,
        }}
      >
        <XAxis dataKey='data' axisLine={false} tickLine={false} tick={CustomXTick} />
        <YAxis axisLine={false} tickLine={false} tick={CustomYTick} />
        <Bar barSize={30} dataKey='reservas' fill='#0852DD' radius={16} />
      </BarChart>
    </ResponsiveContainer>
  )
}
