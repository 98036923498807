import { Menu } from '../../components/menu'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useCessaoDePosicao } from '../../store/cessaoDePosicao'
import { Link } from '../../components/link'
import { toast } from 'react-toastify'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useInvestidores } from '../../store/investidores'
import { useInvestimentosPorInvestidor } from '../../store/investimentosPorInvestidor'
import DeleteIcon from '@mui/icons-material/Delete'
import { validarFormulario } from './validacaoDeFormulario'
import { FormularioDeNovaCessaoDePosicao } from './formularioDeNovaCessaoDePosicao'
import { formatarPercentual } from '../../utils/percentual'
import { transformarDataFormatadaEmTimestamp } from '../../utils/formatarData'
import { useNavigate } from 'react-router-dom'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { ConjuntoDeRadios } from '../../@beegin/react/components/ConjuntoDeRadios'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { Select } from '../../@beegin/react/components/Select'
import { Button } from '../../@beegin/react/components/Button'
import { obterErrorResponse } from '../../utils/obterErrorResponse'

export function CriarCessaoDePosicao() {
  const navigate = useNavigate()

  const opcoesParaTipoDeVendedor = [
    { label: 'Pessoa física', value: 'pf' },
    { label: 'Pessoa jurídica', value: 'pj' },
  ]

  const inputDeArquivoRef = useRef(null)

  const [documentoDoVendedor, setDocumentoDoVendedor] = useState('')
  const [documentoDoComprador, setDocumentoDoComprador] = useState('')
  const [tipoDeVendedor, setTipoDeVendedor] = useState('')
  const [tipoDeComprador, setTipoDeComprador] = useState('')
  const [campanhaSelecionada, setCampanhaSelecionada] = useState<number | null>(null)
  const [form, setForm] = useState({} as FormularioDeNovaCessaoDePosicao)
  const [arquivo, setArquivo] = useState<File | null>(null)

  const { mutation } = useCessaoDePosicao({ disabled: true })

  const obterDocumentoSeFoiPreenchidoCorretamente = (
    tipoDoDocumento: string,
    tipoDesejado: 'pf' | 'pj',
    documento: string,
  ) => {
    if (tipoDoDocumento === 'pj' && tipoDesejado === 'pj' && documento.length === 14) {
      return documento
    } else if (tipoDoDocumento === 'pf' && tipoDesejado === 'pf' && documento.length === 11) {
      return documento
    }
    return ''
  }

  const cpfDoVendedor = obterDocumentoSeFoiPreenchidoCorretamente(
    tipoDeVendedor,
    'pf',
    documentoDoVendedor,
  )
  const cnpjDoVendedor = obterDocumentoSeFoiPreenchidoCorretamente(
    tipoDeVendedor,
    'pj',
    documentoDoVendedor,
  )

  const { investidores: pesquisaDeVendedores, isLoading: carregandoVendedor } = useInvestidores({
    size: 1,
    cpf: cpfDoVendedor,
    cnpj: cnpjDoVendedor,
    disabled: !cpfDoVendedor && !cnpjDoVendedor,
  })

  const cpfDoComprador = obterDocumentoSeFoiPreenchidoCorretamente(
    tipoDeComprador,
    'pf',
    documentoDoComprador,
  )
  const cnpjDoComprador = obterDocumentoSeFoiPreenchidoCorretamente(
    tipoDeComprador,
    'pj',
    documentoDoComprador,
  )

  const { investidores: pesquisaDeCompradores, isLoading: carregandoComprador } = useInvestidores({
    size: 1,
    cpf: cpfDoComprador,
    cnpj: cnpjDoComprador,
    disabled: !cpfDoComprador && !cnpjDoComprador,
  })

  const vendedor = pesquisaDeVendedores?.find(
    (x) => documentoDoVendedor && x.cpfOuCnpj && x.cpfOuCnpj === documentoDoVendedor,
  )
  const comprador = pesquisaDeCompradores?.find(
    (x) => documentoDoComprador && x.cpfOuCnpj && x.cpfOuCnpj === documentoDoComprador,
  )

  const { data: investimentos, isLoading: carregandoInvestimentos } = useInvestimentosPorInvestidor(
    vendedor?.id ?? '',
    'realizado',
  )

  const semInvestimentosDisponiveis =
    !investimentos || !investimentos.investimentos || investimentos?.investimentos?.length === 0

  const vendedorNaoPossuiInvestimentos = vendedor && semInvestimentosDisponiveis

  const campanhasQueVendedorInvestiu =
    investimentos?.investimentos
      ?.map((investimento) => ({
        value: investimento.campanha.id,
        label: investimento.campanha.nome,
      }))
      .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i) ?? []

  const investimentosNaCampanhaEscolhida =
    investimentos?.investimentos
      ?.filter((x) => x.campanha.id === campanhaSelecionada)
      .map((item) => ({ value: item.id, label: item.valor })) ?? []

  const calcularPercentualDaComprado = (): string => {
    if (!form.valorCedido) {
      return '0%'
    }
    if (!form.investimentoId) {
      return '0%'
    }
    const investimento = investimentosNaCampanhaEscolhida.find(
      (x) => x.value === form.investimentoId,
    )
    if (!investimento) {
      return '0%'
    }

    return formatarPercentual(form.valorCedido / investimento.label)
  }

  const percentualComprado = calcularPercentualDaComprado()

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!arquivo) {
      toast.error('Arquivo não selecionado.')
      return
    }

    try {
      validarFormulario(form)
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err?.message as string)
      }
      return
    }

    await mutation.mutateAsync({
      ...form,
      arquivo,
      dataDaCessao: transformarDataFormatadaEmTimestamp(form.dataDaCessao),
    })
  }

  useEffect(() => {
    if (!vendedor) {
      return
    }
    setForm((form) => ({ ...form, investidorIdDoVendedor: vendedor.id }))
  }, [vendedor])

  useEffect(() => {
    if (!comprador) {
      return
    }
    setForm((form) => ({ ...form, investidorIdDoComprador: comprador.id }))
  }, [comprador])

  useEffect(() => {
    if (mutation.isError) {
      toast.error(obterErrorResponse(mutation.error, 'Erro ao enviar dados.'))
    } else if (mutation.isSuccess) {
      toast.success('Dados atualizados com sucesso!')
      navigate('/cessoes-de-posicao')
    }
  }, [mutation.isError, mutation.isSuccess, mutation.error, navigate])

  if (mutation.isLoading) {
    return (
      <Menu>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Esqueleto height='40px' />
          <Esqueleto height='40px' width='20%' />
          <Esqueleto height='40px' width='50%' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' width='50%' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
          <Esqueleto height='40px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to='/cessoes-de-posicao'>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Nova cessão de posição</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <ConjuntoDeRadios
            label='Vendedor'
            options={opcoesParaTipoDeVendedor}
            value={tipoDeVendedor}
            onChange={(e) => setTipoDeVendedor(e.target.value)}
          />
          {tipoDeVendedor === 'pj' ? (
            <MaskedInput
              mascara='cnpj'
              label='CNPJ'
              value={documentoDoVendedor}
              onValueChange={(e) => setDocumentoDoVendedor(e.value)}
              disabled={!tipoDeVendedor}
            />
          ) : (
            <MaskedInput
              mascara='cpf'
              label='CPF'
              value={documentoDoVendedor}
              onValueChange={(e) => setDocumentoDoVendedor(e.value)}
              disabled={!tipoDeVendedor}
            />
          )}
          <Esqueleto active={carregandoVendedor} height='65px'>
            <Input label='Nome' value={vendedor?.nome ?? ''} disabled />
          </Esqueleto>
          <br />
          <ConjuntoDeRadios
            label='Comprador'
            options={opcoesParaTipoDeVendedor}
            value={tipoDeComprador}
            onChange={(e) => setTipoDeComprador(e.target.value)}
          />
          {tipoDeComprador === 'pj' ? (
            <MaskedInput
              mascara='cnpj'
              label='CNPJ'
              value={documentoDoComprador}
              onValueChange={(e) => setDocumentoDoComprador(e.value)}
              disabled={!tipoDeComprador}
            />
          ) : (
            <MaskedInput
              mascara='cpf'
              label='CPF'
              value={documentoDoComprador}
              onValueChange={(e) => setDocumentoDoComprador(e.value)}
              disabled={!tipoDeComprador}
            />
          )}
          <Esqueleto active={carregandoComprador} height='65px'>
            <Input label='Nome' value={comprador?.nome ?? ''} disabled />
          </Esqueleto>
          <br />
          {vendedorNaoPossuiInvestimentos && (
            <p>O vendedor não possui investimentos disponíveis.</p>
          )}
          <Esqueleto active={carregandoInvestimentos} height='65px'>
            <Select
              label='Campanha'
              value={campanhaSelecionada}
              onChange={(e) => setCampanhaSelecionada(e.target.value as number)}
              options={campanhasQueVendedorInvestiu}
              disabled={semInvestimentosDisponiveis}
            />
          </Esqueleto>
          <Esqueleto active={carregandoInvestimentos} height='65px'>
            <Select
              label='Investimento'
              value={form.investimentoId}
              onChange={(e) => setForm({ ...form, investimentoId: e.target.value as number })}
              options={[{ value: '', label: '-' }, ...investimentosNaCampanhaEscolhida]}
              disabled={semInvestimentosDisponiveis}
            />
          </Esqueleto>
          <MaskedInput
            label='Data da cessão'
            mascara='data'
            onValueChange={(e) =>
              setForm({
                ...form,
                dataDaCessao: e.formattedValue,
              })
            }
            value={form.dataDaCessao}
          />
          <MaskedInput
            mascara='dinheiro'
            label='Valor'
            value={form.valorCedido}
            onValueChange={(e) =>
              setForm({
                ...form,
                valorCedido: e.floatValue as number,
              })
            }
          />
          <p>O valor corresponde a {percentualComprado} da participação do vendedor na rodada</p>
          <div>
            {arquivo ? (
              <Button
                variant='text'
                color='error'
                endIcon={<DeleteIcon />}
                onClick={() => setArquivo(null)}
              >
                Remover documento selecionado
              </Button>
            ) : (
              <Button
                variant='text'
                startIcon={<AttachFileIcon />}
                // @ts-ignore
                onClick={() => inputDeArquivoRef?.current?.click()}
              >
                Escolher documento
                <input
                  accept='application/pdf'
                  type='file'
                  hidden
                  ref={inputDeArquivoRef}
                  multiple={false}
                  onChange={(e) => {
                    if (!e.target.files) {
                      return
                    }
                    if (e.target.files?.length <= 0) {
                      return
                    }
                    setArquivo(e.target.files[0])
                  }}
                />
              </Button>
            )}
          </div>
          <div>
            <Button variant='contained' type='submit'>
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
