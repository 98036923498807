import { FormEvent, useCallback, useEffect, useState } from 'react'
import { MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { SelectComAutocomplete } from '../../@beegin/react/components/Select'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Button } from '../../@beegin/react/components/Button'
import { initialState } from './initialState'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { bancos } from '../../enums/bancos'
import { useContaBancariaSpb } from '../../store/investidorContaBancariaSpb'
import { AtualizarContaBancariaSpbRequest } from '../../types/AtualizarContaBancariaSpbRequest'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeContaBancariaSpbDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const [form, setForm] = useState<AtualizarContaBancariaSpbRequest>(initialState)

  const { data, isError, isLoading, mutation } = useContaBancariaSpb(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const iniciarFormulario = useCallback(async () => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({
      numeroDaAgencia: data.numeroDaAgencia,
      digitoDaConta: data.digitoDaConta,
      numeroDaConta: data.numeroDaConta,
      numeroDoBanco: data.numeroDoBanco,
    })
  }, [data, investidorId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  useEffect(() => {
    if (isError) {
      setForm({
        numeroDaAgencia: '',
        digitoDaConta: '',
        numeroDaConta: '',
        numeroDoBanco: '',
      })
    }
  }, [isError])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await mutation.mutateAsync(form)
    iniciarFormulario()
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  if (isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 16px 0'>
        <SelectComAutocomplete
          required
          disabled={desabilitarInputs}
          label='Banco'
          value={form.numeroDoBanco}
          onChange={(valor) => {
            setForm((form) => ({ ...form, numeroDoBanco: valor as string }))
          }}
          options={[
            { value: '', label: '' },
            ...Object.keys(bancos).map((item) => ({
              // @ts-ignore
              label: bancos[item],
              value: item,
            })),
          ]}
        />
        <MaskedInput
          mascara={{
            allowLeadingZeros: true,
          }}
          required
          disabled={desabilitarInputs}
          label='Agência'
          value={form.numeroDaAgencia}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, numeroDaAgencia: e.value }))
          }}
        />
        <MaskedInput
          mascara={{
            allowLeadingZeros: true,
          }}
          required
          disabled={desabilitarInputs}
          label='Conta'
          value={form.numeroDaConta}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, numeroDaConta: e.value }))
          }}
        />
        <MaskedInput
          mascara={{ format: '#', allowLeadingZeros: true }}
          required
          disabled={desabilitarInputs}
          label='Dígito'
          value={form.digitoDaConta}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, digitoDaConta: e.value }))
          }}
        />
      </PilhaDeItens>
      <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
        Salvar
      </Button>
    </form>
  )
}
