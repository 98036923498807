import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { obterUsuarioAsync } from '../api/obterUsuarioAsync'
import { editarEmailDoUsuarioAsync } from '../api/editarEmailDoUsuarioAsync'
import { EditarEmailDoUsuarioRequest } from '../types/EditarEmailDoUsuarioRequest'

export const useUsuario = (usuarioId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['usuario', usuarioId]

  const options = {
    enabled: !!usuarioId,
  }

  const response = useQuery(
    queryKey,
    async () => {
      if (!usuarioId) {
        return
      }
      return await obterUsuarioAsync(usuarioId)
    },
    options,
  )

  async function editarEmailAsync(request: EditarEmailDoUsuarioRequest) {
    if (!usuarioId) {
      return
    }
    await editarEmailDoUsuarioAsync(request)
  }

  const editarEmailMutation = useMutation(editarEmailAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    editarEmailMutation: editarEmailMutation,
  }
}
