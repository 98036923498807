import { useQuery } from '@tanstack/react-query'
import { listarEscritoriosDeAssessorAsync } from '../api/listarEscritoriosDeAssessorAsync'

export const useEscritoriosDeAssessor = () => {
  const queryKey = ['escritorios-de-assessor']

  async function getAsync() {
    const response = await listarEscritoriosDeAssessorAsync()
    return response?.escritorios
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
