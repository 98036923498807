import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { useEffect, useState } from 'react'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { Flex } from '../../components/Flex'
import { useInformesDeRendimentos } from '../../store/informesDeRendimentos'
import { criarInformesDeRendimentosAsync } from '../../api/criarInformesDeRendimentosAsync'
import { toast } from 'react-toastify'
import { obterErrorResponse } from '../../utils/obterErrorResponse'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { cpfEstaValido } from '../../@beegin/react/utils/cpfEstaValido'
import { cnpjEstaValido } from '../../@beegin/react/utils/cnpjEstaValido'
import RefreshIcon from '@mui/icons-material/Refresh'
import { criarEmailsDosInformesDeRendimentosAsync } from '../../api/criarEmailsDosInformesDeRendimentosAsync'
import { columns } from './columns'

export function ListarInformesDeRendimentos() {
  const [itensSelecionados, setItensSelecionados] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [nome, setNome] = useState('')
  const [nomeParaPesquisa, setNomeParaPesquisa] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [pediuParaAtualizar, setPediuParaAtualizar] = useState(false)

  const cpfDigitadoEstaValido = cpfEstaValido(cpf)
  const cnpjDigitadoEstaValido = cnpjEstaValido(cnpj)

  const cpfValidoParaBusca = cpfDigitadoEstaValido ? cpf : ''
  const cnpjValidoParaBusca = cnpjDigitadoEstaValido ? cnpj : ''

  const { data, isLoading, isError, refetch, isRefetching, error } = useInformesDeRendimentos({
    page,
    size,
    nome: nomeParaPesquisa,
    cpf: cpfValidoParaBusca,
    cnpj: cnpjValidoParaBusca,
  })

  const pageLength = data?.informesDeRendimentos?.length ?? 0

  const algumInvestidorFoiSelecionado = itensSelecionados.length > 0

  const maisDeUmAnoSelecionado =
    [
      // @ts-ignore
      ...new Set(
        data?.informesDeRendimentos
          .filter((x) => itensSelecionados.includes(x.id))
          ?.map((x) => x.anoCalendario),
      ),
    ]?.length > 1

  const anoCalendarioSelecionado = data?.informesDeRendimentos.find(
    (x) => x.id === itensSelecionados[0],
  )?.anoCalendario

  const investidoresSelecionados =
    data?.informesDeRendimentos
      .filter((y) => itensSelecionados.includes(y.id))
      .map((y) => y.investidorId) ?? []

  const criarInformesDeRendimentosDosInvestidoresSelecionados = async () => {
    if (maisDeUmAnoSelecionado) {
      toast.error('Só é possível selecionar um ano calendário por vez.')
      return
    }

    if (!anoCalendarioSelecionado) {
      toast.error('Nenhum ano calendário foi selecionado.')
      return
    }

    const t = toast.loading('Enviando...')
    try {
      await criarInformesDeRendimentosAsync({
        anoCalendario: anoCalendarioSelecionado,
        idsDosInvestidores: investidoresSelecionados,
      })
      setItensSelecionados([])
      toast.update(t, {
        render: 'Enviado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      })
    } catch (err) {
      toast.update(t, {
        render: obterErrorResponse(err, 'Erro ao fazer pedido.'),
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      })
    }
  }

  const enviarEmailProsInvestidoresSelecionados = async () => {
    if (maisDeUmAnoSelecionado) {
      toast.error('Só é possível selecionar um ano calendário por vez.')
      return
    }

    if (!anoCalendarioSelecionado) {
      toast.error('Nenhum ano calendário foi selecionado.')
      return
    }

    const t = toast.loading('Enviando...')
    try {
      await criarEmailsDosInformesDeRendimentosAsync({
        anoCalendario: anoCalendarioSelecionado,
        idsDosInvestidores: investidoresSelecionados,
      })
      toast.update(t, {
        render: 'Enviado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      })
    } catch (err) {
      toast.update(t, {
        render: obterErrorResponse(err, 'Erro ao fazer pedido.'),
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      })
    }
  }

  const atualizarVisualizacaoAsync = async () => {
    await refetch()
    setPediuParaAtualizar(true)
    setTimeout(() => {
      setPediuParaAtualizar(false)
    }, 3000)
  }

  useEffect(() => {
    if (!isRefetching) {
      return
    }
  }, [isRefetching])

  useEffect(() => {
    setPage(0)
  }, [nomeParaPesquisa, size, cpfValidoParaBusca, cnpjValidoParaBusca])

  useEffect(() => {
    if (!isError) {
      return
    }
    toast.error(obterErrorResponse(error, 'Erro ao listar informes.'))
  }, [error, isError])

  const handleSelecionarLinha = (rowId: string) => {
    setItensSelecionados((prevSelectedRowIDs) => {
      if (prevSelectedRowIDs.includes(rowId)) {
        return prevSelectedRowIDs.filter((id) => id !== rowId)
      } else {
        return [...prevSelectedRowIDs, rowId]
      }
    })
  }

  const handleSelecionarTodasAsLinhas = () => {
    if (itensSelecionados.length === pageLength) {
      setItensSelecionados([])
    } else {
      setItensSelecionados(data?.informesDeRendimentos.map((x) => x.id) ?? [])
    }
  }

  return (
    <Menu>
      <Flex direction='row' justifyContent='space-between' alignItems='center'>
        <h1>Informes de rendimentos</h1>
      </Flex>
      <Flex gap='8px' margin='0 0 16px 0'>
        <Button
          size='small'
          variant='contained'
          startIcon={<RefreshIcon />}
          onClick={() => atualizarVisualizacaoAsync()}
          disabled={isLoading || pediuParaAtualizar}
        >
          Atualizar
        </Button>
        <Link to={'/informes-de-rendimentos/criar'}>
          <Button size='small' variant='contained'>
            Criar informes
          </Button>
        </Link>
        <Button
          size='small'
          variant='contained'
          onClick={() => criarInformesDeRendimentosDosInvestidoresSelecionados()}
          disabled={!algumInvestidorFoiSelecionado}
        >
          Criar informes para investidores selecionados
        </Button>
        <Link to={'/informes-de-rendimentos/e-mails/criar'}>
          <Button size='small' variant='contained'>
            Enviar e-mails
          </Button>
        </Link>
        <Button
          size='small'
          variant='contained'
          onClick={() => enviarEmailProsInvestidoresSelecionados()}
          disabled={!algumInvestidorFoiSelecionado}
        >
          Enviar e-mails para investidores selecionados
        </Button>
      </Flex>
      <PilhaDeItens direction='row' margin='0 0 16px 0'>
        <Input
          label='Nome'
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setNomeParaPesquisa(nome)
            }
          }}
          onBlur={() => setNomeParaPesquisa(nome)}
        />
        <MaskedInput
          disabled={cnpj.length > 0}
          error={!cpfDigitadoEstaValido && cpf.length > 0}
          helperText={!cpfDigitadoEstaValido && cpf.length > 0 && 'CPF inválido.'}
          label='CPF'
          value={cpf}
          onValueChange={(e) => setCpf(e.value)}
          mascara='cpf'
        />
        <MaskedInput
          disabled={cpf.length > 0}
          error={!cnpjDigitadoEstaValido && cnpj.length > 0}
          helperText={!cnpjDigitadoEstaValido && cnpj.length > 0 && 'CNPJ inválido.'}
          label='CNPJ'
          value={cnpj}
          onValueChange={(e) => setCnpj(e.value)}
          mascara='cnpj'
        />
      </PilhaDeItens>
      <Esqueleto width='100%' height='600px' active={isLoading}>
        <Tabela
          page={page}
          pageRowsSize={size}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setSize(pageSize)}
          rows={data?.informesDeRendimentos ?? []}
          columns={columns({
            handleSelecionarLinha,
            handleSelecionarTodasAsLinhas,
            itensSelecionados,
            pageLength,
          })}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </Esqueleto>
    </Menu>
  )
}
