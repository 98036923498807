import { useEffect, useState } from 'react'
import { Schema, ValidationError } from 'yup'

type ValidationState = {
  errors: {
    [key: string]: string
  }
}

type ValidateAsyncResponse = {
  isValid: boolean
}

type ValidationResponse = {
  errors: {
    [key: string]: string
  }
  validateAsync: () => Promise<ValidateAsyncResponse>
}

type ValidationRequest<T> = {
  validator?: Schema<T>
  model: T
  mutationError?: any
}

async function internalValidateAsync<T>(validator: Schema<T>, model: T): Promise<ValidationState> {
  try {
    await validator.validate(model, { abortEarly: false })
    return {
      errors: {},
    }
  } catch (err: any) {
    const validationErrors = err.inner as ValidationError[]

    let errors: any = {}
    validationErrors.forEach((error) => {
      if (!error.path) {
        return
      }
      errors[error.path] = error.message
    })

    return {
      errors: errors,
    }
  }
}

export const useValidation = <T,>({
  validator,
  model,
  mutationError,
}: ValidationRequest<T>): ValidationResponse => {
  const modelStateErrors = mutationError?.response?.data?.errors

  const [response, setResponse] = useState<ValidationState>({
    errors: {},
  })

  const validateAsync = async (): Promise<ValidateAsyncResponse> => {
    if (!validator) {
      return {
        isValid: true,
      }
    }
    const validationResult = await internalValidateAsync(validator, model)
    setResponse(validationResult)
    return {
      isValid: Object.keys(validationResult.errors).length === 0,
    }
  }

  useEffect(() => {
    if (!modelStateErrors) {
      setResponse({ ...response, errors: {} })
      return
    }
    let responseErrors: any = {}
    Object.keys(modelStateErrors).map((item) => {
      try {
        responseErrors[transformarPrimeiraLetraEmMinuscula(item)] = modelStateErrors[item][0] as any
      } catch {
        console.error('Erro ao obter resposta de erro da API.')
      }
    })
    setResponse({ ...response, errors: responseErrors })
  }, [modelStateErrors])

  return {
    errors: response.errors,
    validateAsync: validateAsync,
  }
}

function transformarPrimeiraLetraEmMinuscula(palavra: string): string {
  return palavra.charAt(0).toLowerCase() + palavra.slice(1)
}
