import { useParams } from 'react-router-dom'
import { Menu } from '../../components/menu'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useVoltar } from '../../hooks/useVoltar'
import { Flex } from '../../components/Flex'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { FormEvent, useState } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import { useUsuario } from '../../store/usuario'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'

type Form = {
  email: string
}

export function EditarUsuarioEmail() {
  const [form, setForm] = useState<Form>({ email: '' })

  let { usuarioId } = useParams()

  const { data, isLoading, editarEmailMutation } = useUsuario(usuarioId)

  useMutationToast({
    mutation: editarEmailMutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const voltar = useVoltar(`/usuarios/${usuarioId}`)

  const carregando = isLoading
  const desabilitarInputs = editarEmailMutation.isLoading
  const desabilitarBotao = editarEmailMutation.isLoading

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!usuarioId) {
      toast.error('Não há a ID do usuário antigo.')
      return
    }
    if (!data?.email) {
      toast.error('Não há o e-mail antigo.')
      return
    }
    if (!form.email) {
      toast.error('Não há o e-mail no formulário.')
      return
    }
    await editarEmailMutation.mutateAsync({
      usuarioId,
      emailAnterior: data?.email,
      emailNovo: form.email,
    })
    setForm({ email: '' })
  }

  const header = () => {
    return (
      <Flex gap='4px' margin='0 0 4px 0'>
        <Link to={voltar}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </Flex>
    )
  }

  if (carregando) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
          <Esqueleto height='56px' />
          <Esqueleto height='56px' />
          <Esqueleto height='36px' width='120px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      <>
        {header()}
        <h1>Editar e-mail do usuário</h1>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0' maxWidth='600px'>
            <Input value={data?.id ?? ''} label='ID' disabled />
            <Input value={data?.email ?? ''} label='Nome completo' disabled />
            <Input
              label='Novo e-mail'
              value={form.email ?? ''}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              required
              disabled={desabilitarInputs}
            />
          </PilhaDeItens>
          <Button
            type='submit'
            carregando={editarEmailMutation.isLoading}
            disabled={desabilitarBotao}
          >
            Salvar
          </Button>
        </form>
      </>
    </Menu>
  )
}
