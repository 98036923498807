import styled from 'styled-components'

interface IBarra {
  grid: number
}

export const Barra = styled.div<IBarra>`
  width: 100%;
  min-height: 67px;
  border-top: 1px solid #eff0f1;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ grid }) => (grid ? `repeat(${grid}, 1fr)` : 'repeat(7, 1fr)')};
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-top: 18.5px;
  }
`

export const Item = styled.div``

export const Titulo = styled.p`
  color: #5b616a;
  margin: 0;
  margin-top: 4px;
  position: relative;
  // font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`

export const Valor = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`

export const Asteristico = styled.span`
  position: absolute;
  left: -8px;
  font-size: 16px;
  color: #074bc9;
`
