import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { obterEnderecoPeloCepAsync } from '../../services/obterEnderecoPeloCepAsync'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Button } from '../../@beegin/react/components/Button'
import { initialState } from './initialState'
import { SelectComAutocomplete } from '../../@beegin/react/components/Select'
import { listagemDeEstados } from '../../utils/listagemDeEstados'
import { Endereco } from '../../types/InvestidorDTO'
import { useInvestidor } from '../../store/investidor'
import { Checkbox } from '../../@beegin/react/components/Checkbox'
import { useValidation } from '../../hooks/useValidation'
import { toast } from 'react-toastify'
import { getErrorMessageProps } from '../../utils/getErrorMessageProps'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeEnderecoDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const primeiraRenderizacao = useRef(true)

  const [form, setForm] = useState<Endereco>(initialState)
  const [buscandoCep, setBuscandoCep] = useState(false)

  const { data, isError, isLoading, mutation } = useInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const { errors, validateAsync } = useValidation<Endereco>({
    model: form,
    mutationError: mutation.error,
  })

  const iniciarFormulario = useCallback(async () => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ ...data.endereco })
  }, [data, investidorId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  useEffect(() => {
    if (!data) {
      return
    }
    if (!data.endereco) {
      return
    }
    if (!form.cep) {
      return
    }
    if (form.cep.length !== 8) {
      return
    }
    if (primeiraRenderizacao.current && form.cep === data.endereco.cep) {
      primeiraRenderizacao.current = false
      return
    }

    const setarEnderecoBaseadoNoCep = async () => {
      if (!form.cep) {
        return
      }
      setBuscandoCep(true)
      try {
        const endereco = await obterEnderecoPeloCepAsync(form.cep)
        setForm((form) => ({
          ...form,
          logradouro: endereco.logradouro,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          uf: endereco.uf,
        }))
      } finally {
        setBuscandoCep(false)
      }
    }

    const timer = setTimeout(async () => {
      await setarEnderecoBaseadoNoCep()
    }, 1000)
    return () => clearTimeout(timer)
  }, [form.cep])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { isValid } = await validateAsync()
    if (!isValid) {
      toast.error('Há um erro no formulário.')
      return
    }
    await mutation.mutateAsync({ endereco: form })
    iniciarFormulario()
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  useEffect(() => {
    if (form.semNumero === true) {
      setForm({ ...form, enderecoNumero: '' })
    }
  }, [form.semNumero])

  if (isError) {
    return <p>Erro.</p>
  }

  if (!data || isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 32px 0'>
        <MaskedInput
          {...getErrorMessageProps(errors, 'cep')}
          required
          carregando={buscandoCep}
          mascara='cep'
          label='CEP'
          value={form.cep ?? ''}
          disabled={desabilitarInputs}
          onValueChange={(e) => setForm({ ...form, cep: e.value })}
        />
        <SelectComAutocomplete
          {...getErrorMessageProps(errors, 'uf')}
          required
          label='UF'
          options={listagemDeEstados}
          value={form.uf ?? ''}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, uf: e })}
        />
        <Input
          {...getErrorMessageProps(errors, 'cidade')}
          required
          label='Cidade'
          value={form.cidade ?? ''}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, cidade: e.target.value })}
        />
        <Input
          {...getErrorMessageProps(errors, 'bairro')}
          required
          label='Bairro'
          value={form.bairro ?? ''}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, bairro: e.target.value })}
        />
        <Input
          {...getErrorMessageProps(errors, 'logradouro')}
          required
          label='Logradouro'
          value={form.logradouro ?? ''}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, logradouro: e.target.value })}
        />
        <Input
          {...getErrorMessageProps(errors, 'enderecoNumero')}
          required={form.semNumero === false}
          label='Número'
          value={form.enderecoNumero ?? ''}
          disabled={desabilitarInputs || !!form.semNumero}
          onChange={(e) => setForm({ ...form, enderecoNumero: e.target.value })}
        />
        <Checkbox
          checked={form.semNumero === true}
          onChange={() =>
            setForm({
              ...form,
              semNumero: !form.semNumero,
            })
          }
          label='Sem número'
        />
        <Input
          {...getErrorMessageProps(errors, 'complemento')}
          label='Complemento'
          value={form.complemento ?? ''}
          disabled={desabilitarInputs}
          onChange={(e) => setForm({ ...form, complemento: e.target.value })}
        />
      </PilhaDeItens>
      <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
        Salvar
      </Button>
    </form>
  )
}
