import { Flex } from '../../components/Flex'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatarData } from '../../utils/formatarData'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { toast } from 'react-toastify'

type Props = {
  callbackParaDeletar: (id: number) => Promise<void>
  callbackParaSelecionar: (id: number) => void
  itemSelecionado: number | null
}

export const columns = ({
  callbackParaDeletar,
  callbackParaSelecionar,
  itemSelecionado,
}: Props): any => [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: (props: any) => {
      if (itemSelecionado === props.getValue()) {
        return (
          <Flex alignItems='center' gap='4px' onClick={() => callbackParaDeletar(props.getValue())}>
            <DeleteIcon color='error' cursor='pointer' fontSize='small' />
            <p>{props.getValue()}</p>
          </Flex>
        )
      }
      return (
        <Flex
          alignItems='center'
          gap='4px'
          onClick={() => callbackParaSelecionar(props.getValue())}
        >
          <DeleteIcon color='disabled' cursor='pointer' fontSize='small' />
          <p>{props.getValue()}</p>
        </Flex>
      )
    },
  },
  {
    header: 'Nome',
    accessorKey: 'nome',
  },
  {
    header: 'Data',
    accessorKey: 'data',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Url',
    accessorKey: 'url',
    cell: (props: any) => {
      return (
        <Flex alignItems='center' gap='4px'>
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => {
              copiarParaAreaDeTransferencia(props.getValue())
              toast.success('Copiado para área de transferência')
            }}
          />
          <p>{props.getValue()}</p>
        </Flex>
      )
    },
  },
]
