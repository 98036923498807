import { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { StyledMaterialButton } from './styles'

type Props = ButtonProps & {
  carregando?: boolean
  margin?: string
}

export function Button({
  variant = 'contained',
  carregando,
  disabled,
  margin,
  ...props
}: Props) {
  return (
    <StyledMaterialButton
    sx={{margin: margin}}
      startIcon={
        carregando ? (
          <CircularProgress size="18px" color="secondary" />
        ) : undefined
      }
      disabled={carregando ? true : disabled}
      variant={variant}
      {...props}
    />
  )
}
