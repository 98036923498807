import { FormEvent, useEffect, useRef, useState } from 'react'
import { Button } from '../../@beegin/react/components/Button'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import {
  formatarData,
  transformarDataFormatadaEmTimestamp,
} from '../../@beegin/react/utils/formatarData'
import { cnpjEstaValido } from '../../@beegin/react/utils/cnpjEstaValido'
import { useMutationToast } from '../../hooks/useMutationToast'
import { initialState } from './initialState'
import { useInvestidorPessoaJuridica } from '../../store/investidorPessoaJuridica'
import { ConjuntoDeRadios } from '../../@beegin/react/components/ConjuntoDeRadios'
import { Flex } from '../Flex'
import DeleteIcon from '@mui/icons-material/Delete'
import { Select } from '../../@beegin/react/components/Select'
import { tipoDeTelefone } from '../../enums/tipoDeTelefone'
import { TipoDeTelefone } from '../../types/TipoDeTelefone'
import { EditarInvestidorEmpresaRequest } from '../../types/EditarInvestidorEmpresaRequest'
import { useValidation } from '../../hooks/useValidation'
import { getErrorMessageProps } from '../../utils/getErrorMessageProps'
import { obterEnderecoPeloCepAsync } from '../../services/obterEnderecoPeloCepAsync'

interface Props {
  investidorId: string
}

export function FormularioDeInvestidorPessoaJuridica({ investidorId }: Props) {
  const [buscandoCep, setBuscandoCep] = useState(false)

  const [form, setForm] = useState(initialState)

  const { data, isError, isLoading, mutation } = useInvestidorPessoaJuridica(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const { errors } = useValidation<EditarInvestidorEmpresaRequest>({
    model: form,
    mutationError: mutation.error,
  })

  useEffect(() => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({
      ...data,
      controladores: data.controladores ?? [],
      administradores: data.administradores ?? [],
      dataDaConstituicao: formatarData(new Date(data.dataDaConstituicao)),
      dataReferenciaDaSituacaoPatrimonial: formatarData(
        new Date(data.dataReferenciaDaSituacaoPatrimonial),
      ),
    })
  }, [investidorId, data])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await mutation.mutateAsync({
      ...form,
      controladores: form.controladores ?? [],
      administradores: form.administradores ?? [],
      dataDaConstituicao: transformarDataFormatadaEmTimestamp(form.dataDaConstituicao ?? ''),
      dataReferenciaDaSituacaoPatrimonial: transformarDataFormatadaEmTimestamp(
        form.dataReferenciaDaSituacaoPatrimonial ?? '',
      ),
    })
  }

  const primeiraRenderizacao = useRef(true)

  useEffect(() => {
    if (!data) {
      return
    }
    if (!data.endereco) {
      return
    }
    if (!form.endereco) {
      return
    }
    if (!form.endereco.cep) {
      return
    }
    if (form.endereco.cep.length !== 8) {
      return
    }
    if (primeiraRenderizacao.current && form.endereco.cep === data.endereco.cep) {
      primeiraRenderizacao.current = false
      return
    }

    const setarEnderecoBaseadoNoCep = async () => {
      if (!form.endereco?.cep) {
        return
      }
      setBuscandoCep(true)
      try {
        const enderecoResponse = await obterEnderecoPeloCepAsync(form.endereco.cep)
        setForm((form) => ({
          ...form,
          endereco: {
            ...form.endereco,
            logradouro: enderecoResponse.logradouro,
            bairro: enderecoResponse.bairro,
            cidade: enderecoResponse.cidade,
            uf: enderecoResponse.uf,
          },
        }))
      } finally {
        setBuscandoCep(false)
      }
    }

    const timer = setTimeout(async () => {
      await setarEnderecoBaseadoNoCep()
    }, 1000)
    return () => clearTimeout(timer)
  }, [form?.endereco?.cep])

  const adicionarControlador = () => {
    setForm((x) => ({
      ...x,
      controladores: [
        ...(form.controladores ?? []),
        {
          id: '',
          nome: '',
          documento: '',
          tipo: '',
          pessoaPoliticamenteExposta: false,
        },
      ],
    }))
  }

  const removerControlador = (item: any) => {
    setForm((x) => ({
      ...x,
      controladores: [...form.controladores.filter((x) => x !== item)],
    }))
  }

  const adicionarAdministrador = () => {
    setForm((x) => ({
      ...x,
      administradores: [
        ...(form.administradores ?? []),
        {
          id: '',
          nome: '',
          cpf: '',
          pessoaPoliticamenteExposta: false,
        },
      ],
    }))
  }

  const removerAdministrador = (item: any) => {
    setForm((x) => ({
      ...x,
      administradores: [...form.administradores.filter((x) => x !== item)],
    }))
  }

  const desabilitarInputs = mutation.isLoading

  if (isError) {
    return <p>Erro.</p>
  }

  if (!data || isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 32px 0'>
        <Input
          label='Nome fantasia'
          {...getErrorMessageProps(errors, 'nomeFantasia')}
          value={form.nomeFantasia ?? ''}
          onChange={(e) =>
            setForm({
              ...form,
              nomeFantasia: e.target.value,
            })
          }
          disabled={desabilitarInputs}
        />
        <Input
          label='Razão social'
          {...getErrorMessageProps(errors, 'razaoSocial')}
          value={form.razaoSocial ?? ''}
          onChange={(e) =>
            setForm({
              ...form,
              razaoSocial: e.target.value,
            })
          }
          required
          disabled={desabilitarInputs}
        />
        <MaskedInput
          error={form.cnpj ? !cnpjEstaValido(form.cnpj) : false}
          helperText={form.cnpj && !cnpjEstaValido(form.cnpj) && 'CNPJ inválido'}
          mascara='cnpj'
          label='CNPJ'
          value={form.cnpj ?? ''}
          onValueChange={(e) =>
            setForm({
              ...form,
              cnpj: e.value,
            })
          }
          required
          disabled={desabilitarInputs}
        />
        <Input
          label='CNAE'
          {...getErrorMessageProps(errors, 'cnae')}
          value={form.cnae ?? ''}
          onChange={(e) =>
            setForm({
              ...form,
              cnae: e.target.value,
            })
          }
          required
          disabled={desabilitarInputs}
        />
        <MaskedInput
          mascara='data'
          label='Data da constituição'
          {...getErrorMessageProps(errors, 'dataDaConstituicao')}
          value={form.dataDaConstituicao ?? ''}
          onValueChange={(e) =>
            setForm({
              ...form,
              dataDaConstituicao: e.formattedValue,
            })
          }
          required
          disabled={desabilitarInputs}
        />
        <MaskedInput
          required
          mascara='dinheiro'
          label='Faturamento médio mensal da empresa'
          {...getErrorMessageProps(errors, 'faturamentoMedioMensal')}
          value={form.faturamentoMedioMensal ?? ''}
          onValueChange={(e) =>
            setForm({
              ...form,
              faturamentoMedioMensal: e.floatValue ?? 0,
            })
          }
          disabled={desabilitarInputs}
        />
        <MaskedInput
          required
          mascara='dinheiro'
          label='Valor patrimonial da empresa'
          {...getErrorMessageProps(errors, 'valorPatrimonial')}
          value={form.valorPatrimonial ?? ''}
          onValueChange={(e) =>
            setForm({
              ...form,
              valorPatrimonial: e.floatValue ?? 0,
            })
          }
          disabled={desabilitarInputs}
        />
        <MaskedInput
          mascara='data'
          label='Data referência da situação patrimonial da empresa'
          {...getErrorMessageProps(errors, 'dataReferenciaDaSituacaoPatrimonial')}
          value={form.dataReferenciaDaSituacaoPatrimonial ?? ''}
          onValueChange={(e) =>
            setForm({
              ...form,
              dataReferenciaDaSituacaoPatrimonial: e.formattedValue,
            })
          }
          disabled={desabilitarInputs}
        />
        <Select
          required
          label='Tipo de telefone'
          value={(form.telefone?.tipoTelefone as unknown as string) ?? ''}
          options={Object.values(tipoDeTelefone).map((item) => ({
            label: item.title,
            value: item.asString,
          }))}
          disabled={desabilitarInputs}
          onChange={(e) =>
            setForm({
              ...form,
              telefone: { ...form.telefone, tipoTelefone: e.target.value as TipoDeTelefone },
            })
          }
        />
        <MaskedInput
          required
          mascara={{ format: '####' }}
          label='DDI'
          value={form.telefone?.codigoPais ?? ''}
          disabled={desabilitarInputs}
          onValueChange={(e) =>
            setForm({ ...form, telefone: { ...form.telefone, codigoPais: parseFloat(e.value) } })
          }
        />
        <MaskedInput
          required
          mascara='ddd'
          label='DDD'
          value={form.telefone?.ddd ?? ''}
          disabled={desabilitarInputs}
          onValueChange={(e) =>
            setForm({ ...form, telefone: { ...form.telefone, ddd: parseFloat(e.value) } })
          }
        />
        <MaskedInput
          required
          mascara={{ format: '# ####-####' }}
          label='Telefone'
          value={form.telefone?.celular ?? ''}
          disabled={desabilitarInputs}
          onValueChange={(e) =>
            setForm({ ...form, telefone: { ...form.telefone, celular: parseFloat(e.value) } })
          }
        />
        <Input
          required
          label='CEP'
          {...getErrorMessageProps(errors, 'endereco.cep')}
          value={form.endereco?.cep ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, cep: e.target.value } })
          }
        />
        <Input
          required
          label='UF'
          {...getErrorMessageProps(errors, 'endereco.uf')}
          value={form.endereco?.uf ?? ''}
          onChange={(e) => setForm({ ...form, endereco: { ...form.endereco, uf: e.target.value } })}
        />
        <Input
          required
          label='Cidade'
          {...getErrorMessageProps(errors, 'endereco.cidade')}
          value={form.endereco?.cidade ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, cidade: e.target.value } })
          }
        />
        <Input
          required
          label='Bairro'
          {...getErrorMessageProps(errors, 'endereco.bairro')}
          value={form.endereco?.bairro ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, bairro: e.target.value } })
          }
        />
        <Input
          required
          label='Logradouro'
          {...getErrorMessageProps(errors, 'endereco.logradouro')}
          value={form.endereco?.logradouro ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, logradouro: e.target.value } })
          }
        />
        <Input
          required
          label='Numero'
          {...getErrorMessageProps(errors, 'endereco.enderecoNumero')}
          value={form.endereco?.enderecoNumero ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, enderecoNumero: e.target.value } })
          }
        />
        <Input
          label='Complemento'
          {...getErrorMessageProps(errors, 'endereco.complemento')}
          value={form.endereco?.complemento ?? ''}
          onChange={(e) =>
            setForm({ ...form, endereco: { ...form.endereco, complemento: e.target.value } })
          }
        />
        <br />
        <h2>Controladores</h2>
        {form.controladores && form.controladores?.length === 0 ? (
          <p>Não possui</p>
        ) : (
          form.controladores?.map((controlador, index) => {
            return (
              <PilhaDeItens
                gap={3}
                key={`${controlador.id} ${index}`}
                margin={index === 0 ? '0 0 0 0' : '32px 0 0 0'}
              >
                <ConjuntoDeRadios
                  direction='row'
                  label='Tipo de controlador'
                  value={controlador.tipo ?? null}
                  options={[
                    { label: 'Pessoa física', value: 'PF' },
                    { label: 'Pessoa jurídica', value: 'PJ' },
                  ]}
                  onChange={(e) => {
                    let todosOsControladores = [...form.controladores]
                    todosOsControladores[index].tipo = e.target.value
                    setForm({ ...form, controladores: todosOsControladores })
                  }}
                />
                <Input
                  label='Nome'
                  value={controlador.nome ?? ''}
                  onChange={(e) => {
                    let todosOsControladores = [...form.controladores]
                    todosOsControladores[index].nome = e.target.value
                    setForm({ ...form, controladores: todosOsControladores })
                  }}
                />
                {controlador.tipo?.toLowerCase() === 'pf' && (
                  <MaskedInput
                    mascara='cpf'
                    label='CPF'
                    value={controlador.documento ?? ''}
                    onValueChange={(e) => {
                      let todosOsControladores = [...form.controladores]
                      todosOsControladores[index].documento = e.value
                      setForm({ ...form, controladores: todosOsControladores })
                    }}
                  />
                )}
                {controlador.tipo?.toLowerCase() === 'pj' && (
                  <MaskedInput
                    mascara='cnpj'
                    label='CNPJ'
                    value={controlador.documento ?? ''}
                    onValueChange={(e) => {
                      let todosOsControladores = [...form.controladores]
                      todosOsControladores[index].documento = e.value
                      setForm({ ...form, controladores: todosOsControladores })
                    }}
                  />
                )}
                <Flex
                  justifyContent='space-between'
                  justifyContentOnMobile='space-between'
                  alignItems='center'
                  alignItemsOnMobile='center'
                >
                  <ConjuntoDeRadios
                    direction='row'
                    label='Controlador é PEP?'
                    value={controlador.pessoaPoliticamenteExposta}
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={() => {
                      let todosOsControladores = [...form.controladores]
                      todosOsControladores[index].pessoaPoliticamenteExposta =
                        !controlador.pessoaPoliticamenteExposta
                      setForm({ ...form, controladores: todosOsControladores })
                    }}
                  />
                  <DeleteIcon
                    cursor='pointer'
                    onClick={() => removerControlador(controlador)}
                    color='secondary'
                  />
                </Flex>
              </PilhaDeItens>
            )
          })
        )}
        <Flex justifyContent='space-between' justifyContentOnMobile='space-between'>
          <Button type='button' variant='text' onClick={() => adicionarControlador()}>
            + Adicionar controlador
          </Button>
        </Flex>
        <br />
        <h2>Administradores</h2>
        {form.administradores && form.administradores?.length === 0 ? (
          <p>Não possui</p>
        ) : (
          form.administradores?.map((administrador, index) => {
            return (
              <PilhaDeItens
                gap={3}
                key={`${administrador.id} ${index}`}
                margin={index === 0 ? '0 0 0 0' : '32px 0 0 0'}
              >
                <Input
                  label='Nome'
                  value={administrador.nome ?? ''}
                  onChange={(e) => {
                    let todos = [...form.administradores]
                    todos[index].nome = e.target.value
                    setForm({ ...form, administradores: [...todos] })
                  }}
                />
                <MaskedInput
                  mascara='cpf'
                  label='CPF'
                  value={administrador.cpf ?? ''}
                  onValueChange={(e) => {
                    let todos = [...form.administradores]
                    todos[index].cpf = e.value
                    setForm({ ...form, administradores: [...todos] })
                  }}
                />
                <Flex
                  justifyContent='space-between'
                  justifyContentOnMobile='space-between'
                  alignItems='center'
                  alignItemsOnMobile='center'
                >
                  <ConjuntoDeRadios
                    direction='row'
                    label='Administrador é PEP?'
                    value={administrador.pessoaPoliticamenteExposta}
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={() => {
                      let todos = [...form.administradores]
                      todos[index].pessoaPoliticamenteExposta =
                        !administrador.pessoaPoliticamenteExposta
                      setForm({ ...form, administradores: [...todos] })
                    }}
                  />
                  <DeleteIcon
                    cursor='pointer'
                    onClick={() => removerAdministrador(administrador)}
                    color='secondary'
                  />
                </Flex>
              </PilhaDeItens>
            )
          })
        )}
        <Flex justifyContent='space-between' justifyContentOnMobile='space-between'>
          <Button type='button' variant='text' onClick={() => adicionarAdministrador()}>
            + Adicionar administrador
          </Button>
        </Flex>
      </PilhaDeItens>
      <Button type='submit' carregando={mutation.isLoading}>
        Salvar
      </Button>
    </form>
  )
}
