import { usuariosApi } from '../services/api'

type Permissao = {
  nome: string
}

export async function listarPermissoesAsync(): Promise<Permissao[]> {
  const response = await usuariosApi.get<Permissao[]>('/permissoes')
  return response.data
}
