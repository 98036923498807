import { useQuery } from '@tanstack/react-query'
import { listarInvestimentosAsync } from '../api/listarInvestimentosAsync'

interface Investimentos {
  investimentos: {
    id: number
    valor: number
    campanha: {
      id: number
      nome: string
    }
  }[]
}

export const useInvestimentosPorInvestidor = (investidorId: string, status: string) => {
  const queryKey = ['investimentos-por-investidor', investidorId]

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    const investimentos = await listarInvestimentosAsync({
      page: 0,
      size: 1000,
      investidorId: investidorId,
      status: status,
    })
    return {
      investimentos: investimentos.investimentos?.map((item) => ({
        id: item.id,
        valor: item.valor,
        campanha: {
          id: item.campanhaId,
          nome: item.nomeDaCampanha,
        },
      })),
    } as Investimentos
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
