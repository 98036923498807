import * as React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiCheckbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

interface CheckboxProps {
  label: React.ReactNode
  checked: boolean
  onChange?: () => void
  disabled?: boolean
  align?: 'start' | 'center'
  error?: string
}

export function Checkbox({
  error = '',
  label,
  checked,
  onChange,
  disabled,
  align = 'center',
}: CheckboxProps) {
  return (
    <FormControl error={!!error}>
      <FormGroup>
        <FormControlLabel
          sx={{ alignItems: align === 'center' ? 'center' : 'flex-start' }}
          control={<MuiCheckbox />}
          label={label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </FormGroup>
      {error && <FormHelperText sx={{ marginLeft: 0 }}>{error}</FormHelperText>}
    </FormControl>
  )
}
