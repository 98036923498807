import { usuariosApi } from '../services/api'

type Request = {
  usuarioId: string
  urlDeRetorno?: string
}

type Response = {
  url: string
}

export async function obterLoginDoUsuarioAsync({
  usuarioId,
  urlDeRetorno,
}: Request): Promise<Response> {
  const query = new URLSearchParams()
  if (urlDeRetorno) {
    query.append('return_url', urlDeRetorno)
  }
  const response = await usuariosApi.get<string>(`/usuarios/${usuarioId}/login?${query}`)
  return {
    url: response.data,
  }
}
