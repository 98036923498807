export type MascarasDisponiveis =
  | 'dinheiro'
  | 'data'
  | 'telefone'
  | 'cpf'
  | 'cnpj'
  | 'cep'
  | 'ddd'
  | 'inteiro'
  | 'ano'
  | 'percentual'
  | object

export const obterPropsMascaraDoInput = (mascara: MascarasDisponiveis): any => {
  switch (mascara) {
    case 'dinheiro':
      return {
        thousandSeparator: '.',
        decimalSeparator: ',',
        isNumericString: true,
        prefix: 'R$ ',
        decimalScale: 2,
      }
    case 'data':
      return {
        format: '##/##/####',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'telefone':
      return {
        format: '(##) #####-####',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'cpf':
      return {
        format: '###.###.###-##',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'cep':
      return {
        format: '#####-###',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'cnpj':
      return {
        format: '##.###.###/####-##',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'ddd':
      return {
        format: '##',
        mask: '_',
        allowLeadingZeros: true,
      }
    case 'inteiro': {
      return {
        allowLeadingZeros: false,
      }
    }
    case 'ano': {
      return {
        format: '####',
        mask: '_',
        allowLeadingZeros: true,
      }
    }
    case 'percentual': {
      return {
        allowLeadingZeros: true,
        suffix: '%',
        decimalScale: 2,
        decimalSeparator: ',',
      }
    }
    default:
      return { ...mascara }
  }
}
