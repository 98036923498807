import { useQuery } from '@tanstack/react-query'
import { listarInvestimentosAsync } from '../api/listarInvestimentosAsync'

type Request = {
  page: number
  size: number
  investidorId?: string
  campanhaId?: string
  id?: string
  status?: string
}

export const useInvestimentos = (request: Request) => {
  const queryKey = ['investimentos', { ...request }]

  const response = useQuery(queryKey, async () => await listarInvestimentosAsync(request))

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    refetch: response.refetch,
  }
}
