import { api } from '../services/api'

type ObterSituacaoNaAdviceDoInvestidorResponse = {
  investidorId: string
  riscoNaAdvice: string
  riscoNaAdviceComoInt: number
}

export async function obterSituacaoNaAdviceDoInvestidorAsync(
  investidorId: string,
): Promise<ObterSituacaoNaAdviceDoInvestidorResponse> {
  const response = await api.get<ObterSituacaoNaAdviceDoInvestidorResponse>(
    `/api/v3/investidores/${investidorId}/situacao-na-advice`,
  )
  return response?.data
}
