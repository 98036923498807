import { investimentosApi } from '../services/api'

type CriarInvestimentoRequest = {
  investidorId: string
  campanhaId: string
  valor: number
}

export async function criarInvestimentoAsync(request: CriarInvestimentoRequest): Promise<void> {
  await investimentosApi.post('/investimentos', request)
}
