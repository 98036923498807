import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Menu } from '../../components/menu'
import { FormEvent, useState } from 'react'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { FormContainer } from '../../components/FormContainer'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useProtocoloDeInvestidorPessoaJuridicaNaAdvice } from '../../store/protocoloDeInvestidorPessoaJuridicaNaAdvice'
import { cnpjEstaValido } from '../../@beegin/react/utils/cnpjEstaValido'
import { guidEstaValido } from '../../@beegin/react/utils/guiaEstaValido'

type Form = {
  cnpj: string
  protocoloNaAdvice: string
}

export function EditarProtocoloDeInvestidorPessoaJuridicaNaAdvice() {
  const { mutation } = useProtocoloDeInvestidorPessoaJuridicaNaAdvice()

  useMutationToast({
    mutation: mutation,
    sucesso: 'Registrado com sucesso!',
    erro: 'Erro ao editar registro.',
    carregando: 'Carregando...',
  })

  const [form, setForm] = useState<Form>({
    cnpj: '',
    protocoloNaAdvice: '',
  })

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    await mutation.mutateAsync(form)
  }

  const cnpjValido = cnpjEstaValido(form.cnpj)
  const protocoloValido = guidEstaValido(form.protocoloNaAdvice)

  const desabilitarForm = mutation.isLoading
  const desabilitarBotao = !cnpjValido || !protocoloValido || desabilitarForm

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to='/'>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Protocolo de investidor na Advice</h1>
        <p>É somente para investidores PJ. Faz parte do processo atual.</p>
        <form onSubmit={onSubmit}>
          <PilhaDeItens gap={3} margin='0 0 32px 0'>
            <MaskedInput
              required
              disabled={desabilitarForm}
              label='CNPJ'
              mascara='cnpj'
              value={form.cnpj}
              onValueChange={(e) => setForm({ ...form, cnpj: e.value })}
              error={!!form.cnpj && !cnpjValido}
              helperText={!!form.cnpj && !cnpjValido && 'CNPJ inválido.'}
            />
            <Input
              required
              disabled={desabilitarForm}
              label='Protocolo'
              value={form.protocoloNaAdvice}
              onChange={(e) => setForm({ ...form, protocoloNaAdvice: e.target.value })}
              error={!!form.protocoloNaAdvice && !protocoloValido}
              helperText={!!form.protocoloNaAdvice && !protocoloValido && 'Protocolo inválido.'}
            />
          </PilhaDeItens>
          <Button type='submit' carregando={desabilitarForm} disabled={desabilitarBotao}>
            Salvar
          </Button>
        </form>
      </FormContainer>
    </Menu>
  )
}
