import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew'
import { Menu } from '../../components/menu'
import { BarraDeCaptacao } from '../../components/BarraDeCaptacao'
import { BarraDeInformacoesDaCampanha } from '../../components/BarraDeInformacoesDaCampanha'
import { GraficoBarraVertical } from '../../components/GraficoBarraVertical'
import {
  Container,
  Cnpj,
  CnpjStatus,
  Header,
  Button,
  Info,
  Nome,
  Status,
  StatusColor,
  Main,
  Section,
  BlocoInfo,
  TituloBloco,
  ValorBloco,
  LinhaDeBlocos,
  ItensAbaixoDoTitulo,
  ItensDaEsquerda,
  ItemAbaixoDoTitulo,
  TituloDoItemAbaixoDoTitulo,
  ValorDoItemAbaixoDoTitulo,
  ItensDaDireita,
  TituloGrafico,
  SubtituloGrafico,
  DataGrafico,
  HeaderGrafico,
  ElementosDaEsquerda,
} from './styles'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import { useCampanha } from '../../store/campanha'

export function ObterCampanha() {
  const { slug } = useParams()

  const { data: campanha } = useCampanha({ slug })

  const statusDaCampanha = campanha?.status ?? ''

  const campanhaEstaConcluida = statusDaCampanha?.toLowerCase() === 'concluída'
  const campanhaDeTipoAcoes = campanha?.tipo?.toLowerCase() === 'ação'

  // const investimentosNaCampanha = useInvestimentosNaCampanha(slug)
  const investimentosNaCampanha = {
    data: {
      atingiuValorMinimo: false,
      campanhaId: 683,
      quantidadeDeInvestidores: 1,
      totalInvestido: 700000,
      totalPrevisto: 11000,
      valorInvestido: 30000,
    },
    isError: false,
    isLoading: false,
  }

  return (
    <Menu>
      <Container>
        <Header>
          <ElementosDaEsquerda>
            <Link to='/campanhas'>
              <ArrowBackIcon sx={{ color: '#2571ff' }} />
            </Link>
            <Info>
              <Nome>{campanha?.nome}</Nome>
              <CnpjStatus>
                <Cnpj>17.745.307/0001-14</Cnpj>
                <Status>
                  <StatusColor />
                  {campanha?.status}
                </Status>
              </CnpjStatus>
            </Info>
          </ElementosDaEsquerda>
          <Link to={`/campanhas/${slug}/editar`}>
            <Button>Editar campanha</Button>
          </Link>
        </Header>
        <Main>
          <Section>
            <ItensAbaixoDoTitulo>
              <ItensDaEsquerda>
                {campanha?.setor && (
                  <ItemAbaixoDoTitulo>
                    <TituloDoItemAbaixoDoTitulo>Setor</TituloDoItemAbaixoDoTitulo>
                    <ValorDoItemAbaixoDoTitulo>{campanha?.setor}</ValorDoItemAbaixoDoTitulo>
                  </ItemAbaixoDoTitulo>
                )}
                {campanhaDeTipoAcoes && campanha.especieDeAcoes ? (
                  <ItemAbaixoDoTitulo>
                    <TituloDoItemAbaixoDoTitulo>Tipo de ativo</TituloDoItemAbaixoDoTitulo>
                    <ValorDoItemAbaixoDoTitulo>
                      <span style={{ display: 'inline-block', marginRight: '8px' }}>
                        {campanha.especieDeAcoes ?? ''}
                      </span>
                    </ValorDoItemAbaixoDoTitulo>
                  </ItemAbaixoDoTitulo>
                ) : (
                  <ItemAbaixoDoTitulo>
                    <TituloDoItemAbaixoDoTitulo>Tipo de ativo</TituloDoItemAbaixoDoTitulo>
                    <ValorDoItemAbaixoDoTitulo>{campanha?.tipo}</ValorDoItemAbaixoDoTitulo>
                  </ItemAbaixoDoTitulo>
                )}
              </ItensDaEsquerda>
              <ItensDaDireita>
                {!!investimentosNaCampanha.data?.totalInvestido && (
                  <ItemAbaixoDoTitulo>
                    <TituloDoItemAbaixoDoTitulo>Total captado</TituloDoItemAbaixoDoTitulo>
                    <ValorDoItemAbaixoDoTitulo
                      currency
                      cents={formatarDinheiro(investimentosNaCampanha.data?.totalInvestido).slice(
                        -3,
                      )}
                    >
                      {formatarDinheiro(investimentosNaCampanha.data?.totalInvestido).slice(2, -3)}
                    </ValorDoItemAbaixoDoTitulo>
                  </ItemAbaixoDoTitulo>
                )}
                {campanhaEstaConcluida ? (
                  <>
                    {!!investimentosNaCampanha.data?.quantidadeDeInvestidores && (
                      <ItemAbaixoDoTitulo>
                        <TituloDoItemAbaixoDoTitulo>
                          Total de investidores
                        </TituloDoItemAbaixoDoTitulo>
                        <ValorDoItemAbaixoDoTitulo>
                          {formatarDinheiro(
                            investimentosNaCampanha.data?.quantidadeDeInvestidores,
                            0,
                            'decimal',
                          )}
                        </ValorDoItemAbaixoDoTitulo>
                      </ItemAbaixoDoTitulo>
                    )}
                  </>
                ) : (
                  <>
                    {!!campanha?.valorDeCaptacaoMaximo && (
                      <ItemAbaixoDoTitulo>
                        <TituloDoItemAbaixoDoTitulo>Captação máxima</TituloDoItemAbaixoDoTitulo>
                        <ValorDoItemAbaixoDoTitulo
                          currency
                          cents={formatarDinheiro(campanha.valorDeCaptacaoMaximo).slice(-3)}
                        >
                          {formatarDinheiro(campanha.valorDeCaptacaoMaximo).slice(2, -3)}
                        </ValorDoItemAbaixoDoTitulo>
                      </ItemAbaixoDoTitulo>
                    )}
                  </>
                )}
              </ItensDaDireita>
            </ItensAbaixoDoTitulo>
            {!campanhaEstaConcluida && investimentosNaCampanha.data && (
              <BarraDeCaptacao
                carregando={investimentosNaCampanha.isLoading}
                valorMaximo={campanha?.valorDeCaptacaoMaximo ?? 0}
                valorInvestido={investimentosNaCampanha.data?.totalInvestido ?? 0}
                atingiuValorMinimo={investimentosNaCampanha.data?.atingiuValorMinimo ?? false}
              />
            )}
            {campanha && <BarraDeInformacoesDaCampanha campanha={campanha} />}
          </Section>
          <Section>
            <HeaderGrafico>
              <TituloGrafico>
                Reservas por dia
                <SubtituloGrafico>(Qtd Reservas x Valor Investido)</SubtituloGrafico>
              </TituloGrafico>
              <DataGrafico>Últimos 7 dias</DataGrafico>
            </HeaderGrafico>
            <GraficoBarraVertical />
          </Section>
          <LinhaDeBlocos>
            <BlocoInfo>
              <TituloBloco>
                Ticket Médio
                <br />
                (Sem institucional)
              </TituloBloco>
              <ValorBloco>R$23.800</ValorBloco>
            </BlocoInfo>
            <BlocoInfo>
              <TituloBloco>
                Total de Investidores na Campanha
                <br />
                (reserva + liquidação)
              </TituloBloco>
              <ValorBloco>13.800</ValorBloco>
            </BlocoInfo>
            <BlocoInfo>
              <TituloBloco>Total de investidores a liquidar</TituloBloco>
              <ValorBloco>3.800</ValorBloco>
            </BlocoInfo>
          </LinhaDeBlocos>
        </Main>
      </Container>
    </Menu>
  )
}
