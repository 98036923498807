export const columns = [
  { accessorKey: 'createdAt', header: 'Criado em' },
  { accessorKey: 'tipoDeInvestidor', header: 'Tipo' },
  {
    accessorKey: 'patrimonioOuRendaAnual',
    header: 'Patrimônio ou renda anual',
  },
  {
    accessorKey: 'jaInvestiuEmOutrasPlataformas',
    header: 'Já investiu em outras',
  },
  {
    accessorKey: 'quantoInvestiuEmOutrasPlataformas',
    header: 'Quanto investiu em outras plataformas',
  },
  {
    accessorKey: 'pessoaPoliticamenteExposta',
    header: 'Pessoa politicamente exposta',
  },
]
