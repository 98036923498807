import { useMutation, useQueryClient } from '@tanstack/react-query'
import { editarLogoDaCampanhaAsync } from '../api/editarLogoDaCampanhaAsync'

export const useCampanhaLogo = (slug: string) => {
  const queryClient = useQueryClient()

  async function editarAsync(request: File) {
    await editarLogoDaCampanhaAsync(slug, request)
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['campanha', slug])
    },
  })

  return {
    mutation,
  }
}
