import { useNavigate } from 'react-router-dom'
import { Text } from '../../@beegin/react/components/Text'
import { Permissao } from '../../constants/permissoes'
import { useAuth } from '../../hooks/auth'
import { Flex } from '../Flex'
import { Button } from '../../@beegin/react/components/Button'

type Props = {
  children: JSX.Element
  permissoes: Permissao[]
}

export function ValidadorDePermissaoPorPagina({ children, permissoes }: Props): JSX.Element {
  const navigate = useNavigate()

  const { isInAnyOfTheseRoles } = useAuth()

  if (isInAnyOfTheseRoles([...permissoes, 'Administrador', 'Invest.Admin'])) {
    return children
  }

  return (
    <Flex alignItems='center' justifyContent='center' direction='column'>
      <Text textAlign='center' fontSize='32px' fontWeight='bold' margin='0 0 24px 0'>
        😨
      </Text>
      <Text textAlign='center' fontSize='16px' margin='0 0 16px 0'>
        Você não tem permissão para acessar esse item.
      </Text>
      <Button variant='outlined' onClick={() => navigate(-1)}>
        👈 Voltar
      </Button>
    </Flex>
  )
}
