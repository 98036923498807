import styled, { css } from 'styled-components'
import { TextProps } from './props'

const sharedStyle = css<TextProps>`
  text-decoration: none;
  margin: 0;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin: 0;
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
    ${({ mobileFontWeight }) =>
      mobileFontWeight && `font-weight: ${mobileFontWeight};`}
    ${({ mobileLineHeight }) =>
      mobileLineHeight && `line-height: ${mobileLineHeight};`}
    ${({ mobileTextAlign }) =>
      mobileTextAlign && `text-align: ${mobileTextAlign};`}
  }
`

export const StyledSpan = styled.span<TextProps>`
  ${sharedStyle}
`

export const StyledParagraph = styled.p<TextProps>`
  ${sharedStyle}
`
