import { api } from '../services/api'

interface ObterHistoricoDoPerfilDeInvestidorResponse {
  historicoDePerfis: {
    createdAt: Date
    tipoDeInvestidor: string
    patrimonioOuRendaAnual: number
    jaInvestiuEmOutrasPlataformas: boolean
    quantoInvestiuEmOutrasPlataformas: number
    pessoaPoliticamenteExposta: boolean | null
  }[]
}

export async function obterHistoricoDoPerfilDeInvestidorAsync(
  investidorId: string,
): Promise<ObterHistoricoDoPerfilDeInvestidorResponse> {
  const response = await api.get<ObterHistoricoDoPerfilDeInvestidorResponse>(
    `/v2/usuarios/${investidorId}/historico-do-perfil-de-investidor`,
  )
  return response?.data
}
