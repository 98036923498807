import { useEffect } from 'react'
import { useAuth } from '../../hooks/auth'

export function Sair(): JSX.Element {
  const { logOut } = useAuth()

  useEffect(() => {
    logOut()
  }, [])

  return <p>Saindo...</p>
}
