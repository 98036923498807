import MuiTooltip from '@mui/material/Tooltip'
import * as React from 'react'

interface TooptipProps {
  texto: string
  children: React.ReactElement<any, any>
}

export function Tooltip({ children, texto }: TooptipProps) {
  return (
    <MuiTooltip color='primary' title={texto} enterTouchDelay={0}>
      {children}
    </MuiTooltip>
  )
}
