import { requestClient } from './beeginRequestClient'
import { CriarEmailsDosInformesDeRendimentosRequest } from '../types/criarEmailsDosInformesDeRendimentosRequest'

export const criarEmailsDosInformesDeRendimentosAsync = async (
  request: CriarEmailsDosInformesDeRendimentosRequest,
) => {
  await requestClient.apis.informeDeRendimentos.post(
    '/api/informes-de-rendimentos/e-mails',
    request,
  )
}
