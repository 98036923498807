import { Menu } from '../../components/menu'
import { FormEvent, useState } from 'react'
import { Link } from '../../components/link'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Button } from '../../@beegin/react/components/Button'
import { InputDeArquivo } from '../../components/InputDeArquivo'
import { useRelatorioDeCampanha } from '../../store/relatorioDeCampanha'
import { useCampanha } from '../../store/campanha'
import { useMutationToast } from '../../hooks/useMutationToast'

type Form = {
  nome: string
}

export function CriarRelatorioDeCampanha() {
  let { slug } = useParams()

  const [file, setFile] = useState<File | null>(null)
  const [form, setForm] = useState<Form>({
    nome: '',
  })

  const campanha = useCampanha({ slug })

  const relatorioDeCampanha = useRelatorioDeCampanha({ campanhaId: campanha.data?.id })

  useMutationToast({
    mutation: relatorioDeCampanha.mutationParaCriar,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
    carregando: 'Carregando...',
  })

  function resetarFormulario() {
    setFile(null)
    setForm({ nome: '' })
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!campanha.data?.id) {
      toast.error('Erro ao obter campanha.')
      return
    }
    if (!file) {
      toast.error('Selecione o arquivo.')
      return
    }
    await relatorioDeCampanha.mutationParaCriar.mutateAsync({
      campanhaId: campanha.data?.id,
      arquivo: file,
      nome: form.nome,
    })
    resetarFormulario()
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to={`/campanhas/${slug}/relatorios`}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Criar relatório de campanha</h1>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Input
            required
            label='Nome'
            value={form.nome}
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
          />
          <InputDeArquivo file={file} setFile={setFile} />
          <div>
            <Button
              variant='contained'
              type='submit'
              disabled={relatorioDeCampanha.mutationParaCriar.isLoading}
            >
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
