import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editarPermissoesDoUsuarioAsync } from '../api/editarPermissoesDoUsuarioAsync'
import { EditarPermissoesDoUsuarioRequest } from '../types/EditarPermissoesDoUsuarioRequest'
import { obterUsuarioPermissoesAsync } from '../api/obterUsuarioPermissoesAsync'

export const useUsuarioPermissoes = (usuarioId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['usuario-permissoes', usuarioId]

  const options = {
    enabled: !!usuarioId,
  }

  const response = useQuery(
    queryKey,
    async () => {
      if (!usuarioId) {
        return
      }
      return await obterUsuarioPermissoesAsync(usuarioId)
    },
    options,
  )

  async function editarAsync(request: EditarPermissoesDoUsuarioRequest) {
    if (!usuarioId) {
      return
    }
    await editarPermissoesDoUsuarioAsync(request)
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    mutation: mutation,
  }
}
