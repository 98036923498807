import { useQuery } from '@tanstack/react-query'
import { obterLoginDoUsuarioAsync } from '../api/obterLoginDoUsuarioAsync'

export const useUsuarioLogin = (usuarioId: string | undefined) => {
  const queryKey = ['login', usuarioId]

  const options = {
    enabled: !!usuarioId,
  }

  const response = useQuery(
    queryKey,
    async () => {
      if (!usuarioId) {
        return
      }
      const urlDeRetorno = process.env.REACT_APP_URL_WEB_BEEGIN ?? ''
      return await obterLoginDoUsuarioAsync({ usuarioId, urlDeRetorno: urlDeRetorno })
    },
    options,
  )

  return {
    data: response.data,
    isLoading: response.isLoading,
  }
}
