export const formatarPercentual = (
  valor: number,
  digitos: number = 2,
): string => {
  try {
    return Number(valor / 100).toLocaleString('pt-BR', {
      style: 'percent',
      minimumFractionDigits: digitos,
    })
  } catch {
    return valor.toString()
  }
}
