import { usuariosApi } from '../services/api'
import { RecuperarSenhaDoUsuarioRequest } from '../types/RecuperarSenhaDoUsuarioRequest'

export async function recuperarSenhaDoUsuarioAsync({
  usuarioId,
  veiculoDaMensagem,
}: RecuperarSenhaDoUsuarioRequest) {
  await usuariosApi.post(`/usuarios/${usuarioId}/resetpassword`, {
    veiculoDaMensagem,
  })
}
