import { useEffect, useState } from 'react'
import { UseMutationResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { obterErrorResponse } from '../utils/obterErrorResponse'

interface Props {
  mutation: UseMutationResult<any, any, any, any>
  sucesso: string
  erro: string
  carregando?: string
}

export function useMutationToast({ mutation, sucesso, erro, carregando }: Props): void {
  const [toastId] = useState(Math.random().toString(36).slice(2, 7))

  const { isLoading, isError, isSuccess, error } = mutation

  useEffect(() => {
    if (!isSuccess) {
      return
    }
    if (carregando) {
      toast.update(toastId, {
        render: sucesso,
        type: 'success',
        isLoading: false,
        autoClose: 4000,
      })
    } else {
      toast.success(sucesso)
    }
  }, [isLoading, isSuccess, sucesso, carregando, toastId])

  useEffect(() => {
    if (!isError) {
      return
    }
    if (carregando) {
      toast.update(toastId, {
        render: obterErrorResponse(error, erro),
        type: 'error',
        isLoading: false,
        autoClose: 4000,
      })
    } else {
      toast.error(obterErrorResponse(error, erro))
    }
  }, [isLoading, isError, error, carregando, erro, toastId])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    if (!carregando) {
      return
    }
    toast.loading(carregando, { toastId: toastId })
  }, [isLoading, carregando, toastId])
}
