export const bancos = {
  '001': '001 - BCO DO BRASIL S.A.',
  '070': '070 - BRB - BCO DE BRASILIA S.A.',
  '430': '430 - CCR SEARA',
  '272': '272 - AGK CC S.A.',
  '136': '136 - CONF NAC COOP CENTRAIS UNICRED',
  '407': '407 - ÍNDIGO INVESTIMENTOS DTVM LTDA.',
  '104': '104 - CAIXA ECONOMICA FEDERAL',
  '077': '077 - BANCO INTER',
  '423': '423 - COLUNA S.A. DTVM',
  '330': '330 - BANCO BARI S.A.',
  '741': '741 - BCO RIBEIRAO PRETO S.A.',
  '739': '739 - BCO CETELEM S.A.',
  '743': '743 - BANCO SEMEAR',
  '100': '100 - PLANNER CV S.A.',
  '096': '096 - BCO B3 S.A.',
  '747': '747 - BCO RABOBANK INTL BRASIL S.A.',
  '362': '362 - CIELO S.A.',
  '322': '322 - CCR DE ABELARDO LUZ',
  '748': '748 - BCO COOPERATIVO SICREDI S.A.',
  '350': '350 - CREHNOR LARANJEIRAS',
  '752': '752 - BCO BNP PARIBAS BRASIL S A',
  '379': '379 - CECM COOPERFORTE',
  '399': '399 - KIRTON BANK',
  '108': '108 - PORTOCRED S.A. - CFI',
  '378': '378 - BCO BRASILEIRO DE CRÉDITO S.A.',
  '413': '413 - BCO BV S.A.',
  '756': '756 - BANCO SICOOB S.A.',
  '360': '360 - TRINUS CAPITAL DTVM',
  '757': '757 - BCO KEB HANA DO BRASIL S.A.',
  '102': '102 - XP INVESTIMENTOS CCTVM S/A',
  '084': '084 - UNIPRIME DO BRASIL - CC LTDA.',
  '180': '180 - CM CAPITAL MARKETS CCTVM LTDA',
  '066': '066 - BCO MORGAN STANLEY S.A.',
  '015': '015 - UBS BRASIL CCTVM S.A.',
  '143': '143 - TREVISO CC S.A.',
  '062': '062 - HIPERCARD BM S.A.',
  '074': '074 - BCO. J.SAFRA S.A.',
  '099': '099 - UNIPRIME CENTRAL CCC LTDA.',
  '387': '387 - BCO TOYOTA DO BRASIL S.A.',
  '326': '326 - PARATI - CFI S.A.',
  '025': '025 - BCO ALFA S.A.',
  '315': '315 - PI DTVM S.A.',
  '075': '075 - BCO ABN AMRO S.A.',
  '040': '040 - BCO CARGILL S.A.',
  '307': '307 - TERRA INVESTIMENTOS DTVM',
  '385': '385 - CECM DOS TRAB.PORT. DA G.VITOR',
  '425': '425 - SOCINAL S.A. CFI',
  '190': '190 - SERVICOOP',
  '296': '296 - VISION S.A. CC',
  '063': '063 - BANCO BRADESCARD',
  '191': '191 - NOVA FUTURA CTVM LTDA.',
  '382': '382 - FIDUCIA SCMEPP LTDA',
  '064': '064 - GOLDMAN SACHS DO BRASIL BM S.A',
  '459': '459 - CCM SERV. PÚBLICOS SP',
  '097': '097 - CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
  '016': '016 - CCM DESP TRÂNS SC E RS',
  '299': '299 - BCO SOROCRED S.A. - BM',
  '468': '468 - PORTOSEG S.A. CFI',
  '012': '012 - BANCO INBURSA',
  '003': '003 - BCO DA AMAZONIA S.A.',
  '060': '060 - CONFIDENCE CC S.A.',
  '037': '037 - BCO DO EST. DO PA S.A.',
  '411': '411 - VIA CERTA FINANCIADORA S.A. - CFI',
  '359': '359 - ZEMA CFI S/A',
  '159': '159 - CASA CREDITO S.A. SCM',
  '085': '085 - COOPCENTRAL AILOS',
  '400': '400 - COOP CREDITAG',
  '429': '429 - CREDIARE CFI S.A.',
  '410': '410 - PLANNER SCM S.A.',
  '114': '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
  '328': '328 - CECM FABRIC CALÇADOS SAPIRANGA',
  '036': '036 - BCO BBI S.A.',
  '469': '469 - LECCA DTVM LTDA',
  '394': '394 - BCO BRADESCO FINANC. S.A.',
  '004': '004 - BCO DO NORDESTE DO BRASIL S.A.',
  '458': '458 - HEDGE INVESTMENTS DTVM LTDA.',
  '320': '320 - BCO CCB BRASIL S.A.',
  '189': '189 - HS FINANCEIRA',
  '105': '105 - LECCA CFI S.A.',
  '076': '076 - BCO KDB BRASIL S.A.',
  '082': '082 - BANCO TOPÁZIO S.A.',
  '312': '312 - HSCM SCMEPP LTDA.',
  '195': '195 - VALOR SCD S.A.',
  '286': '286 - CCR DE OURO',
  '093': '093 - POLOCRED SCMEPP LTDA.',
  '391': '391 - CCR DE IBIAM',
  '273': '273 - CCR DE SÃO MIGUEL DO OESTE',
  '368': '368 - BCO CSF S.A.',
  '290': '290 - PAGSEGURO INTERNET IP S.A.',
  '259': '259 - MONEYCORP BCO DE CÂMBIO S.A.',
  '395': '395 - F D GOLD DTVM LTDA',
  '364': '364 - GERENCIANET',
  '157': '157 - ICAP DO BRASIL CTVM LTDA.',
  '183': '183 - SOCRED SA - SCMEPP',
  '014': '014 - STATE STREET BR S.A. BCO COMERCIAL',
  '130': '130 - CARUANA SCFI',
  '358': '358 - MIDWAY S.A. - SCFI',
  '127': '127 - CODEPE CVC S.A.',
  '079': '079 - BCO ORIGINAL DO AGRO S/A',
  '340': '340 - SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.',
  '081': '081 - BANCOSEGURO S.A.',
  '133': '133 - CRESOL CONFEDERAÇÃO',
  '323': '323 - MERCADO PAGO IP LTDA.',
  '121': '121 - BCO AGIBANK S.A.',
  '083': '083 - BCO DA CHINA BRASIL S.A.',
  '138': '138 - GET MONEY CC LTDA',
  '024': '024 - BCO BANDEPE S.A.',
  '384': '384 - GLOBAL SCM LTDA',
  '426': '426 - BIORC FINANCEIRA - CFI S.A.',
  '088': '088 - BANCO RANDON S.A.',
  '319': '319 - OM DTVM LTDA',
  '274': '274 - MONEY PLUS SCMEPP LTDA',
  '095': '095 - TRAVELEX BANCO DE CÂMBIO S.A.',
  '094': '094 - BANCO FINAXIS',
  '478': '478 - GAZINCRED S.A. SCFI',
  '276': '276 - BCO SENFF S.A.',
  '447': '447 - MIRAE ASSET CCTVM LTDA',
  '092': '092 - BRK S.A. CFI',
  '047': '047 - BCO DO EST. DE SE S.A.',
  '144': '144 - BEXS BCO DE CAMBIO S.A.',
  '332': '332 - ACESSO SOLUCOES PAGAMENTO SA',
  '450': '450 - FITBANK PAGAMENTOS ELETRONICOS S.A.',
  '126': '126 - BR PARTNERS BI',
  '325': '325 - ÓRAMA DTVM S.A.',
  '301': '301 - BPP IP S.A.',
  '173': '173 - BRL TRUST DTVM SA',
  '331': '331 - FRAM CAPITAL DTVM S.A.',
  '119': '119 - BCO WESTERN UNION',
  '396': '396 - HUB PAGAMENTOS',
  '309': '309 - CAMBIONET CC LTDA',
  '254': '254 - PARANA BCO S.A.',
  '268': '268 - BARI CIA HIPOTECÁRIA',
  '401': '401 - IUGU IP S.A.',
  '107': '107 - BCO BOCOM BBM S.A.',
  '412': '412 - SOCIAL BANK S/A',
  '124': '124 - BCO WOORI BANK DO BRASIL S.A.',
  '149': '149 - FACTA S.A. CFI',
  '197': '197 - STONE IP S.A.',
  '439': '439 - ID CTVM',
  '313': '313 - AMAZÔNIA CC LTDA.',
  '142': '142 - BROKER BRASIL CC LTDA.',
  '389': '389 - BCO MERCANTIL DO BRASIL S.A.',
  '184': '184 - BCO ITAÚ BBA S.A.',
  '634': '634 - BCO TRIANGULO S.A.',
  '545': '545 - SENSO CCVM S.A.',
  '132': '132 - ICBC DO BRASIL BM S.A.',
  '298': '298 - VIPS CC LTDA.',
  '377': '377 - BMS SCD S.A.',
  '321': '321 - CREFAZ SCMEPP LTDA',
  '260': '260 - NU PAGAMENTOS - IP',
  '470': '470 - CDC SOCIEDADE DE CRÉDITO',
  '129': '129 - UBS BRASIL BI S.A.',
  '128': '128 - MS BANK S.A. BCO DE CÂMBIO',
  '416': '416 - LAMARA SCD S.A.',
  '461': '461 - ASAAS IP S.A.',
  '194': '194 - PARMETAL DTVM LTDA',
  '383': '383 - EBANX IP LTDA.',
  '324': '324 - CARTOS SCD S.A.',
  '310': '310 - VORTX DTVM LTDA.',
  '380': '380 - PICPAY',
  '163': '163 - COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
  '280': '280 - WILL FINANCEIRA S.A.CFI',
  '146': '146 - GUITTA CC LTDA',
  '343': '343 - FFA SCMEPP LTDA.',
  '279': '279 - CCR DE PRIMAVERA DO LESTE',
  '335': '335 - BANCO DIGIO',
  '349': '349 - AL5 S.A. CFI',
  '427': '427 - CRED-UFES',
  '374': '374 - REALIZE CFI S.A.',
  '278': '278 - GENIAL INVESTIMENTOS CVM S.A.',
  '271': '271 - IB CCTVM S.A.',
  '021': '021 - BCO BANESTES S.A.',
  '246': '246 - BCO ABC BRASIL S.A.',
  '292': '292 - BS2 DTVM S.A.',
  '751': '751 - SCOTIABANK BRASIL',
  '352': '352 - TORO CTVM S.A.',
  '208': '208 - BANCO BTG PACTUAL S.A.',
  '386': '386 - NU FINANCEIRA S.A. CFI',
  '746': '746 - BCO MODAL S.A.',
  '241': '241 - BCO CLASSICO S.A.',
  '398': '398 - IDEAL CTVM S.A.',
  '336': '336 - BCO C6 S.A.',
  '612': '612 - BCO GUANABARA S.A.',
  '604': '604 - BCO INDUSTRIAL DO BRASIL S.A.',
  '505': '505 - BCO CREDIT SUISSE S.A.',
  '329': '329 - QI SCD S.A.',
  '196': '196 - FAIR CC S.A.',
  '342': '342 - CREDITAS SCD',
  '300': '300 - BCO LA NACION ARGENTINA',
  '477': '477 - CITIBANK N.A.',
  '266': '266 - BCO CEDULA S.A.',
  '122': '122 - BCO BRADESCO BERJ S.A.',
  '376': '376 - BCO J.P. MORGAN S.A.',
  '348': '348 - BCO XP S.A.',
  '473': '473 - BCO CAIXA GERAL BRASIL S.A.',
  '745': '745 - BCO CITIBANK S.A.',
  '120': '120 - BCO RODOBENS S.A.',
  '265': '265 - BCO FATOR S.A.',
  '007': '007 - BNDES',
  '188': '188 - ATIVA S.A. INVESTIMENTOS CCTVM',
  '134': '134 - BGC LIQUIDEZ DTVM LTDA',
  '029': '029 - BANCO ITAÚ CONSIGNADO S.A.',
  '467': '467 - MASTER S/A CCTVM',
  '243': '243 - BANCO MASTER',
  '397': '397 - LISTO SCD S.A.',
  '078': '078 - HAITONG BI DO BRASIL S.A.',
  '355': '355 - ÓTIMO SCD S.A.',
  '367': '367 - VITREO DTVM S.A.',
  '445': '445 - PLANTAE CFI',
  '373': '373 - UP.P SEP S.A.',
  '111': '111 - OLIVEIRA TRUST DTVM S.A.',
  '408': '408 - BONUSPAGO SCD S.A.',
  '402': '402 - COBUCCIO SCD S.A.',
  '404': '404 - SUMUP SCD S.A.',
  '418': '418 - ZIPDIN SCD S.A.',
  '414': '414 - WORK SCD S.A.',
  '449': '449 - DMCARD SCD S.A.',
  '406': '406 - ACCREDITO SCD S.A.',
  '403': '403 - CORA SCD S.A.',
  '419': '419 - NUMBRS SCD S.A.',
  '435': '435 - DELCRED SCD S.A.',
  '421': '421 - CC LAR CREDI',
  '443': '443 - CREDIHOME SCD',
  '457': '457 - UY3 SCD S/A',
  '428': '428 - CRED-SYSTEM SCD S.A.',
  '448': '448 - HEMERA DTVM LTDA.',
  '452': '452 - CREDIFIT SCD S.A.',
  '462': '462 - STARK SCD S.A.',
  '465': '465 - CAPITAL CONSIG SCD S.A.',
  '306': '306 - PORTOPAR DTVM LTDA',
  '463': '463 - AZUMI DTVM',
  '451': '451 - J17 - SCD S/A',
  '444': '444 - TRINUS SCD S.A.',
  '454': '454 - MÉRITO DTVM LTDA.',
  '460': '460 - UNAVANTI SCD S/A',
  '017': '017 - BNY MELLON BCO S.A.',
  '174': '174 - PEFISA S.A. - C.F.I.',
  '433': '433 - BR-CAPITAL DTVM S.A.',
  '495': '495 - BCO LA PROVINCIA B AIRES BCE',
  '125': '125 - BANCO GENIAL',
  '488': '488 - JPMORGAN CHASE BANK',
  '065': '065 - BCO ANDBANK S.A.',
  '492': '492 - ING BANK N.V.',
  '145': '145 - LEVYCAM CCV LTDA',
  '250': '250 - BCV - BCO, CRÉDITO E VAREJO S.A.',
  '354': '354 - NECTON INVESTIMENTOS S.A CVM',
  '253': '253 - BEXS CC S.A.',
  '269': '269 - BCO HSBC S.A.',
  '213': '213 - BCO ARBI S.A.',
  '139': '139 - INTESA SANPAOLO BRASIL S.A. BM',
  '018': '018 - BCO TRICURY S.A.',
  '422': '422 - BCO SAFRA S.A.',
  '630': '630 - BCO LETSBANK S.A.',
  '224': '224 - BCO FIBRA S.A.',
  '393': '393 - BCO VOLKSWAGEN S.A',
  '600': '600 - BCO LUSO BRASILEIRO S.A.',
  '390': '390 - BCO GM S.A.',
  '623': '623 - BANCO PAN',
  '655': '655 - BCO VOTORANTIM S.A.',
  '479': '479 - BCO ITAUBANK S.A.',
  '456': '456 - BCO MUFG BRASIL S.A.',
  '464': '464 - BCO SUMITOMO MITSUI BRASIL S.A.',
  '341': '341 - ITAÚ UNIBANCO S.A.',
  '237': '237 - BCO BRADESCO S.A.',
  '381': '381 - BCO MERCEDES-BENZ S.A.',
  '613': '613 - OMNI BANCO S.A.',
  '652': '652 - ITAÚ UNIBANCO HOLDING S.A.',
  '637': '637 - BCO SOFISA S.A.',
  '653': '653 - BANCO VOITER',
  '069': '069 - BCO CREFISA S.A.',
  '370': '370 - BCO MIZUHO S.A.',
  '249': '249 - BANCO INVESTCRED UNIBANCO S.A.',
  '318': '318 - BCO BMG S.A.',
  '626': '626 - BCO C6 CONSIG',
  '270': '270 - SAGITUR CC LTDA',
  '366': '366 - BCO SOCIETE GENERALE BRASIL',
  '113': '113 - NEON CTVM S.A.',
  '131': '131 - TULLETT PREBON BRASIL CVC LTDA',
  '011': '011 - C.SUISSE HEDGING-GRIFFO CV S/A',
  '611': '611 - BCO PAULISTA S.A.',
  '755': '755 - BOFA MERRILL LYNCH BM S.A.',
  '089': '089 - CREDISAN CC',
  '643': '643 - BCO PINE S.A.',
  '140': '140 - NU INVEST CORRETORA DE VALORES S.A.',
  '707': '707 - BCO DAYCOVAL S.A',
  '288': '288 - CAROL DTVM LTDA.',
  '363': '363 - SINGULARE CTVM S.A.',
  '101': '101 - RENASCENCA DTVM LTDA',
  '487': '487 - DEUTSCHE BANK S.A.BCO ALEMAO',
  '233': '233 - BANCO CIFRA',
  '177': '177 - GUIDE',
  '438': '438 - PLANNER TRUSTEE DTVM LTDA',
  '365': '365 - SIMPAUL',
  '633': '633 - BCO RENDIMENTO S.A.',
  '218': '218 - BCO BS2 S.A.',
  '293': '293 - LASTRO RDV DTVM LTDA',
  '285': '285 - FRENTE CC LTDA.',
  '080': '080 - B&T CC LTDA.',
  '753': '753 - NOVO BCO CONTINENTAL S.A. - BM',
  '222': '222 - BCO CRÉDIT AGRICOLE BR S.A.',
  '281': '281 - CCR COOPAVEL',
  '754': '754 - BANCO SISTEMA',
  '311': '311 - DOURADA CORRETORA',
  '098': '098 - CREDIALIANÇA CCR',
  '610': '610 - BCO VR S.A.',
  '712': '712 - BCO OURINVEST S.A.',
  '720': '720 - BCO RNX S.A.',
  '010': '010 - CREDICOAMO',
  '440': '440 - CREDIBRF COOP',
  '442': '442 - MAGNETIS - DTVM',
  '283': '283 - RB INVESTIMENTOS DTVM LTDA.',
  '033': '033 - BCO SANTANDER (BRASIL) S.A.',
  '217': '217 - BANCO JOHN DEERE S.A.',
  '041': '041 - BCO DO ESTADO DO RS S.A.',
  '117': '117 - ADVANCED CC LTDA',
  '654': '654 - BCO DIGIMAIS S.A.',
  '371': '371 - WARREN CVMC LTDA',
  '212': '212 - BANCO ORIGINAL',
  '289': '289 - EFX CC LTDA.',
  '536': '536 - NEON PAGAMENTOS',
}
