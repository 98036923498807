import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { columns } from './columns'
import { useInvestimentos } from '../../store/investimentos'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Input } from '../../@beegin/react/components/Input'
import { Select } from '../../@beegin/react/components/Select'
import { Main, Title, BlocoBranco } from './styles'

type Filtros = {
  id: string
  nomeDoInvestidor: string
}

const initialState = {
  id: '',
  nomeDoInvestidor: '',
}

export function ListarInvestimentos() {
  const [form, setForm] = useState<Filtros>(initialState)

  const [filtros, setFiltros] = useState<Filtros>(initialState)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [status, setStatus] = useState<string>('')

  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const { isLoading, data } = useInvestimentos({
    page,
    size,
    campanhaId: query.get('campanha-id') as string,
    investidorId: query.get('investidor-id') as string,
    status,
    ...filtros,
  })

  useEffect(() => {
    setPage(0)
  }, [filtros])

  return (
    <Menu>
      <Main>
        <Title>Investimentos</Title>
        <BlocoBranco>
          <PilhaDeItens direction='row' mobileDirection='column' margin='0 0 16px 0'>
            <Input
              fullWidth
              label='ID'
              value={form.id}
              onChange={(e) => setForm({ ...form, id: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Input
              fullWidth
              label='Nome do investidor'
              value={form.nomeDoInvestidor}
              onChange={(e) => setForm({ ...form, nomeDoInvestidor: e.target.value })}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setFiltros(form)
                }
              }}
            />
            <Select
              label='Status'
              value={status}
              onChange={(e: any) => setStatus(e.target.value)}
              options={[
                { value: '', label: '-' },
                { value: 'Intencao', label: 'Intenção' },
                { value: 'Realizado', label: 'Realizado' },
                { value: 'Cancelado', label: 'Cancelado' },
                { value: 'Reembolsar', label: 'Reembolsar' },
                { value: 'Reembolsado', label: 'Reembolsado' },
              ]}
            />
          </PilhaDeItens>
          <Esqueleto width='100%' height='600px' active={isLoading} doNotRenderChildrenWhenLoading>
            <Tabela
              columns={columns}
              rows={data?.investimentos}
              page={page}
              pageRowsSize={size}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
              onPageChange={(page: number) => setPage(page)}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
