import { FormEvent, useCallback, useEffect, useState } from 'react'
import { MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { SelectComAutocomplete } from '../../@beegin/react/components/Select'
import { useMutationToast } from '../../hooks/useMutationToast'
import { Button } from '../../@beegin/react/components/Button'
import { initialState } from './initialState'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { bancos } from '../../enums/bancos'
import { DadosBancarios } from '../../types/InvestidorDTO'
import { useInvestidor } from '../../store/investidor'
import { useValidation } from '../../hooks/useValidation'
import { toast } from 'react-toastify'
import { getErrorMessageProps } from '../../utils/getErrorMessageProps'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeDadosBancariosDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const [form, setForm] = useState<DadosBancarios>(initialState)

  const { data, isError, isLoading, mutation } = useInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  const { errors, validateAsync } = useValidation<DadosBancarios>({
    model: form,
    mutationError: mutation.error,
  })

  const iniciarFormulario = useCallback(async () => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ ...data.dadosBancarios })
  }, [data, investidorId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { isValid } = await validateAsync()
    if (!isValid) {
      toast.error('Há um erro no formulário.')
      return
    }
    await mutation.mutateAsync({ dadosBancarios: form })
    iniciarFormulario()
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  if (isError) {
    return <p>Erro.</p>
  }

  if (!data || isLoading || !form) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 16px 0'>
        <SelectComAutocomplete
          {...getErrorMessageProps(errors, 'numeroBanco')}
          required
          disabled={desabilitarInputs}
          label='Banco'
          value={form.numeroBanco ?? ''}
          onChange={(valor) => {
            setForm((form) => ({ ...form, numeroBanco: valor as string }))
          }}
          options={[
            { value: '', label: '' },
            ...Object.keys(bancos).map((item) => ({
              // @ts-ignore
              label: bancos[item],
              value: item,
            })),
          ]}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'numeroAgencia')}
          mascara={{
            allowLeadingZeros: true,
          }}
          required
          disabled={desabilitarInputs}
          label='Agência'
          value={form.numeroAgencia ?? ''}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, numeroAgencia: e.value }))
          }}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'numeroConta')}
          mascara={{
            allowLeadingZeros: true,
          }}
          required
          disabled={desabilitarInputs}
          label='Conta'
          value={form.numeroConta ?? ''}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, numeroConta: e.value }))
          }}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'numeroContaDigito')}
          mascara={{ format: '#', allowLeadingZeros: true }}
          required
          disabled={desabilitarInputs}
          label='Dígito'
          value={form.numeroContaDigito ?? ''}
          onValueChange={(e) => {
            setForm((form) => ({ ...form, numeroContaDigito: e.value }))
          }}
        />
      </PilhaDeItens>
      <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
        Salvar
      </Button>
    </form>
  )
}
