import styled from 'styled-components'
import { useRef, useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts'

interface BarProps {
  name: string
  value: number
}

const data: BarProps[] = [
  { name: 'Usuários pré cadastro', value: 9787 },
  { name: 'Conta validada', value: 8927 },
  { name: 'Cadastro completo', value: 8000 },
  { name: 'Cadastros aprovados', value: 7000 },
  { name: 'Reservas', value: 5000 },
  { name: 'Liquidações', value: 3000 },
]

const TickerNome = styled.text`
  // font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: red;
`
const TickerValor = styled.text`
  // font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #212427;
`

// const TickerPorcentagem = styled.text`
//   // font-family: Ubuntu;
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 18px;
//   color: #212427;
// `

export const GraficoBarraHorizontal: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [chartWidth, setChartWidth] = useState<number>(0)

  useEffect(() => {
    setChartWidth(chartRef.current ? chartRef.current.offsetWidth : 0)
  }, [chartRef])

  const CustomYTick = (props: { x: number; y: number; payload: { value: string } }) => {
    const { y, payload } = props
    return (
      <TickerNome x={0} y={y} textAnchor='start' dy={4}>
        {payload.value}
      </TickerNome>
    )
  }

  interface RenderLabelProps {
    x?: string | number
    y?: string | number
    width?: string | number
    value?: string | number
  }

  /* eslint-disable react/prop-types */
  const renderLabel = (props: RenderLabelProps) => {
    const { x, y, width, value } = props
    if (!x || !y || !width || !value) return null
    if (typeof y !== 'number') return null
    if (typeof width !== 'number') return null
    if (typeof value !== 'number') return null
    const xPos = chartWidth - 10
    return (
      <TickerValor x={xPos} y={y + 7} fill='#666' textAnchor='end' dominantBaseline='middle'>
        {value}
      </TickerValor>
    )
  }

  return (
    <div ref={chartRef} style={{ width: '100%' }}>
      <ResponsiveContainer width='100%' height={200}>
        <BarChart
          data={data}
          layout='vertical'
          margin={{ top: 0, right: 120, left: 85, bottom: 0 }}
          barGap={12}
        >
          <XAxis type='number' hide />
          <YAxis
            dataKey='name'
            type='category'
            width={80}
            axisLine={false}
            tickLine={false}
            tick={CustomYTick}
          />
          <Bar dataKey='value' fill='#2571FF' barSize={16} radius={16}>
            <LabelList content={renderLabel} position='insideRight' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
