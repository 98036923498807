import styled from 'styled-components'

interface IBarraProps {
  width: number
  backgroundColor: string
}

export const Barra = styled.div`
  width: 100%;
  height: 23px;
  border-radius: 4px;
  position: relative;
  margin-top: 24px;
  background-color: #cfd0d4;

  @media (max-width: 768px) {
    height: 22px;
    background-color: #e6e8eb;
    border: 1px solid white;
    margin-top: 16px;
  }
`

export const BarraDeIntencoes = styled.div<IBarraProps>`
  width: ${(props) => `${props.width}%`};
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  border-radius: 3px 0 0 3px;
`

export const Percentuais = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(64px, 64px));
  width: auto;
  gap: 32px;
  margin-top: 8px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 12.5px;
    margin-top: 10px;
  }
`

export const Percentual = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const PercentualTitulo = styled.div`
  // font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.15px;
  color: #5b616a;
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 768px) {
    line-height: 145%;
    font-weight: 500;
  }
`
interface ILegendaProps {
  color: string
}

export const CorDaLegenda = styled.div<ILegendaProps>`
  height: 12px;
  width: 12px;
  border-radius: 4px;

  background-color: ${({ color }) => color};

  @media (min-width: 769px) {
    display: none;
  }
`

interface IPercentualValorProps {
  color: string
}

export const PercentualValor = styled.div<IPercentualValorProps>`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-top: 4px;
  color: ${(props) => props.color};

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: black;
  }
`
