import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { formatarData } from '../../utils/formatarData'
import Checkbox from '@mui/material/Checkbox'
import { ColumnProps } from '../../components/Tabela'

type Props = {
  handleSelecionarLinha: (rowId: string) => void
  handleSelecionarTodasAsLinhas: () => void
  itensSelecionados: string[]
  pageLength: number
}

export const columns = ({
  handleSelecionarLinha,
  handleSelecionarTodasAsLinhas,
  itensSelecionados,
  pageLength,
}: Props): ColumnProps[] => [
  {
    accessorKey: 'id',
    maxSize: 1,
    header: () => (
      <Checkbox
        checked={itensSelecionados.length === pageLength && pageLength !== 0}
        onChange={handleSelecionarTodasAsLinhas}
      />
    ),
    cell: (props: any) => {
      const value = props.getValue()
      return (
        <Checkbox
          checked={itensSelecionados.includes(value)}
          onChange={() => handleSelecionarLinha(value)}
        />
      )
    },
  },
  {
    accessorKey: 'nomeDoInvestidor',
    header: 'Nome',
  },
  {
    accessorKey: 'documentoDoInvestidor',
    header: 'Documento',
  },
  {
    accessorKey: 'anoCalendario',
    header: 'Ano calendário',
  },
  {
    accessorKey: 'url',
    header: 'Url',
    cell: (props: any) => {
      const value = props.getValue()
      if (!value) {
        return <OpenInNewIcon color='disabled' />
      }
      return (
        <a href={value} target='_blank' rel='noreferrer'>
          <OpenInNewIcon />
        </a>
      )
    },
  },
  {
    accessorKey: 'dataDeAtualizacaoDoStatus',
    header: 'Atualização',
    cell: (props: any) => {
      const value = props.getValue()
      try {
        return formatarData(new Date(value), 'dd/MM/yyyy HH:mm:ss')
      } catch {
        return value
      }
    },
  },
  {
    accessorKey: 'dataDeEnvioDoEmail',
    header: 'Envio do e-mail',
    cell: (props: any) => {
      const value = props.getValue()
      if (!value) {
        return '-'
      }
      try {
        return formatarData(new Date(value), 'dd/MM/yyyy HH:mm:ss')
      } catch {
        return value
      }
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
]
