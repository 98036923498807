import { ListarCampanhasResponse } from '../types/ListarCampanhasResponse'
import { requestClient } from './beeginRequestClient'

type ListarCampanhasRequestQuery = {
  page?: number
  size?: number
  status?: string[]
}

export async function listarCampanhasAsync(
  request?: ListarCampanhasRequestQuery,
): Promise<ListarCampanhasResponse> {
  const query = new URLSearchParams()
  if (request) {
    if (request.page) {
      query.append('page', request.page.toString())
    }
    if (request.size) {
      query.append('size', request.size.toString())
    }
    if (request.status) {
      request.status.forEach((x) => {
        query.append('status', x)
      })
    }
  }
  const url = `/campanhas?${query}`
  const response = await requestClient.apis.campanhaApi.get<ListarCampanhasResponse>(url)
  return response.data
}
