import { assessoresApi } from '../services/api'

type EditarRelacionamentoAssessorInvestidor = {
  assessorId: string
}

export async function editarRelacionamentoAssessorInvestidor(
  investidorId: string,
  request: EditarRelacionamentoAssessorInvestidor,
): Promise<void> {
  await assessoresApi.put(`investidores/${investidorId}/assessor`, request)
}
