import React from 'react'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'

interface Props {
  text?: string
  maxWidth?: string
  marginVertical?: number
}

export function Divisor({ text, maxWidth, marginVertical = 5 }: Props) {
  if (text) {
    return (
      <Divider sx={{ maxWidth: maxWidth, my: marginVertical }}>{<Chip label={text} />}</Divider>
    )
  }
  return <Divider sx={{ maxWidth: maxWidth, my: marginVertical }} />
}
