import { formatarData } from '../../utils/formatarData'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import LaunchIcon from '@mui/icons-material/Launch'
import { Link } from '../../components/link'
import { Flex } from '../../components/Flex'
import { ColumnProps } from '../../components/Tabela'

export const columns: ColumnProps[] = [
  {
    header: '',
    accessorKey: 'slug',
    maxSize: 20,
    cell: (props: any) => {
      return (
        <Link to={`/campanhas/${props.getValue()}`}>
          <Flex alignItems='center' gap='4px'>
            <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
          </Flex>
        </Link>
      )
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    size: 60,
  },
  {
    accessorKey: 'nome',
    header: 'Nome',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'valorDeCaptacaoMaximo',
    header: 'Target',
    cell: (props: any) => formatarDinheiro(props.getValue()),
  },
  {
    accessorKey: 'dataDeInicio',
    header: 'Início',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    accessorKey: 'dataDeFechamento',
    header: 'Fim',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    accessorKey: 'tipo',
    header: 'Tipo',
  },
]
