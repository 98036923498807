import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EditarInvestidorEmpresaRequest } from '../types/EditarInvestidorEmpresaRequest'
import { editarInvestidorEmpresaAsync } from '../api/editarInvestidorEmpresaAsync'
import { obterInvestidorEmpresaAsync } from '../api/obterInvestidorEmpresaAsync'

export const useInvestidorPessoaJuridica = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['investidor-pessoa-juridica', investidorId]

  const options = {
    retry: false,
    enabled: !!investidorId,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterInvestidorEmpresaAsync(investidorId)
  }

  const response = useQuery(queryKey, getAsync, options)

  const editarAsync = async (form: EditarInvestidorEmpresaRequest) => {
    if (!response.data) {
      return
    }
    if (!investidorId) {
      return
    }
    await editarInvestidorEmpresaAsync(investidorId, form)
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading: response.isLoading,
    isError: response.isError,
    mutation,
    data: response.data,
  }
}
