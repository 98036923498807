import { ConjuntoDeCheckboxes } from '../../@beegin/react/components/ConjuntoDeCheckboxes'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { Select, SelectComAutocomplete } from '../../@beegin/react/components/Select'
import { cnpjEstaValido } from '../../@beegin/react/utils/cnpjEstaValido'
import { setores } from '../../constants/setores'
import { CriarCampanhaRequest } from '../../types/CriarCampanhaRequest'
import { OndeFicaNoInformeDeRendimentosEnum } from '../../types/enums/OndeFicaNoInformeDeRendimentosEnum'
import { TipoLiquidacaoCampanha } from '../../types/enums/TipoLiquidacaoCampanha'
import { TipoSocietarioEnum } from '../../types/enums/TipoSocietarioEnum'

type Props = {
  form: CriarCampanhaRequest
  setForm: React.Dispatch<React.SetStateAction<CriarCampanhaRequest>>
}

export function FormularioDeCriarCampanha({ form, setForm }: Props) {
  return (
    <>
      <Input
        required
        label='Nome'
        value={form.nome}
        onChange={(e) => setForm({ ...form, nome: e.target.value })}
      />
      <Input
        multiline
        rows={5}
        required
        label='Descrição'
        value={form.descricao}
        onChange={(e) => setForm({ ...form, descricao: e.target.value })}
      />
      <Input
        required
        label='Slug'
        value={form.slug}
        onChange={(e) => setForm({ ...form, slug: e.target.value })}
      />
      <ConjuntoDeCheckboxes
        label=''
        options={[
          {
            checked: form.privada ?? false,
            onChange: () =>
              setForm({
                ...form,
                privada: !form.privada,
              }),
            label: 'Campanha privada',
          },
          {
            checked: form.estaListadaNaBee4 ?? false,
            onChange: () =>
              setForm({
                ...form,
                estaListadaNaBee4: !form.estaListadaNaBee4,
              }),
            label: 'Está listada na BEE4',
          },
          {
            checked: form.gerarContratoAposInvestimento ?? false,
            onChange: () =>
              setForm({
                ...form,
                gerarContratoAposInvestimento: !form.gerarContratoAposInvestimento,
              }),
            label: 'Gerar contrato após investimento',
          },
          {
            checked: form.enviarEmailAposInvestimento ?? false,
            onChange: () =>
              setForm({
                ...form,
                enviarEmailAposInvestimento: !form.enviarEmailAposInvestimento,
              }),
            label: 'Enviar e-mail após investimento',
          },
        ]}
      />
      <MaskedInput
        mascara='cnpj'
        required
        label='CNPJ'
        value={form.cnpj}
        onValueChange={(e) => setForm({ ...form, cnpj: e.value })}
        error={form.cnpj ? !cnpjEstaValido(form.cnpj) : false}
        helperText={form.cnpj && !cnpjEstaValido(form.cnpj) && 'CNPJ inválido'}
      />
      <Select
        required
        label='Tipo'
        value={form.tipo}
        options={[
          { label: 'Participação', value: 'Participação' },
          { label: 'Dívida', value: 'Dívida' },
          { label: 'Imobiliário', value: 'Imobiliário' },
          { label: 'Ação', value: 'Ação' },
          { label: 'Mútuo conversível', value: 'Mútuo conversível' },
        ]}
        onChange={(e) => setForm({ ...form, tipo: e.target.value as string })}
      />
      <Select
        required
        label='Tipo de liquidação'
        value={form.tipoLiquidacaoCampanha}
        options={[
          { label: 'BEE4', value: 'BEE4' },
          { label: 'FitBank', value: 'FitBank' },
          {
            label: 'Marcar investimento como realizado automaticamente',
            value: 'MarcarInvestimentoComoRealizadoAutomaticamente',
          },
        ]}
        onChange={(e) =>
          setForm({ ...form, tipoLiquidacaoCampanha: e.target.value as TipoLiquidacaoCampanha })
        }
      />
      <SelectComAutocomplete
        required
        label='Setor'
        value={form.setor}
        options={[{ label: '', value: '' }].concat(setores.map((x) => ({ value: x, label: x })))}
        onChange={(e) => setForm({ ...form, setor: e })}
      />
      <MaskedInput
        required
        label='Data de início'
        mascara='data'
        value={form.dataDeInicio}
        onValueChange={(e) =>
          setForm({
            ...form,
            dataDeInicio: e.formattedValue,
          })
        }
      />
      <MaskedInput
        label='Data de fechamento'
        mascara='data'
        value={form.dataDeFechamento}
        onValueChange={(e) =>
          setForm({
            ...form,
            dataDeFechamento: e.formattedValue,
          })
        }
      />
      <MaskedInput
        label='Data de conclusão'
        mascara='data'
        value={form.dataDeConclusao}
        onValueChange={(e) =>
          setForm({
            ...form,
            dataDeConclusao: e.formattedValue,
          })
        }
      />
      <MaskedInput
        required
        label='Valuation da rodada'
        mascara='dinheiro'
        value={form.valuationDaRodada}
        onValueChange={(e) =>
          setForm({
            ...form,
            valuationDaRodada: e.floatValue,
          })
        }
      />
      <MaskedInput
        required
        label='Valuation pre-money'
        mascara='dinheiro'
        value={form.valuationPreMoney}
        onValueChange={(e) =>
          setForm({
            ...form,
            valuationPreMoney: e.floatValue,
          })
        }
      />
      <MaskedInput
        required
        label='Captação máxima'
        mascara='dinheiro'
        value={form.valorDeCaptacaoMaximo}
        onValueChange={(e) =>
          setForm({
            ...form,
            valorDeCaptacaoMaximo: e.floatValue,
          })
        }
      />
      <MaskedInput
        required
        label='Captação mínima'
        mascara='dinheiro'
        value={form.valorDeCaptacaoMinimo}
        onValueChange={(e) =>
          setForm({
            ...form,
            valorDeCaptacaoMinimo: e.floatValue,
          })
        }
      />
      <MaskedInput
        label='Valor do lote adicional'
        mascara='dinheiro'
        value={form.valorDoLoteAdicional}
        onValueChange={(e) =>
          setForm({
            ...form,
            valorDoLoteAdicional: e.floatValue,
          })
        }
      />
      <Input
        label='Júros'
        value={form.juros}
        onChange={(e) => setForm({ ...form, juros: e.target.value })}
      />
      <Input
        label='Investidor líder'
        value={form.investidorLider}
        onChange={(e) => setForm({ ...form, investidorLider: e.target.value })}
      />
      <Input
        label='Rentabilidade alvo'
        value={form.rentabilidadeAlvo}
        onChange={(e) => setForm({ ...form, rentabilidadeAlvo: e.target.value })}
      />
      <Select
        required
        label='Onde fica no informe de rendimentos'
        value={form.ondeFicaNoInformeDeRendimentos}
        options={[
          { label: 'Mútuo', value: 'Mutuo' },
          { label: 'Participações', value: 'Participacoes' },
        ]}
        onChange={(e) =>
          setForm({
            ...form,
            ondeFicaNoInformeDeRendimentos: e.target.value as OndeFicaNoInformeDeRendimentosEnum,
          })
        }
      />
      <Input
        required
        label='Nome da empresa no informe de rendimentos'
        value={form.nomeDaEmpresaNoInformeDeRendimentos}
        onChange={(e) => setForm({ ...form, nomeDaEmpresaNoInformeDeRendimentos: e.target.value })}
      />
      <MaskedInput
        required
        mascara='cnpj'
        label='CNPJ da empresa no informe de rendimentos'
        value={form.cnpjDaEmpresaNoInformeDeRendimentos}
        onValueChange={(e) => setForm({ ...form, cnpjDaEmpresaNoInformeDeRendimentos: e.value })}
        error={
          form.cnpjDaEmpresaNoInformeDeRendimentos
            ? !cnpjEstaValido(form.cnpjDaEmpresaNoInformeDeRendimentos)
            : false
        }
        helperText={
          form.cnpjDaEmpresaNoInformeDeRendimentos &&
          !cnpjEstaValido(form.cnpjDaEmpresaNoInformeDeRendimentos) &&
          'CNPJ inválido'
        }
      />
      <Input
        label='Nome da sócio ostensiva'
        value={form.nomeDaSocioOstensiva}
        onChange={(e) => setForm({ ...form, nomeDaSocioOstensiva: e.target.value })}
      />
      <MaskedInput
        mascara='cnpj'
        label='CNPJ da sócio ostensiva'
        value={form.cnpjDaSocioOstensiva}
        onValueChange={(e) => setForm({ ...form, cnpjDaSocioOstensiva: e.value })}
        error={form.cnpjDaSocioOstensiva ? !cnpjEstaValido(form.cnpjDaSocioOstensiva) : false}
        helperText={
          form.cnpjDaSocioOstensiva && !cnpjEstaValido(form.cnpjDaSocioOstensiva) && 'CNPJ inválido'
        }
      />
      <MaskedInput
        mascara='ano'
        label='Ano de transição pra sócio ostensiva'
        value={form.anoDeTransicaoDaSocioOstensiva}
        onValueChange={(e) => setForm({ ...form, anoDeTransicaoDaSocioOstensiva: e.floatValue })}
      />
      <MaskedInput
        mascara='ano'
        label='Ano de saída pra BEE4'
        value={form.anoDeSaidaPraBee4}
        onValueChange={(e) => setForm({ ...form, anoDeSaidaPraBee4: e.floatValue })}
      />
      <Input
        label='ID do termo de acordo do contrato de investimento'
        value={form.idDoTermoDeAcordoContratoDeInvestimento}
        onChange={(e) =>
          setForm({ ...form, idDoTermoDeAcordoContratoDeInvestimento: e.target.value })
        }
      />
      <Input
        required
        label='Nome empresarial da companhia'
        value={form.nomeEmpresarialDaCompanhia}
        onChange={(e) => setForm({ ...form, nomeEmpresarialDaCompanhia: e.target.value })}
      />
      <MaskedInput
        label='Data da deliberação de emissão das ações'
        mascara='data'
        value={form.dataDaDeliberacaoDeEmissaoDasAcoes}
        onValueChange={(e) =>
          setForm({
            ...form,
            dataDaDeliberacaoDeEmissaoDasAcoes: e.formattedValue,
          })
        }
      />
      <MaskedInput
        required
        label='Valor por ação'
        mascara='dinheiro'
        value={form.valorPorAcao}
        onValueChange={(e) =>
          setForm({
            ...form,
            valorPorAcao: e.floatValue,
          })
        }
      />
      <MaskedInput
        label='Porcentagem destinada ao capital social'
        mascara='percentual'
        value={form.porcentagemDestinadaAoCapitalSocial}
        onValueChange={(e) =>
          setForm({
            ...form,
            porcentagemDestinadaAoCapitalSocial: e.floatValue,
          })
        }
      />
      <MaskedInput
        label='Porcentagem destinada para formação de reservas'
        mascara='percentual'
        value={form.porcentagemDestinadaParaFormacaoDeReservas}
        onValueChange={(e) =>
          setForm({
            ...form,
            porcentagemDestinadaParaFormacaoDeReservas: e.floatValue,
          })
        }
      />
      <Select
        required
        label='Espécie de ações'
        value={form.especieDeAcoes}
        options={[
          {
            label: 'Ações ordinárias',
            value: 'Ações ordinárias',
          },
          {
            label: 'Ações preferenciais',
            value: 'Ações preferenciais',
          },
        ]}
        onChange={(e) =>
          setForm({
            ...form,
            especieDeAcoes: e.target.value as string,
          })
        }
      />
      <Input
        label='Deliberação de emissão das ações'
        value={form.deliberacaoDeEmissaoDasAcoes}
        onChange={(e) => setForm({ ...form, deliberacaoDeEmissaoDasAcoes: e.target.value })}
      />
      <Input
        required
        label='Cidade da empresa para assinatura do contrato'
        value={form.cidadeDaEmpresaParaAssinaturaDoContrato}
        onChange={(e) =>
          setForm({ ...form, cidadeDaEmpresaParaAssinaturaDoContrato: e.target.value })
        }
      />
      <Input
        required
        label='UF da empresa para assinatura do contrato'
        value={form.unidadeFederativaDaEmpresaParaAssinaturaDoContrato}
        onChange={(e) => {
          const valor = e.target.value?.replace(/[0-9]/g, '').slice(0, 2).toUpperCase()
          setForm({
            ...form,
            unidadeFederativaDaEmpresaParaAssinaturaDoContrato: valor,
          })
        }}
      />
      <MaskedInput
        required
        label='Quantidade mínima de ações para comprar'
        mascara='inteiro'
        value={form.quantidadeMinimaDeAcoesParaComprar}
        onValueChange={(e) =>
          setForm({
            ...form,
            quantidadeMinimaDeAcoesParaComprar: e.floatValue,
          })
        }
      />
      <MaskedInput
        required
        label='Quantidade de ações para venda'
        mascara='inteiro'
        value={form.quantidadeAcoesParaVenda}
        onValueChange={(e) =>
          setForm({
            ...form,
            quantidadeAcoesParaVenda: e.floatValue,
          })
        }
      />
      <MaskedInput
        required
        label='Quantidade total de ações da empresa'
        mascara='inteiro'
        value={form.quantidadeTotalDeAcoesDaEmpresa}
        onValueChange={(e) =>
          setForm({
            ...form,
            quantidadeTotalDeAcoesDaEmpresa: e.floatValue,
          })
        }
      />
      <MaskedInput
        label='Quantidade de ações de outras rodadas'
        mascara='inteiro'
        value={form.quantidadeDeAcoesDeOutrasRodadas}
        onValueChange={(e) =>
          setForm({
            ...form,
            quantidadeDeAcoesDeOutrasRodadas: e.floatValue,
          })
        }
      />
      <Select
        required
        label='Tipo societário'
        value={form.tipoSocietario}
        options={[
          {
            label: 'LTDA',
            value: 'LTDA',
          },
          {
            label: 'SA',
            value: 'SA',
          },
        ]}
        onChange={(e) =>
          setForm({
            ...form,
            tipoSocietario: e.target.value as TipoSocietarioEnum,
          })
        }
      />
      <Input
        required
        label='Endereço da companhia'
        value={form.enderecoCompanhia}
        onChange={(e) => setForm({ ...form, enderecoCompanhia: e.target.value })}
      />
      <MaskedInput
        required
        label='CEP da companhia'
        mascara='cep'
        value={form.cepCompanhia}
        onValueChange={(e) =>
          setForm({
            ...form,
            cepCompanhia: e.value,
          })
        }
      />
    </>
  )
}
