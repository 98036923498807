import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { obterInvestidorAsync } from '../api/obterInvestidorAsync'
import { editarInvestidorAsync } from '../api/editarInvestidorAsync'
import { InvestidorDTO } from '../types/InvestidorDTO'
import { criarJsonMergePatch } from '../utils/criarJsonMergePatch'

export const useInvestidor = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['investidor', investidorId]

  const options = {
    retry: false,
    enabled: !!investidorId,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterInvestidorAsync(investidorId)
  }

  const response = useQuery(queryKey, getAsync, options)

  const editarAsync = async (form: InvestidorDTO) => {
    if (!response.data) {
      return
    }
    if (!investidorId) {
      return
    }
    const patch = criarJsonMergePatch(response.data, form)
    delete patch.id
    await editarInvestidorAsync(investidorId, patch)
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    mutation: mutation,
  }
}
