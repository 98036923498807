import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editarCampanhaAsync } from '../api/editarCampanhaAsync'
import { obterCampanhaAsync } from '../api/obterCampanhaAsync'
import { criarCampanhaAsync } from '../api/criarCampanhaAsync'
import { EditarCampanhaRequest } from '../types/EditarCampanhaRequest'

type Props = {
  slug?: string
}

export const useCampanha = (request?: Props) => {
  const queryClient = useQueryClient()

  const slug = request?.slug ?? ''

  const queryKey = ['campanha', slug]

  const options = {
    enabled: !!slug,
  }

  async function getAsync() {
    const response = await obterCampanhaAsync(slug)
    return response
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync, options)

  async function editarAsync(request: EditarCampanhaRequest) {
    await editarCampanhaAsync(slug, request)
  }

  const mutationParaEditar = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  const mutationParaCriar = useMutation(criarCampanhaAsync)

  return {
    isLoading,
    isError,
    data,
    mutationParaEditar,
    mutationParaCriar,
  }
}
