import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editarStatusDoCadastroDoInvestidorAsync } from '../api/editarStatusDoCadastroDoInvestidorAsync'
import { obterStatusDoCadastroDoInvestidorAsync } from '../api/obterStatusDoCadastroDoInvestidorAsync'

export const useStatusDoCadastroDoInvestidor = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['status-do-cadastro', investidorId]

  const response = useQuery(
    queryKey,
    async () => {
      if (!investidorId) {
        return null
      }
      return await obterStatusDoCadastroDoInvestidorAsync(investidorId)
    },
    {
      enabled: !!investidorId,
    },
  )

  async function editarAsync({ status }: { status: string }) {
    if (!investidorId) {
      return
    }
    await editarStatusDoCadastroDoInvestidorAsync({ investidorId, status: status })
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    mutation: mutation,
  }
}
