import { ObterCampanhaResponse } from '../../types/ObterCampanhaResponse'
import { Tooltip } from '../Tooltip'
import { Asteristico, Barra, Item, Titulo, Valor } from './styles'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import { formatarData } from '../../@beegin/react/utils/formatarData'
import { formatarPercentual } from '../../@beegin/react/utils/formatarPercentual'

type BarraDeInformacoesDaCampanhaProps = {
  campanha: ObterCampanhaResponse
}

export function BarraDeInformacoesDaCampanha({ campanha }: BarraDeInformacoesDaCampanhaProps) {
  if (!campanha) {
    return null
  }

  const campanhaDeTipoAcoes = campanha.tipo?.toLowerCase() === 'ação'

  const itensDaBarraDeCaptacao = [
    {
      chave: campanhaDeTipoAcoes ? 'Preço da ação' : 'Preço do ativo',
      valor: formatarDinheiro(campanha.valorPorAcao ?? 0),
    },
    {
      chave: 'Investimento mínimo',
      valor: formatarDinheiro(campanha.investimentoMinimo ?? 0),
    },
    {
      chave: campanhaDeTipoAcoes ? 'Qtd mínima de ações' : 'Qtd mínima de ativos',
      valor: formatarDinheiro(campanha.quantidadeMinimaDeAcoesParaComprar ?? 0, 0, 'decimal'),
    },
    {
      chave: campanhaDeTipoAcoes
        ? 'Qtd de ações a serem emitidas'
        : 'Qtd de ativos a serem emitidos',
      valor: formatarDinheiro(campanha.quantidadeAcoesParaVenda ?? 0, 0, 'decimal'),
    },
    {
      chave: campanhaDeTipoAcoes
        ? 'Qtd de ações atual da empresa'
        : 'Qtd de ativos atual da empresa',
      valor: formatarDinheiro(campanha.quantidadeTotalDeAcoesDaEmpresa ?? 0, 0, 'decimal'),
      tooltip: `Este número representa a quantidade atual de ${
        campanhaDeTipoAcoes ? 'ações' : 'ativos'
      } da empresa. Lembre-se que para saber o total final após o término do IPO, você deverá somar este número a nova quantidade de ${
        campanhaDeTipoAcoes ? 'ações' : 'ativos'
      } emitida pela Oferta.`,
    },
    {
      chave: 'Capital social à venda',
      valor: formatarPercentual(
        ((campanha.quantidadeAcoesParaVenda ?? 0) /
          ((campanha.quantidadeTotalDeAcoesDaEmpresa ?? 0) +
            (campanha.quantidadeAcoesParaVenda ?? 0) +
            (campanha.quantidadeDeAcoesDeOutrasRodadas ?? 0))) *
          100,
      ),
    },
    {
      chave: 'Captação mínima',
      valor: formatarDinheiro(campanha.valorDeCaptacaoMinimo ?? 0),
    },
    {
      chave: 'Captação máxima',
      valor: formatarDinheiro(campanha.valorDeCaptacaoMaximo ?? 0),
    },
    {
      chave: 'Valuation pre-money',
      valor: formatarDinheiro(campanha.valuationPreMoney ?? 0),
    },
    {
      chave: 'Data de abertura',
      valor: campanha.dataDeInicio ? formatarData(new Date(campanha.dataDeInicio)) : '',
    },
    {
      chave: 'Previsão de encerramento',
      valor: campanha.dataDeFechamento ? formatarData(new Date(campanha.dataDeFechamento)) : '',
    },
  ]

  return (
    <Barra grid={6}>
      {itensDaBarraDeCaptacao.map((valor) => {
        if (!valor.chave) {
          return null
        }
        if (!valor.valor) {
          return null
        }
        return (
          <Item key={valor.chave}>
            <Valor>{valor.valor}</Valor>
            <Tooltip texto={valor.tooltip ?? ''}>
              <Titulo>
                {valor.tooltip && <Asteristico>*</Asteristico>}
                {valor.chave}
              </Titulo>
            </Tooltip>
          </Item>
        )
      })}
    </Barra>
  )
}
