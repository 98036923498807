import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Tabela } from '../../components/Tabela'
import { useEffect, useState } from 'react'
import { useCessaoDePosicao } from '../../store/cessaoDePosicao'
import { Link } from '../../components/link'
import { filtrarTodosAtributos } from '../../utils/filtro'
import { Input } from '../../@beegin/react/components/Input'
import { Button } from '../../@beegin/react/components/Button'
import { columns } from './columns'
import { Main, Title, BlocoBranco } from '../listarAssessores/styles'

type Cessao = {
  campanhaId: number
  compradorId: string
  contrato: string
  dataDaCessao: string
  documentoDoComprador: string
  documentoDoVendedor: string
  id: number
  nomeDaCampanha: string
  nomeDoComprador: string
  nomeDoVendedor: string
  valor: number
  vendedorId: string
}

export function CessoesDePosicao() {
  const [busca, setBusca] = useState('')

  const { data, isLoading } = useCessaoDePosicao({})

  const dadosFiltrados = filtrarTodosAtributos(data ?? [], busca)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [currentPageData, setCurrentPageData] = useState<Cessao[]>([])

  useEffect(() => {
    const start = page * size
    const end = start + size
    setCurrentPageData(dadosFiltrados.slice(start, end))
  }, [page, size, busca, dadosFiltrados])

  return (
    <Menu>
      <Main>
        <Title>Cessões de posição</Title>
        <BlocoBranco>
          <PilhaDeItens direction='row'>
            <Input
              fullWidth
              label='Pesquisa'
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
            />
            <Link to={'/cessoes-de-posicao/criar'}>
              <Button size='small' variant='contained'>
                Nova cessao
              </Button>
            </Link>
          </PilhaDeItens>
          <br />
          <Esqueleto height='600px' active={isLoading}>
            <Tabela
              rows={currentPageData}
              columns={columns}
              page={page}
              pageRowsSize={size}
              onPageChange={(page: number) => setPage(page)}
              onPageSizeChange={(pageSize: number) => setSize(pageSize)}
            />
          </Esqueleto>
        </BlocoBranco>
      </Main>
    </Menu>
  )
}
