import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Checkbox } from '../../@beegin/react/components/Checkbox'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Radio } from '../../@beegin/react/components/Radio'
import { TipoPerfilDeInvestidor } from '../../types/TipoPerfilDeInvestidor'
import { obterErrorResponse } from '../../utils/obterErrorResponse'
import { Spacing } from '../Spacing'
import { Text } from '../../@beegin/react/components/Text'
import { Detalhes } from './styles'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import { useInvestimentosDoInvestidor } from '../../store/investimentosDoInvestidor'
import { useInvestidor } from '../../store/investidor'
import { PerfilDeInvestidor } from '../../types/InvestidorDTO'
import { useValidation } from '../../hooks/useValidation'
import { Button } from '../../@beegin/react/components/Button'
import { Flex } from '../Flex'
import { useAuth } from '../../hooks/auth'

interface PerfilDeInvestidorProps {
  investidorId: string
  nomeDaOferta: string
}

const MensagemDeValorJaInvestido = ({
  quantoJaInvestiuEsseAno,
  quantoJaReservouEsseAno,
}: {
  quantoJaInvestiuEsseAno: number
  quantoJaReservouEsseAno: number
}) => {
  let mensagem = 'Lembrando que na Beegin, '

  if (quantoJaInvestiuEsseAno === 0 && quantoJaReservouEsseAno === 0) {
    mensagem += 'você ainda não realizou investimentos no ano-calendário.'
    return <Detalhes>{mensagem}</Detalhes>
  }

  const reservado = formatarDinheiro(quantoJaReservouEsseAno)
  const investido = formatarDinheiro(quantoJaInvestiuEsseAno)

  if (quantoJaInvestiuEsseAno > 0 && quantoJaReservouEsseAno > 0) {
    mensagem += `você já investiu ${investido} no ano-calendário e tem ${reservado} em reserva.`
  } else if (quantoJaInvestiuEsseAno === 0) {
    mensagem += `você tem ${reservado} em reserva.`
  } else if (quantoJaReservouEsseAno === 0) {
    mensagem += `você já investiu ${investido} no ano-calendário.`
  }
  return <Detalhes>{mensagem}</Detalhes>
}

const MensagemDeValorMaximoParaInvestir = ({
  tipoDePerfil,
  nomeDaCampanha,
  valorJaInvestidoNesseAno,
}: {
  tipoDePerfil: TipoPerfilDeInvestidor
  nomeDaCampanha: string
  valorJaInvestidoNesseAno: number
}) => {
  if (tipoDePerfil === 'Baixo') {
    return (
      <Detalhes>
        O valor do meu investimento na oferta {nomeDaCampanha}, quando somado ao valor de{' '}
        {formatarDinheiro(valorJaInvestidoNesseAno)} que já investi no ano-calendário em ofertas
        dispensadas de registro na Comissão de Valores Mobiliários – CVM por meio de plataformas
        eletrônicas investimento participativo (crowdfunding de investimento), não ultrapassa R$
        20.000,00 (vinte mil reais).
      </Detalhes>
    )
  }
  if (tipoDePerfil === 'Medio') {
    return (
      <Detalhes>
        O valor do meu investimento na oferta {nomeDaCampanha}, quando somado ao valor de{' '}
        {formatarDinheiro(valorJaInvestidoNesseAno)} que já investi no ano-calendário em ofertas
        dispensadas de registro na Comissão de Valores Mobiliários – CVM por meio de plataformas
        eletrônicas investimento participativo (crowdfunding de investimento), não ultrapassa 10%
        (dez por cento) do maior entre: (a) minha renda bruta anual; ou (b) o montante total de meus
        investimentos financeiros.
      </Detalhes>
    )
  }
  if (tipoDePerfil === 'Qualificado') {
    return (
      <>
        <Detalhes>
          Como investidor qualificado, atesto ser capaz de entender e ponderar os riscos financeiros
          relacionados à aplicação de meus recursos em oferta pública de distribuição de valores
          mobiliários de emissão de sociedades empresárias de pequeno porte, realizada com dispensa
          de registro na Comissão de Valores Mobiliários - CVM, por meio de plataforma eletrônica de
          investimento participativo.
        </Detalhes>
        <Detalhes>
          Declaro, sob as penas da lei, que possuo investimentos financeiros em valor superior a R$
          1.000.000,00 (um milhão de reais)
        </Detalhes>
      </>
    )
  }
  return <></>
}

const TituloComDescricao = () => {
  return (
    <>
      <Text fontWeight='400' fontSize='18px' lineHeight='130%' color='#36393f' margin='0 0 32px 0'>
        De acordo com a{' '}
        <Text type='span' color='#3975e4' fontWeight='bold'>
          <a
            href='https://conteudo.cvm.gov.br/legislacao/resolucoes/resol088.html'
            target='_blank'
            rel='noreferrer'
          >
            Resolução CVM 88
          </a>
        </Text>
        , há um limite de investimento para cada perfil de investidor no ano-calendário. Selecione a
        opção que mais se adequa ao seu perfil que indicaremos o máximo que poderá investir.
      </Text>
    </>
  )
}

export function FormularioDePerfilDeInvestidor({
  investidorId,
  nomeDaOferta,
}: PerfilDeInvestidorProps) {
  const { isInAnyOfTheseRoles } = useAuth()

  const toastId = 'qwert'

  const { data: perfilDeInvestidor, isLoading, mutation } = useInvestidor(investidorId)

  const { data: investimentos } = useInvestimentosDoInvestidor({
    investidorId,
    ano: new Date().getFullYear(),
  })

  const quantoJaInvestiuNoAnoCalendario = investimentos?.quantoJaInvestiuNoAnoCalendario ?? 0
  const quantoJaReservouNoAnoCalendario = investimentos?.quantoJaReservouNoAnoCalendario ?? 0

  const [form, setForm] = useState({
    tipoDePerfilDeInvestidor: undefined,
    maiorValorEntreRendaBrutaAnualOuInvestimentos: 0,
    jaInvestiuEmOutrasPlataformas: false,
    valorQueInvestiuEmOutrasPlataformasNoAnoAtual: 0,
    declaroQueIreiAtualizarMinhasInformacoesNaBeegin: false,
  } as PerfilDeInvestidor)

  const { errors, validateAsync } = useValidation<PerfilDeInvestidor>({
    model: form,
    mutationError: mutation.error,
  })

  useEffect(() => {
    if (!investidorId) {
      return
    }
    if (!perfilDeInvestidor) {
      return
    }

    setForm({
      ...perfilDeInvestidor.perfilDeInvestidor,
      jaInvestiuEmOutrasPlataformas: !perfilDeInvestidor.perfilDeInvestidor
        ?.jaInvestiuEmOutrasPlataformas
        ? false
        : true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investidorId, perfilDeInvestidor])

  async function onSubmit(): Promise<void> {
    toast.loading('Atualizando perfil de investidor...', {
      toastId,
    })
    const { isValid } = await validateAsync()
    if (!isValid) {
      toast.error('Há um erro no formulário.')
      return
    }
    await mutation.mutateAsync({ perfilDeInvestidor: form })
  }

  useEffect(() => {
    if (mutation.isError) {
      toast.update(toastId, {
        render: obterErrorResponse(mutation.error, 'Erro ao atualizar perfil.'),
        type: 'error',
        isLoading: false,
        autoClose: 1000,
      })
    }
    if (mutation.isSuccess) {
      toast.update(toastId, {
        render: 'Perfil atualizado com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 1000,
      })
    }
  }, [mutation.isError, mutation.error, mutation.isSuccess])

  if (!form || isLoading) {
    return (
      <PilhaDeItens>
        <TituloComDescricao />
        <Esqueleto width='100%' height='40px' />
        <Esqueleto width='100%' height='40px' />
        <Esqueleto width='100%' height='40px' />
        <Esqueleto width='100%' height='60px' />
        <Esqueleto width='50%' height='40px' />
        <Esqueleto width='50%' height='20px' />
      </PilhaDeItens>
    )
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  return (
    <div>
      <TituloComDescricao />
      <Spacing margin='0 0 24px 0'>
        <Radio
          disabled={desabilitarInputs}
          checked={form.tipoDePerfilDeInvestidor === 'Baixo'}
          onChange={() => setForm({ ...form, tipoDePerfilDeInvestidor: 'Baixo' })}
          label='Possuo renda bruta anual ou investimentos financeiros até R$ 200.000,00 (duzentos mil reais).'
          value='Baixo'
        />
      </Spacing>
      <Spacing margin='0 0 24px 0'>
        <Radio
          disabled={desabilitarInputs}
          checked={form.tipoDePerfilDeInvestidor === 'Medio'}
          onChange={() => setForm({ ...form, tipoDePerfilDeInvestidor: 'Medio' })}
          label='Possuo renda bruta anual ou investimentos financeiros superior a R$ 200.000,00 (duzentos mil reais).'
          value='Medio'
        />
        {form.tipoDePerfilDeInvestidor === 'Medio' && (
          <Spacing margin='16px 0 16px 0'>
            <MaskedInput
              disabled={desabilitarInputs}
              sx={{ maxWidth: '400px' }}
              fullWidth
              mascara='dinheiro'
              error={
                form.maiorValorEntreRendaBrutaAnualOuInvestimentos
                  ? form.maiorValorEntreRendaBrutaAnualOuInvestimentos < 200000 ||
                    form.maiorValorEntreRendaBrutaAnualOuInvestimentos > 1000000
                  : false
              }
              helperText='O valor deve ser entre R$200.000 e R$1.000.000.'
              label='Maior valor entre renda bruta anual e investimentos financeiros'
              value={form.maiorValorEntreRendaBrutaAnualOuInvestimentos}
              onValueChange={(e) =>
                setForm({
                  ...form,
                  maiorValorEntreRendaBrutaAnualOuInvestimentos: e.floatValue ?? 0,
                })
              }
            />
          </Spacing>
        )}
      </Spacing>
      <Spacing margin='0 0 24px 0'>
        <Radio
          disabled={desabilitarInputs}
          checked={form.tipoDePerfilDeInvestidor === 'Qualificado'}
          onChange={() => setForm({ ...form, tipoDePerfilDeInvestidor: 'Qualificado' })}
          label='Possuo investimentos financeiros superiores a R$1.000.000 (um milhão de reais), sendo um investidor qualificado. Logo, não tenho limites de investimento.'
          value='Qualificado'
        />
      </Spacing>
      <Spacing margin='0 0 32px 0'>
        <Checkbox
          disabled={desabilitarInputs}
          checked={form.jaInvestiuEmOutrasPlataformas === true}
          onChange={() =>
            setForm({
              ...form,
              jaInvestiuEmOutrasPlataformas: !form.jaInvestiuEmOutrasPlataformas,
            })
          }
          label='Já investi em Oferta Pública de Distribuição de Valores Mobiliários de Emissão de Sociedade Empresária de Pequeno Porte Realizada com dispensa de registro via plataforma eletrônica de investimento participativo neste ano-calendário. '
        />
        {form.jaInvestiuEmOutrasPlataformas && (
          <Spacing margin='32px 0 0 0'>
            <MaskedInput
              disabled={desabilitarInputs}
              sx={{ maxWidth: '400px' }}
              fullWidth
              mascara='dinheiro'
              label='Valor investido em outras plataformas no ano atual'
              value={form.valorQueInvestiuEmOutrasPlataformasNoAnoAtual}
              onValueChange={(e) =>
                setForm({
                  ...form,
                  valorQueInvestiuEmOutrasPlataformasNoAnoAtual: e.floatValue ?? 0,
                })
              }
            />
          </Spacing>
        )}
      </Spacing>
      <MensagemDeValorMaximoParaInvestir
        tipoDePerfil={form.tipoDePerfilDeInvestidor as TipoPerfilDeInvestidor}
        nomeDaCampanha={nomeDaOferta}
        valorJaInvestidoNesseAno={quantoJaInvestiuNoAnoCalendario ?? 0}
      />
      <MensagemDeValorJaInvestido
        quantoJaInvestiuEsseAno={quantoJaInvestiuNoAnoCalendario ?? 0}
        quantoJaReservouEsseAno={quantoJaReservouNoAnoCalendario ?? 0}
      />
      <Checkbox
        disabled={desabilitarInputs}
        error={errors['declaroQueIreiAtualizarMinhasInformacoesNaBeegin']}
        checked={form.declaroQueIreiAtualizarMinhasInformacoesNaBeegin === true}
        onChange={() =>
          setForm({
            ...form,
            declaroQueIreiAtualizarMinhasInformacoesNaBeegin:
              !form.declaroQueIreiAtualizarMinhasInformacoesNaBeegin,
          })
        }
        label='Declaro que atualizarei minhas informações na Beegin e caso venha realizar mais investimentos em outras plataformas de crowdfunding no ano-calendário.'
      />
      <Flex justifyContent='flex-end' margin='4px 0 0 0'>
        <Button
          type='button'
          onClick={onSubmit}
          carregando={mutation.isLoading}
          disabled={desabilitarBotao}
        >
          Salvar
        </Button>
      </Flex>
    </div>
  )
}
