export function filtrarTodosAtributos(dados: any[], pesquisa: string) {
  if (!pesquisa) {
    return dados
  }

  if (!dados) {
    return []
  }

  if (dados.length === 0) {
    return dados
  }

  const keys = Object.keys(dados[0])

  return dados.filter((item) =>
    keys.some((k) => {
      if (!item[k]) {
        return false
      }

      try {
        if (item[k].toString().toLowerCase().includes(pesquisa.toLowerCase())) {
          return true
        }
      } catch {
        return false
      }

      return false
    }),
  )
}
