import { parseISO } from 'date-fns'
import format from 'date-fns/format'

export const formatarData = (
  data: number | Date,
  formato = 'dd/MM/yyyy',
): string => {
  try {
    if (data instanceof Date) {
      return format(data, formato)
    } else {
      return format(parseISO(data.toString()), formato)
    }
  } catch (err) {
    console.error('Erro ao formatar data.', err)
    return data.toString()
  }
}

export const transformarDataFormatadaEmTimestamp = (data?: string): string => {
  if (!data) {
    return ''
  }

  if (data.length !== 10) {
    return ''
  }

  try {
    const dia = Number(data.substring(0, 2))
    const mes = Number(data.substring(3, 5))
    const ano = Number(data.substring(6, 10))

    const date = new Date(ano, mes - 1, dia)

    return formatarData(date, 'yyyy-MM-dd')
  } catch (err) {
    return data
  }
}

export const transformarTimestampEmDataFormatada = (data?: string): string => {
  if (!data) {
    return ''
  }

  if (data.length !== 10) {
    return ''
  }

  try {
    const dia = Number(data.substring(0, 2))
    const mes = Number(data.substring(3, 5))
    const ano = Number(data.substring(6, 10))

    const date = new Date(ano, mes - 1, dia)

    return formatarData(date, 'dd/MM/yyyy')
  } catch (err) {
    return data
  }
}
