import { ObterInvestidoresRequestQuery } from '../types/obterInvestidoresRequestQuery'
import { ObterInvestidoresResponse } from '../types/obterInvestidoresResponse'
import { investidoresApi } from '../services/api'

export const obterInvestidoresAsync = async ({
  page = 0,
  size = 10,
  statusDoCadastro = [],
  escritorioId,
  assessorId,
  cpf,
  cnpj,
  id,
  nome,
  email,
}: ObterInvestidoresRequestQuery) => {
  let query = new URLSearchParams()
  query.append('page', page.toString())
  query.append('size', size.toString())

  if (escritorioId) {
    query.append('escritorio-id', escritorioId)
  }
  if (assessorId) {
    query.append('assessor-id', assessorId)
  }
  if (cpf) {
    query.append('cpf', cpf)
  }
  if (cnpj) {
    query.append('cnpj', cnpj)
  }
  if (id) {
    query.append('id', id)
  }
  if (nome) {
    query.append('nome', nome)
  }
  if (email) {
    query.append('email', email)
  }

  statusDoCadastro.forEach((item) => {
    query.append('status', item.toString())
  })

  const response = await investidoresApi.get<ObterInvestidoresResponse>(
    `/investidores?${query.toString()}`,
  )

  return response.data.investidores
}
