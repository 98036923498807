import { investidoresApi } from '../services/api'

type Response = {
  tipo: 'PF' | 'PJ'
}

export async function obterTipoDeInvestidorAsync(investidorId: string): Promise<Response> {
  const response = await investidoresApi.get<Response>(`/investidores/${investidorId}/tipo`)
  return response?.data
}
