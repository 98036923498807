import { IterableEnum } from '../types/IterableEnum'

export const tipoDeTelefone: IterableEnum = {
  celular: {
    title: 'Celular',
    asNumber: 0,
    asString: 'CELULAR',
  },
  comercial: {
    title: 'Comercial',
    asNumber: 1,
    asString: 'COMERCIAL',
  },
  recados: {
    title: 'Recados',
    asNumber: 2,
    asString: 'RECADOS',
  },
  residencial: {
    title: 'Residencial',
    asNumber: 3,
    asString: 'RESIDENCIAL',
  },
  fixo: {
    title: 'Fixo',
    asNumber: 4,
    asString: 'FIXO',
  },
}
