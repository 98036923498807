import { useParams } from 'react-router-dom'
import { Button } from '../../@beegin/react/components/Button'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Menu } from '../../components/menu'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Input } from '../../@beegin/react/components/Input'
import { FormContainer } from '../../components/FormContainer'
import { Select } from '../../@beegin/react/components/Select'
import { useInvestimento } from '../../store/investimento'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'

type Form = {
  status: string
}

export function EditarInvestimento() {
  const [form, setForm] = useState<Form>({
    status: '',
  })

  let { investimentoId } = useParams()

  const { data, isLoading, editarStatusMutation } = useInvestimento(investimentoId)

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!investimentoId) {
      toast.error('Erro ao obter id do investimento.')
      return
    }
    await editarStatusMutation.mutateAsync({ ...form, investimentoId: investimentoId })
  }

  const iniciarFormulario = useCallback(async () => {
    if (!investimentoId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ status: data.status ?? '' })
  }, [data, investimentoId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  useMutationToast({
    mutation: editarStatusMutation,
    sucesso: 'Investimento editado!',
    erro: 'Erro ao editar investimento.',
    carregando: 'Carregando...',
  })

  const desabilitarInputs = editarStatusMutation.isLoading

  return (
    <Menu>
      <FormContainer>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to='/investimentos'>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Editar investimento</h1>
        <Esqueleto height='80px' active={isLoading}>
          <form onSubmit={onSubmit}>
            <PilhaDeItens gap={3} margin='0 0 32px 0'>
              <Input disabled label='ID' value={investimentoId} />
              <Select
                required
                disabled={desabilitarInputs}
                label='Status'
                value={form.status}
                onChange={(e) => setForm({ ...form, status: e.target.value as string })}
                options={[
                  { label: 'Intenção', value: 'Intencao' },
                  { label: 'Realizado', value: 'Realizado' },
                  { label: 'Cancelado', value: 'Cancelado' },
                  { label: 'A Reembolsar', value: 'Reembolsar' },
                  { label: 'Reembolsado', value: 'Reembolsado' },
                ]}
              />
            </PilhaDeItens>
            <Button type='submit' carregando={desabilitarInputs}>
              Salvar
            </Button>
          </form>
        </Esqueleto>
      </FormContainer>
    </Menu>
  )
}
