import { createTheme } from '@mui/material/styles';

export const temaDoMaterialUi = createTheme({
  palette: {
    primary: {
      main: '#060B7A',
    },
    secondary: {
      main: '#0852dd',
    },
  },
    typography: {
    button: {
      fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      textTransform: 'none'
    }
  }
});
