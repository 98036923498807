import { IterableEnum } from '../types/IterableEnum'

export const tiposDeDocumento: IterableEnum = {
  CNH: {
    asNumber: 1,
    title: 'CNH',
    asString: 'CNH',
  },
  ComprovanteDeEndereco: {
    asNumber: 2,
    title: 'Comprovante de endereço',
    asString: 'ComprovanteDeEndereco',
  },
  Procuracao: {
    asNumber: 3,
    title: 'Procuração',
    asString: 'Procuracao',
  },
  CartaoCNPJ: {
    asNumber: 4,
    title: 'Cartão CNPJ',
    asString: 'CartaoCNPJ',
  },
  ContratoSocialOuEstatutoSocial: {
    asNumber: 5,
    title: 'Contrato social ou estatuto social',
    asString: 'ContratoSocialOuEstatutoSocial',
  },
  DocumentoDeProtocolo: {
    asNumber: 7,
    title: 'Documento de protocolo',
    asString: 'DocumentoDeProtocolo',
  },
  RG_Frente: {
    asNumber: 8,
    title: 'RG (frente)',
    asString: 'RG_Frente',
  },
  RG_Verso: {
    asNumber: 9,
    title: 'RG (verso)',
    asString: 'RG_Verso',
  },
  DocumentoDeIdentificacao_Outro: {
    asNumber: 10,
    title: 'Documento de identificação (outro)',
    asString: 'DocumentoDeIdentificacao_Outro',
  },
}
