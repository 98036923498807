import { useMutation } from '@tanstack/react-query'
import { recuperarSenhaDoUsuarioAsync } from '../api/recuperarSenhaDoUsuarioAsync'
import { RecuperarSenhaDoUsuarioRequest } from '../types/RecuperarSenhaDoUsuarioRequest'
import { editarSenhaDoUsuarioAsync } from '../api/editarSenhaDoUsuarioAsync'
import { EditarSenhaDoUsuarioRequest } from '../types/EditarSenhaDoUsuarioRequest'

export const useUsuarioSenha = (usuarioId: string | undefined) => {
  async function editarAsync(request: EditarSenhaDoUsuarioRequest) {
    if (!usuarioId) {
      return
    }
    await editarSenhaDoUsuarioAsync(request)
  }
  const mutationParaEditarSenha = useMutation(editarAsync)

  async function recuperarAsync(request: RecuperarSenhaDoUsuarioRequest) {
    if (!usuarioId) {
      return
    }
    await recuperarSenhaDoUsuarioAsync(request)
  }
  const mutationParaRecuperarSenha = useMutation(recuperarAsync)

  return {
    mutationParaEditarSenha: mutationParaEditarSenha,
    mutationParaRecuperarSenha: mutationParaRecuperarSenha,
  }
}
