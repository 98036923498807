import styled from 'styled-components'
import { Button as MaterialButton } from '../../@beegin/react/components/Button'

export const Container = styled.div`
  margin: -24px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  background-color: #ffffff;
  border-bottom: 1px solid #eff0f1;
  align-items: center;
`
export const ElementosDaEsquerda = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  a {
    display: flex;
    align-items: center;
  }
`

export const Button = styled(MaterialButton)`
  background-color: #2571ff !important;
  height: 44px;
  width: 199px;
  border-radius: 4px;
  // font-family: Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  box-shadow: none !important;
`

export const Info = styled.div``
export const Nome = styled.h1`
  margin: 0 0 9px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  text-align: left;
`
export const CnpjStatus = styled.div`
  display: flex;
  gap: 24px;
  color: #969aa1;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`
export const Cnpj = styled.span``
export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const StatusColor = styled.div`
  border-radius: 50%;
  background-color: #2db350;
  height: 12px;
  width: 12px;
`

export const Main = styled.div`
  background-color: #f5f5f5;
  padding: 32px;
  flex: 1;
`

export const Section = styled.div`
  border-radius: 8px;
  padding: 24px;
  background-color: #ffffff;
  margin-bottom: 32px;
`
export const LinhaDeBlocos = styled.div`
  display: flex;
  gap: 16px;
`
export const BlocoInfo = styled(Section)`
  background-color: #2571ff;
  color: #ffffff;
  height: 195px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
`
export const TituloBloco = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const ValorBloco = styled.span`
  font-size: 64px;
  font-weight: 700;
  line-height: 83px;
`
export const ItensAbaixoDoTitulo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 36px;
  // margin-top: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
`

export const ItensDaDireita = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const ItensDaEsquerda = styled(ItensDaDireita)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const ItemAbaixoDoTitulo = styled.div`
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const TituloDoItemAbaixoDoTitulo = styled.p`
  // font-family: Source Sans Pro;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.15px;
  color: #5b616a;
  margin: 0;

  @media (max-width: 768px) {
    line-height: 115%;
    letter-spacing: -0.01em;
    max-width: 50px;
  }
`

interface IValorDoItemAbaixoProps {
  currency?: boolean
  cents?: string
}
export const ValorDoItemAbaixoDoTitulo = styled.p<IValorDoItemAbaixoProps>`
  // font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 24px;
  line-height: 31.2px;
  margin: 0;
  margin-top: 8px;
  color: #131b30;
  display: flex;

  ${({ currency }) => currency && '&:before { content: "R$"}'}

  ${({ cents }) => cents && `&:after { content: '${cents}'}`}

  @media (max-width: 768px) {
    // font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: center;

    &:before {
      // font-family: Inter;
      color: #5b616a;
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: -0.03em;
      text-align: center;
      position: relative;
      top: 0;
    }

    &:after {
      // font-family: Inter;
      color: #5b616a;
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: -0.03em;
      text-align: center;
      position: relative;
      top: 0;
      left: 2px;
    }
  }
`

export const HeaderGrafico = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const TituloGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  align-items: end;
  display: flex;
  gap: 16px;
  color: #212427;
`

export const SubtituloGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`
export const DataGrafico = styled.span`
  // font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: #636873;
`
