import MuiRadio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

type RadioProps = {
  label: string
  value: any
  checked: boolean
  onChange: () => void
  disabled?: boolean
}

export function Radio({ checked, value, label, onChange, disabled = false }: RadioProps) {
  return (
    <FormControlLabel
      disabled={disabled}
      checked={checked}
      value={value}
      control={<MuiRadio />}
      label={label}
      onChange={onChange}
    />
  )
}
