import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { obterContaBancariaSpbDoInvestidorAsync } from '../api/obterContaBancariaSpbDoInvestidorAsync'
import { AtualizarContaBancariaSpbRequest } from '../types/AtualizarContaBancariaSpbRequest'
import { editarContaBancariaSpbDoInvestidorAsync } from '../api/editarContaBancariaSpbDoInvestidorAsync'

export const useContaBancariaSpb = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['conta-bancaria-spb', investidorId]

  const options = {
    retry: false,
    enabled: !!investidorId,
  }

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterContaBancariaSpbDoInvestidorAsync(investidorId)
  }

  const response = useQuery(queryKey, getAsync, options)

  const editarAsync = async (request: AtualizarContaBancariaSpbRequest) => {
    if (!investidorId) {
      return
    }
    await editarContaBancariaSpbDoInvestidorAsync(investidorId, request)
  }

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => await queryClient.invalidateQueries(queryKey),
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
    mutation: mutation,
  }
}
