import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { EditarInformacoesDeNegociacaoDaCampanhaRequest } from '../types/EditarInformacoesDeNegociacaoDaCampanhaRequest'
import { editarInformacoesDeNegociacaoDaCampanhaAsync } from '../api/editarInformacoesDeNegociacaoDaCampanhaAsync'
import { obterInformacoesDeNegociacaoDaCampanhaAsync } from '../api/obterInformacoesDeNegociacaoDaCampanhaAsync'

type Props = {
  slug?: string
}

export const useInformacoesDeNegociacaoDaCampanha = (request?: Props) => {
  const queryClient = useQueryClient()

  const slug = request?.slug ?? ''

  const queryKey = ['informacoes-de-negociacao', slug]

  const options = {
    enabled: !!slug,
  }

  async function getAsync() {
    const response = await obterInformacoesDeNegociacaoDaCampanhaAsync(slug)
    return response
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync, options)

  async function editarAsync(request: EditarInformacoesDeNegociacaoDaCampanhaRequest) {
    await editarInformacoesDeNegociacaoDaCampanhaAsync(slug, request)
  }

  const mutationParaEditar = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data,
    mutationParaEditar,
  }
}
