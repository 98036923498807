import { Menu } from '../../components/menu'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Link } from '../../components/link'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Button } from '../../@beegin/react/components/Button'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useParams } from 'react-router-dom'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { MaskedInput } from '../../@beegin/react/components/Input'
import { EditarInformacoesDeNegociacaoDaCampanhaRequest } from '../../types/EditarInformacoesDeNegociacaoDaCampanhaRequest'
import { useInformacoesDeNegociacaoDaCampanha } from '../../store/informacoesDeNegociacaoDaCampanha'
import { formatarData, transformarDataFormatadaEmTimestamp } from '../../utils/formatarData'

export function EditarCampanhaInformacoesDeNegociacao() {
  const [form, setForm] = useState<EditarInformacoesDeNegociacaoDaCampanhaRequest>({
    ultimaVariacaoDoPregao: 0,
    precoDoUltimoNegocio: 0,
    volumeNegociado: 0,
    dataDaUltimaAtualizacao: '',
  })

  let { slug } = useParams()

  const { data, mutationParaEditar, isLoading } = useInformacoesDeNegociacaoDaCampanha({ slug })

  useMutationToast({
    mutation: mutationParaEditar,
    sucesso: 'Campanha editada com sucesso!',
    erro: 'Erro ao editar campanha.',
    carregando: 'Carregando...',
  })

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!form.ultimaVariacaoDoPregao) {
      return
    }
    await mutationParaEditar.mutateAsync({
      ...form,
      dataDaUltimaAtualizacao: transformarDataFormatadaEmTimestamp(
        form.dataDaUltimaAtualizacao ?? '',
      ),
      ultimaVariacaoDoPregao: form.ultimaVariacaoDoPregao / 100,
    })
  }

  const iniciarFormulario = useCallback(async () => {
    if (!slug) {
      return
    }
    if (!data) {
      return
    }
    setForm({
      ...data,
      dataDaUltimaAtualizacao: data.dataDaUltimaAtualizacao
        ? formatarData(new Date(data.dataDaUltimaAtualizacao))
        : '',
      ultimaVariacaoDoPregao: !data.ultimaVariacaoDoPregao ? 0 : data.ultimaVariacaoDoPregao * 100,
    })
  }, [data, slug])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  const header = (): JSX.Element => {
    return (
      <>
        <PilhaDeItens direction='row' mobileDirection='row'>
          <Link to={`/campanhas/${slug}/editar`}>
            <Button variant='outlined'>👈 Voltar</Button>
          </Link>
        </PilhaDeItens>
        <h1>Editar informações de negociação da campanha</h1>
      </>
    )
  }

  if (isLoading || !data) {
    return (
      <Menu>
        {header()}
        <PilhaDeItens gap={3} maxWidth='600px'>
          <Esqueleto width='100%' height='40px' />
          <Esqueleto width='100%' height='40px' />
          <Esqueleto width='100%' height='40px' />
        </PilhaDeItens>
      </Menu>
    )
  }

  return (
    <Menu>
      {header()}
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} maxWidth='600px'>
          <MaskedInput
            required
            label='Última variação do pregão'
            mascara='percentual'
            value={form.ultimaVariacaoDoPregao}
            onValueChange={(e) =>
              setForm({
                ...form,
                ultimaVariacaoDoPregao: e.floatValue,
              })
            }
          />
          <MaskedInput
            required
            label='Preço do último negócio'
            mascara='dinheiro'
            value={form.precoDoUltimoNegocio}
            onValueChange={(e) =>
              setForm({
                ...form,
                precoDoUltimoNegocio: e.floatValue,
              })
            }
          />
          <MaskedInput
            required
            label='Volume negociado'
            mascara='dinheiro'
            value={form.volumeNegociado}
            onValueChange={(e) =>
              setForm({
                ...form,
                volumeNegociado: e.floatValue,
              })
            }
          />
          <MaskedInput
            required
            label='Data da última atualização'
            mascara='data'
            value={form.dataDaUltimaAtualizacao}
            onValueChange={(e) =>
              setForm({
                ...form,
                dataDaUltimaAtualizacao: e.formattedValue,
              })
            }
          />
          <div>
            <Button variant='contained' type='submit' disabled={mutationParaEditar.isLoading}>
              Salvar
            </Button>
          </div>
        </PilhaDeItens>
      </form>
    </Menu>
  )
}
