import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MaterialSelect, {
  SelectProps as MaterialSelectProps,
} from '@mui/material/Select'
import { styles } from './styles'
import { Input } from '../Input'

type SelectProps = MaterialSelectProps & {
  autocomplete?: boolean
  label: string
  helperText?: string
  value: any
  options: {
    value: any
    label: any
  }[]
}

export function Select({ helperText, options, label, value, ...props }: SelectProps) {
  return (
    <FormControl
      required={props.required}
      sx={{ minWidth: 0, width: '100%', ...styles }}
    >
      <InputLabel>{label}</InputLabel>
      <MaterialSelect label={label} value={value ?? ''} {...props} variant="outlined">
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MaterialSelect>
      {helperText && helperText.length > 0 && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

type SelectWithAutocompleteProps = Omit<SelectProps, 'onChange'> & {
  // eslint-disable-next-line
  onChange: (novoValor: string) => void
}

const autoCompleteStyle = {
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}

export function SelectComAutocomplete({
  helperText,
  options,
  value,
  label,
  disabled,
  required,
  onChange,
}: SelectWithAutocompleteProps) {
  return (
    <FormControl sx={{ minWidth: 0, width: '100%', ...styles }}>
      <Autocomplete
        sx={autoCompleteStyle}
        getOptionLabel={(option: any) => {
          if (!option) {
            return ''
          }
          const item = options.find((x) => x.value === option)
          if (!item) {
            console.error(`Opção ${option} não encontrada.`)
            return ''
          }
          return item.label
        }}
        disabled={disabled}
        disablePortal
        options={options.map((x) => x.value)}
        value={value ?? ''}
        onChange={(_, novoValor) => onChange(novoValor)}
        renderInput={(params) => (
          <Input
            {...params}
            required={required}
            disabled={disabled}
            label={label}
          />
        )}
      />
      {helperText && helperText.length > 0 && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
