import { requestClient } from './beeginRequestClient'

type Request = {
  investidorId: string
  tipo: string
  arquivo: File
}

export async function editarDocumentoDoInvestidorAsync(request: Request): Promise<void> {
  const url = `/investidores/${request.investidorId}/documentos?documentotipo=${request.tipo}`

  const formData = new FormData()
  formData.append('arquivo', request.arquivo)

  await requestClient.apis.investidores.put(url, formData)
}
