import * as Yup from 'yup'
import { DadosPessoais } from '../../types/InvestidorDTO'
import { Schema, object } from 'yup'
import { SexoEnum } from '../../types/enums/SexoEnum'

const mensagemDeValorMaiorQueZero = 'O valor precisa ser maior que zero.'

export const validator: Schema<DadosPessoais> = object().shape({
  nome: Yup.string().required('O nome é obrigatório.'),
  valorPatrimonial: Yup.number()
    .moreThan(0, mensagemDeValorMaiorQueZero)
    .required('Preenchimento obrigatório.'),
  rendaAnual: Yup.number()
    .moreThan(0, mensagemDeValorMaiorQueZero)
    .required('Preenchimento obrigatório.'),
  tipoDocumento: Yup.string().required('Campo obrigatório'),
  sexo: Yup.mixed<SexoEnum>().required('Campo obrigatório'),
})
