import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Tabela } from '../../components/Tabela'
import { columns } from './columns'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { useParams } from 'react-router-dom'
import { useCampanha } from '../../store/campanha'
import { toast } from 'react-toastify'
import { useMutationToast } from '../../hooks/useMutationToast'
import { useState, useEffect } from 'react'
import { useAtualizacaoParaInvestidores } from '../../store/atualizacaoParaInvestidores'

type Atualizacao = {
  id: string
  titulo: string
  conteudo: string
  createdAt: string
}

export function ListarAtualizacoesParaInvestidores() {
  const [itemSelecionado, setItemSelecionado] = useState<number | null>(null)
  let { slug } = useParams()

  const campanha = useCampanha({ slug })

  const { data, isLoading, mutationParaDeletar } = useAtualizacaoParaInvestidores({
    campanhaId: campanha.data?.id,
  })

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [currentPageData, setCurrentPageData] = useState<Atualizacao[] | undefined>([])

  useEffect(() => {
    const start = page * size
    const end = start + size
    setCurrentPageData(data?.atualizacoes.slice(start, end))
  }, [page, size, data])

  useMutationToast({
    mutation: mutationParaDeletar,
    sucesso: 'Item deletado com sucesso!',
    erro: 'Erro ao deletar item.',
    carregando: 'Carregando...',
  })

  async function deletarItem(id: number) {
    if (!campanha.data?.id) {
      toast.error('Erro ao obter id do item.')
      return
    }
    await mutationParaDeletar.mutateAsync({
      campanhaId: campanha.data?.id,
      id: id,
    })
  }

  function selecionarItem(id: number) {
    setItemSelecionado(id)
    toast.info('Clique novamente se deseja deletar.')
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to={`/campanhas/${slug}/editar`}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
        <Link to={`/campanhas/${slug}/atualizacoes-para-investidores/criar`}>
          <Button variant='text'>Criar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Atualizações para investidores</h1>
      <br />
      <Esqueleto width='100%' height='600px' active={isLoading}>
        <Tabela
          rows={currentPageData ?? []}
          columns={columns({
            callbackParaDeletar: deletarItem,
            callbackParaSelecionar: selecionarItem,
            itemSelecionado: itemSelecionado,
          })}
          page={page}
          pageRowsSize={size}
          onPageChange={(page: number) => setPage(page)}
          onPageSizeChange={(pageSize: number) => setSize(pageSize)}
        />
      </Esqueleto>
    </Menu>
  )
}
