import { requestClient } from './beeginRequestClient'
import { ObterInformesDeRendimentosResponse } from '../types/obterInformesDeRendimentosResponse'

interface Props {
  page?: number
  size?: number
  cnpj?: string
  cpf?: string
  nome?: string
}

export const listarInformesDeRendimentosAsync = async ({
  page = 0,
  size = 100,
  cnpj,
  cpf,
  nome,
}: Props): Promise<ObterInformesDeRendimentosResponse> => {
  let query = new URLSearchParams()
  query.append('page', page.toString())
  query.append('size', size.toString())

  if (cnpj) {
    query.append('cnpj', cnpj)
  }

  if (cpf) {
    query.append('cpf', cpf)
  }

  if (nome) {
    query.append('nome', nome)
  }

  const response =
    await requestClient.apis.informeDeRendimentos.get<ObterInformesDeRendimentosResponse>(
      `/api/informes-de-rendimentos?${query.toString()}`,
    )

  return response.data
}
