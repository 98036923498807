import { requestClient } from './beeginRequestClient'

type Response = {
  atualizacoes: {
    id: string
    titulo: string
    conteudo: string
    createdAt: string
  }[]
}

export async function listarAtualizacoesParaInvestidoresAsync(
  campanhaId: number,
): Promise<Response> {
  const response = await requestClient.apis.campanhaApi.get<Response>(
    `/campanhas/${campanhaId}/atualizacoes-para-investidores`,
  )
  return response.data
}
