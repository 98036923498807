import { api } from '../services/api'

interface ListarEscritoriosDeAssessorResponse {
  escritorios: {
    id: string
    nome: string
  }[]
}

export async function listarEscritoriosDeAssessorAsync(): Promise<ListarEscritoriosDeAssessorResponse> {
  const response = await api.get<ListarEscritoriosDeAssessorResponse>(
    '/api/v3.0/backoffice/escritorios-de-assessor',
  )
  return response?.data
}
