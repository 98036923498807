import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Button } from '../../@beegin/react/components/Button'
import { useStatusDoCadastroDoInvestidor } from '../../store/statusDoCadastroDoInvestidor'
import { useMutationToast } from '../../hooks/useMutationToast'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Select } from '../../@beegin/react/components/Select'
import { toast } from 'react-toastify'

type Props = {
  investidorId?: string
}

type Form = {
  status: string
}

export function FormularioDeStatusDoCadastroParaAdministrador({
  investidorId,
}: Props): JSX.Element {
  const [form, setForm] = useState<Form>({
    status: '',
  })

  const { data, mutation } = useStatusDoCadastroDoInvestidor(investidorId)

  useMutationToast({
    mutation: mutation,
    sucesso: 'Status atualizado com sucesso!',
    erro: 'Erro ao atualizar os dados.',
    carregando: 'Carregando...',
  })

  const iniciarFormulario = useCallback(async () => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({ status: data.status })
  }, [data, investidorId])

  useEffect(() => {
    iniciarFormulario()
  }, [iniciarFormulario])

  async function onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()
    if (!form.status) {
      toast.error('Selecione um status.')
      return
    }
    await mutation.mutateAsync(form)
  }

  const desabilitarForm = mutation.isLoading
  const desabilitarBotao = mutation.isLoading

  return (
    <>
      <form onSubmit={onSubmit}>
        <PilhaDeItens gap={3} margin='0 0 32px 0'>
          <Select
            required
            label='Status'
            value={form.status ?? ''}
            options={[
              { value: '', label: '' },
              { value: 'Nenhum', label: 'Nenhum' },
              { value: 'CadastroIniciado', label: 'CadastroIniciado' },
              { value: 'CadastroCompleto', label: 'CadastroCompleto' },
              { value: 'EmAnalise', label: 'EmAnalise' },
              { value: 'Aprovado', label: 'Aprovado' },
              { value: 'Revisao', label: 'Revisao' },
              { value: 'Erro', label: 'Erro' },
              { value: 'Reprovado', label: 'Reprovado' },
            ]}
            onChange={(e: any) => setForm({ ...form, status: e.target.value as string })}
          />
        </PilhaDeItens>
        <Button type='submit' carregando={desabilitarForm} disabled={desabilitarBotao}>
          Salvar
        </Button>
      </form>
    </>
  )
}
