import { formatarData } from '../../utils/formatarData'
import { formatarDinheiro } from '../../@beegin/react/utils/formatarDinheiro'
import LaunchIcon from '@mui/icons-material/Launch'
import { Link } from '../../components/link'
import { Flex } from '../../components/Flex'

export const columns = [
  {
    id: 'id',
    header: '',
    maxSize: 20,
    cell: (props: any) => {
      return (
        <Link to={`/filas-de-espera/${props.getValue()}/editar`}>
          <Flex alignItems='center' gap='4px'>
            <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
          </Flex>
        </Link>
      )
    },
  },
  {
    accessorKey: 'id',
    header: 'ID',
    size: 40,
  },
  {
    header: 'Campanha',
    accessorKey: 'nomeDaCampanha',
  },
  {
    header: 'Investidor',
    accessorKey: 'nomeDoInvestidor',
    cell: (props: any) => {
      return (
        <Link to={`/investidores/${props.row.original.investidorId}?voltar=/filas-de-espera`}>
          <Flex alignItems='center' gap='4px'>
            <LaunchIcon color='primary' cursor='pointer' fontSize='small' />
            <p>{props.getValue()}</p>
          </Flex>
        </Link>
      )
    },
  },
  {
    header: 'Nome do Assessor',
    accessorKey: 'nomeDoAssessor',
  },
  {
    header: 'Valor',
    accessorKey: 'valor',
    cell: (props: any) => formatarDinheiro(props.getValue()),
  },
  {
    header: 'Status',
    accessorKey: 'status',
  },
  {
    header: 'Data de criação',
    accessorKey: 'dataDeCriacao',
    cell: (props: any) => formatarData(new Date(props.getValue()), 'dd/MM/yyyy HH:mm:ss'),
  },
]
