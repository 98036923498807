import { investimentosApi } from '../services/api'

type Request = {
  investimentoId: string
  status: string
}

export async function editarStatusDoInvestimentoAsync(request: Request) {
  await investimentosApi.put(`/investimentos/${request.investimentoId}/status`, {
    status: request.status,
  })
}
