import React, { useEffect } from 'react'
import { Router } from './router'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './store/queryClient'
import { AuthProvider } from './hooks/auth'
import { ThemeProvider } from '@mui/material/styles'
import { GlobalStyle } from './utils/globalStyle'
import { ToastContainer } from 'react-toastify'
import { configurarRequestClient } from './configuration/requestClientConfig'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './configuration/applicationInsights'
import { temaDoMaterialUi } from './utils/temaDoMaterialUi'
import 'react-toastify/dist/ReactToastify.css'
import './styles/toastify.css'

function App() {
  useEffect(() => {
    configurarRequestClient()
  }, [])

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AuthProvider>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={temaDoMaterialUi}>
            <ToastContainer />
            <Router />
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </AppInsightsContext.Provider>
  )
}

export default App
