import { requestClient } from './beeginRequestClient'

type Request = {
  campanhaId: number
  relatorioId: number
}

export async function deletarRelatorioDeCampanhaAsync({
  campanhaId,
  relatorioId,
}: Request): Promise<void> {
  await requestClient.apis.campanhaApi.delete(`/campanhas/${campanhaId}/relatorios/${relatorioId}`)
}
