import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { criarRelatorioDeCampanhaAsync } from '../api/criarRelatorioDeCampanhaAsync'
import { deletarRelatorioDeCampanhaAsync } from '../api/deletarRelatorioDeCampanhaAsync'
import { listarRelatoriosDeCampanhaAsync } from '../api/listarRelatoriosDeCampanhaAsync'

type Props = {
  campanhaId?: number
}

export const useRelatorioDeCampanha = (request?: Props) => {
  const queryClient = useQueryClient()

  const campanhaId = request?.campanhaId

  const queryKey = ['relatorio-de-campanha', campanhaId]

  const options = {
    enabled: !!campanhaId,
  }

  async function getAsync() {
    if (!campanhaId) {
      return null
    }
    const response = await listarRelatoriosDeCampanhaAsync(campanhaId)
    return response
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync, options)

  const mutationParaDeletar = useMutation(deletarRelatorioDeCampanhaAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  const mutationParaCriar = useMutation(criarRelatorioDeCampanhaAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data,
    mutationParaDeletar,
    mutationParaCriar,
  }
}
