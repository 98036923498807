import { useQuery } from '@tanstack/react-query'
import { obterEstatisticasAssessoresAsync } from '../api/obterEstatisticasAssessoresAsync'

export const useEstatisticasAssessores = () => {
  const getAsync = async () => {
    return await obterEstatisticasAssessoresAsync()
  }

  const { isLoading, isError, data } = useQuery(['estatisticas-assessores'], getAsync)

  return {
    isLoading,
    isError,
    data,
  }
}
