import { Menu } from '../../components/menu'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Tabela as Table } from '../../components/Tabela'
import { useHistoricoDoPerfilDoInvestidor } from '../../store/historicoDoPerfilDoInvestidor'
import { useParams } from 'react-router-dom'
import { Link } from '../../components/link'
import { Button } from '../../@beegin/react/components/Button'
import { columns } from './columns'
import { useEffect, useState } from 'react'

export function ListarHistoricoDoPerfilDoInvestidor() {
  let { investidorId } = useParams()

  const {
    isLoading,
    isError,
    data: historicoDePerfis,
  } = useHistoricoDoPerfilDoInvestidor(investidorId)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [currentPageData, setCurrentPageData] = useState<any[] | undefined>([])

  useEffect(() => {
    const start = page * size
    const end = start + size
    setCurrentPageData(historicoDePerfis?.slice(start, end))
  }, [page, size, historicoDePerfis])

  const Tabela = () => {
    if (!historicoDePerfis) {
      return <></>
    }
    if (isLoading) {
      return (
        <PilhaDeItens>
          <Esqueleto height='400px' />
        </PilhaDeItens>
      )
    }

    if (isError) {
      return <p>Erro.</p>
    }

    return (
      <Table
        columns={columns}
        rows={currentPageData ?? []}
        page={page}
        pageRowsSize={size}
        onPageChange={(page: number) => setPage(page)}
        onPageSizeChange={(pageSize: number) => setSize(pageSize)}
      />
    )
  }

  return (
    <Menu>
      <PilhaDeItens direction='row' mobileDirection='row'>
        <Link to={`/investidores/${investidorId}`}>
          <Button variant='outlined'>👈 Voltar</Button>
        </Link>
      </PilhaDeItens>
      <h1>Histórico do perfil do investidor</h1>
      <Esqueleto height='600px' active={isLoading}>
        <Tabela />
      </Esqueleto>
    </Menu>
  )
}
