import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { obterInvestidorNaVisaoDoBackofficeAsync } from '../api/obterInvestidorNaVisaoDoBackofficeAsync'
import { editarInvestidorNaVisaoDoBackofficeAsync } from '../api/editarInvestidorNaVisaoDoBackofficeAsync'

interface Mutation {
  origem: string
  assessorId: string
  statusBackoffice: number | null
  observacoesDoBackoffice: string
}

export const useInvestidorBackoffice = (investidorId: string | undefined) => {
  const queryClient = useQueryClient()

  const queryKey = ['investidor-backoffice', investidorId]

  const getAsync = async () => {
    if (!investidorId) {
      return null
    }
    return await obterInvestidorNaVisaoDoBackofficeAsync(investidorId)
  }

  const editarAsync = async (form: Mutation) => {
    if (!investidorId) {
      return []
    }
    await editarInvestidorNaVisaoDoBackofficeAsync(investidorId, form)
  }

  const { isLoading, isError, data } = useQuery(queryKey, getAsync)

  const mutation = useMutation(editarAsync, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    isLoading,
    isError,
    data: data,
    mutation,
  }
}
