import { useQuery } from '@tanstack/react-query'
import { obterSimulacaoDeResultadoDoSuitabilityAsync } from '../api/obterSimulacaoDeResultadoDoSuitabilityAsync'
import { ObterSimulacaoDeResultadoDoSuitabilityRequest } from '../types/ObterSimulacaoDeResultadoDoSuitabilityRequest'

export const useSimulacaoDoSuitability = (
  request: ObterSimulacaoDeResultadoDoSuitabilityRequest | undefined,
) => {
  const queryKey = ['simulacao-do-suitability', { ...request }]

  const options = {
    retry: false,
    enabled: !!request,
  }

  const getAsync = async () => {
    if (!request) {
      return
    }
    if (!request.perguntasComRespostas) {
      return null
    }
    if (request.perguntasComRespostas.length === 0) {
      return null
    }
    return await obterSimulacaoDeResultadoDoSuitabilityAsync(request)
  }

  const response = useQuery(queryKey, getAsync, options)

  return {
    data: response.data,
    isLoading: response.isLoading,
    isError: response.isError,
  }
}
