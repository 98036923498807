import { requestClient } from './beeginRequestClient'

type Request = {
  investidorId: string
  protocoloNaAdvice: string
}

export async function editarProtocoloDeInvestidorPessoaFisicaNaAdviceAsync(request: Request) {
  await requestClient.apis.adviceApi.post(
    '/api/protocolo-de-investidor-na-advice/pessoa-fisica',
    request,
  )
}
