import React, { createContext, useState, useContext, useEffect } from 'react'
import jwt from 'jsonwebtoken'
import { authenticationManager } from '../services/authenticationManager'
import { PaginaInteiraCarregando } from '../components/paginaInteiraCarregando'
import { Permissao } from '../constants/permissoes'

interface IToken {
  acr: string
  aud: string
  auth_time: number
  email: string
  email_confirmed: boolean
  exp: number
  family_name: string
  given_name: string
  iat: number
  idp: string
  isRefreshToken: boolean
  iss: string
  name: string
  nbf: number
  new_user: boolean
  nonce: string
  sub: string
  user_groups: string
  enterpriseId: string
  nome_completo: string
  roles: string[]
}

interface AuthState {
  token: string
  userId: string
  enterpriseId: string
  currentUserName: string
  roles: Permissao[]
}

interface AuthContextData {
  userId: string
  currentEnterpriseId: string
  currentUserName: string
  refreshToken(): Promise<void>
  logOut(): Promise<void>
  isInRole(role: Permissao): boolean
  isInAnyOfTheseRoles(role: Permissao[]): boolean
  token: string
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState('')
  const [data, setData] = useState<AuthState>({} as AuthState)

  async function refreshToken() {
    try {
      await authenticationManager.refreshToken()

      const token = await authenticationManager.getTokenAsync()
      if (!token) {
        return
      }

      const decoded = jwt.decode(token) as IToken | null
      if (!decoded) {
        return
      }

      setData({
        token: token,
        userId: decoded.sub,
        enterpriseId: decoded.enterpriseId,
        currentUserName: decoded.nome_completo ? decoded.nome_completo : decoded.name,
        roles: decoded.roles,
      } as AuthState)
    } catch (err) {
      console.error('[auth.refreshToken] Erro ao fazer refresh token.', err)
      await authenticationManager.signIn()
    }
  }

  async function logOut() {
    setData({} as AuthState)
    await authenticationManager.logOut()
  }

  function isInRole(role: Permissao): boolean {
    return data.roles.includes(role)
  }

  function isInAnyOfTheseRoles(role: Permissao[]): boolean {
    if (!data.roles) {
      console.error('Erro: data.roles não definido')
      return false
    }
    return data.roles?.filter((value) => role.includes(value)).length > 0
  }

  useEffect(() => {
    let retry = 0
    async function init() {
      retry++
      if (retry > 5) {
        setError('Erro ao fazer login. Verifique o console.')
        return
      }

      const token = await authenticationManager.getTokenAsync()
      if (!token) {
        init()
        return
      }

      const decoded = jwt.decode(token) as IToken | null
      if (!decoded) {
        return
      }

      setData({
        token: token,
        userId: decoded.sub,
        enterpriseId: decoded.enterpriseId,
        currentUserName: decoded.nome_completo,
        roles: decoded.roles,
      } as AuthState)
    }
    init()
  }, [])

  if (error) {
    return <p>{error}</p>
  }

  if (!data.userId) {
    return <PaginaInteiraCarregando />
  }

  return (
    <AuthContext.Provider
      value={{
        userId: data.userId,
        currentEnterpriseId: data.enterpriseId,
        currentUserName: data.currentUserName,
        refreshToken,
        logOut,
        isInRole,
        isInAnyOfTheseRoles,
        token: data.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  return context
}
