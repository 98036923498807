import { Input } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { useInvestidorSituacaoNaAdvice } from '../../store/investidorSituacaoNaAdvice'
import { defaultGap, defaultMaxWidth } from '../../utils/constants'

interface Props {
  investidorId: string
}

export function SituacaoNaAdvice({ investidorId }: Props) {
  const { data, isError, isLoading } = useInvestidorSituacaoNaAdvice(investidorId)

  if (isLoading) {
    return (
      <PilhaDeItens maxWidth={defaultMaxWidth}>
        <Esqueleto />
      </PilhaDeItens>
    )
  }

  if (isError) {
    return <p>Erro.</p>
  }

  return (
    <PilhaDeItens gap={defaultGap} maxWidth={defaultMaxWidth}>
      <Input
        label='Risco'
        value={`${data?.riscoNaAdviceComoInt} - ${data?.riscoNaAdvice}`}
        disabled
      />
    </PilhaDeItens>
  )
}
