import styled from 'styled-components'

export const Main = styled.div`
  background-color: #f5f5f5;
  margin: -24px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  padding: 24px 16px 16px 16px;
`
export const Title = styled.h1`
  // font-family: Ubuntu;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  margin: 0 0 40px 24px;
`

export const BlocoBranco = styled.div`
  border-radius: 8px;
  padding: 24px;
  background-color: white;
`
