import { useQuery } from '@tanstack/react-query'
import { ListarUsuariosRequest } from '../types/ListarUsuariosRequest'
import { listarUsuariosAsync } from '../api/listarUsuariosAsync'

export const useUsuarios = (request: ListarUsuariosRequest) => {
  const queryKey = ['usuarios', request]

  const response = useQuery(queryKey, async () => {
    return await listarUsuariosAsync(request)
  })

  return {
    data: response.data,
    isLoading: response.isLoading,
  }
}
