import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { useDocumentosDoInvestidor } from '../../store/documentosDoInvestidor'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { Text } from '../../@beegin/react/components/Text'
import { tiposDeDocumento } from '../../enums/tiposDeDocumento'
import { useRef, useState } from 'react'
import { useMutationToast } from '../../hooks/useMutationToast'
import { toast } from 'react-toastify'
import { Button } from '../../@beegin/react/components/Button'
import { Select } from '../../@beegin/react/components/Select'
import { Flex } from '../Flex'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeDocumentosDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const [tipoDeDocumentoEscolhido, setTipoDeDocumentoEscolhido] = useState('')
  const [arquivo, setArquivo] = useState<File | null>(null)

  const inputRef = useRef(null)

  const { data, isError, isLoading, editarDocumentoMutation, deletarDocumentoMutation } =
    useDocumentosDoInvestidor(investidorId)

  useMutationToast({
    mutation: editarDocumentoMutation,
    sucesso: 'Documento atualizado com sucesso!',
    erro: 'Erro ao atualizar documento.',
  })

  useMutationToast({
    mutation: deletarDocumentoMutation,
    sucesso: 'Documento deletado com sucesso!',
    erro: 'Erro ao deletar documento.',
    carregando: 'Deletando documento...',
  })

  const obterNomeBonitoDoDocumento = (nomeDoArquivo?: string, tipo?: string) => {
    if (!nomeDoArquivo) {
      return ''
    }
    if (!tipo) {
      return nomeDoArquivo
    }
    const tipoDesseDocumento = Object.values(tiposDeDocumento).find((x) => x.asString === tipo)
    return tipoDesseDocumento?.title ?? nomeDoArquivo
  }

  const escolherArquivo = () => {
    if (!inputRef.current) {
      return
    }
    // @ts-ignore
    inputRef.current.click()
  }

  const deletarDocumento = (documentoId?: string) => {
    if (!documentoId) {
      return
    }
    deletarDocumentoMutation.mutateAsync(documentoId)
  }

  const enviarDocumento = async () => {
    if (!arquivo) {
      return
    }
    try {
      await editarDocumentoMutation.mutateAsync({
        arquivo: arquivo,
        tipo: tipoDeDocumentoEscolhido,
      })
    } finally {
      setArquivo(null)
      setTipoDeDocumentoEscolhido('')
    }
  }

  if (isError) {
    return <p>Erro.</p>
  }

  if (
    !data ||
    isLoading ||
    editarDocumentoMutation.isLoading ||
    deletarDocumentoMutation.isLoading
  ) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='240px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  const desabilitarInputs = !isInAnyOfTheseRoles([
    'Investidores.ReadWrite',
    'Administrador',
    'Invest.Admin',
  ])

  const desabilitarBotao =
    editarDocumentoMutation.isLoading ||
    !arquivo ||
    !tipoDeDocumentoEscolhido ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  return (
    <>
      <PilhaDeItens gap={3} margin='0 0 32px 0'>
        {data?.documentos?.length === 0 ? (
          <Text>Nenhum documento foi enviado.</Text>
        ) : (
          data?.documentos?.map((documento) => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }} key={documento.id}>
              <Text fontSize='16px' lineHeight='24px' color='#383D47'>
                {obterNomeBonitoDoDocumento(documento.nomeDoArquivo, documento.tipo)}
              </Text>
              <div>
                <DeleteIcon
                  color={desabilitarInputs ? 'disabled' : 'secondary'}
                  sx={{ marginRight: '8px', cursor: 'pointer' }}
                  onClick={() => deletarDocumento(documento.id)}
                />
                <a target='_blank' href={documento.url} rel='noreferrer'>
                  <CloudDownloadIcon />
                </a>
              </div>
            </div>
          ))
        )}
      </PilhaDeItens>
      <Text fontWeight='bold' margin='0 0 16px 0'>
        Enviar novo documento 👇
      </Text>
      <Select
        label='Tipo de documento'
        value={tipoDeDocumentoEscolhido}
        onChange={(e) => setTipoDeDocumentoEscolhido(e.target.value as string)}
        options={Object.keys(tiposDeDocumento).map((x) => ({
          label: tiposDeDocumento[x].title,
          value: tiposDeDocumento[x].asString,
        }))}
      />
      <Flex margin='16px 0 32px 0' gap='8px'>
        <CloudUploadIcon
          onClick={() => escolherArquivo()}
          color='secondary'
          sx={{ cursor: 'pointer' }}
        />
        <Text onClick={() => escolherArquivo()} cursor='pointer'>
          {arquivo ? arquivo.name : 'Escolher arquivo'}
        </Text>
      </Flex>
      <Button type='button' onClick={() => enviarDocumento()} disabled={desabilitarBotao}>
        Enviar documento
      </Button>
      <input
        value={''}
        onChange={(e) => {
          if (!e.target.files) {
            return
          }
          if (e.target.files?.length === 0) {
            return
          }
          const file = e.target.files[0]
          if (file.size > 5 * 1024 * 1024) {
            toast.error('O tamanho máximo do arquivo é 5MB.')
            return
          }
          setArquivo(e.target.files[0])
        }}
        ref={inputRef}
        style={{ display: 'none' }}
        type='file'
        accept='.png, .jpeg, .jpg, .pdf'
      />
    </>
  )
}
