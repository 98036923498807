import { AxiosInstance } from 'axios'

export class BeeginRequestClient {
  private _apis: { [key: string]: AxiosInstance } = {}

  public set apis(instance: { [key: string]: AxiosInstance }) {
    this._apis = instance
  }

  public get apis() {
    if (!this._apis) {
      throw new Error('A instância do axios não pode ser nula.')
    }
    return this._apis
  }
}

export const requestClient = new BeeginRequestClient()
