import { usuariosApi } from '../services/api'
import { EditarEmailDoUsuarioRequest } from '../types/EditarEmailDoUsuarioRequest'

export async function editarEmailDoUsuarioAsync({
  usuarioId,
  emailAnterior,
  emailNovo,
}: EditarEmailDoUsuarioRequest): Promise<void> {
  await usuariosApi.put(`/usuarios/${usuarioId}/email`, {
    email: emailAnterior,
    novoEmail: emailNovo,
  })
}
