import { requestClient } from './beeginRequestClient'
import { InvestidorDTO } from '../types/InvestidorDTO'

export const editarInvestidorAsync = async (investidorId: string, request: InvestidorDTO) => {
  delete request?.dadosPessoais?.valorPatrimonialDataAtualizacao
  delete request?.dadosPessoais?.rendaAnualDataAtualizacao
  await requestClient.apis.investidores.patch(`/investidores/${investidorId}`, request, {
    headers: {
      'content-type': 'application/merge-patch+json',
    },
  })
}
