import * as React from 'react'
import Stack from '@mui/material/Stack'

interface PilhaDeItensProps {
  gap?: number
  children: any
  maxWidth?: string
  direction?: 'row' | 'column'
  mobileDirection?: 'row' | 'column'
  margin?: string
}

export function PilhaDeItens({
  gap = 1,
  children,
  maxWidth = 'none',
  direction = 'column',
  mobileDirection = 'column',
  margin,
}: PilhaDeItensProps) {
  return (
    <Stack
      spacing={gap}
      maxWidth={maxWidth}
      direction={{
        xs: mobileDirection ? mobileDirection : direction,
        sm: direction,
      }}
      sx={{
        margin: margin,
      }}
    >
      {children}
    </Stack>
  )
}
