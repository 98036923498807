import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
  height: 100vh;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
  visibility: visible;

  .loader__content {
    text-align: center;
    padding-bottom: 40px;
  }

  .loader__title {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #101728;
    margin-top: 18px;
  }

  .loader__spinner {
    display: inline-block;
    position: relative;
    width: 46px;
    height: 46px;
  }

  .loader__spinner div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #0852dd;
    border-radius: 50%;
    animation: spinner 1.2s linear infinite;
  }

  .loader__spinner div:nth-child(1) {
    animation-delay: 0s;
    top: 38px;
    left: 26px;
  }

  .loader__spinner div:nth-child(2) {
    animation-delay: -0.1s;
    top: 29px;
    left: 37px;
  }

  .loader__spinner div:nth-child(3) {
    animation-delay: -0.2s;
    top: 17px;
    left: 37px;
  }

  .loader__spinner div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
  }

  .loader__spinner div:nth-child(5) {
    animation-delay: -0.4s;
    top: 5px;
    left: 15px;
  }

  .loader__spinner div:nth-child(6) {
    animation-delay: -0.5s;
    top: 13px;
    left: 5px;
  }

  .loader__spinner div:nth-child(7) {
    animation-delay: -0.6s;
    top: 26px;
    left: 4px;
  }

  .loader__spinner div:nth-child(8) {
    animation-delay: -0.7s;
    top: 37px;
    left: 11px;
  }

  @keyframes spinner {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }
  }
`

export function PaginaInteiraCarregando() {
  return (
    <Container>
      <div className='loader__content'>
        <div className='loader__spinner'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1 className='loader__title'>Carregando...</h1>
      </div>
    </Container>
  )
}
