import { FormEvent, useEffect, useState } from 'react'
import { Button } from '../../@beegin/react/components/Button'
import { ConjuntoDeRadios } from '../../@beegin/react/components/ConjuntoDeRadios'
import { Esqueleto } from '../../@beegin/react/components/Esqueleto'
import { Input, MaskedInput } from '../../@beegin/react/components/Input'
import { PilhaDeItens } from '../../@beegin/react/components/PilhaDeItens'
import { Select, SelectComAutocomplete } from '../../@beegin/react/components/Select'
import {
  formatarData,
  transformarDataFormatadaEmTimestamp,
} from '../../@beegin/react/utils/formatarData'
import { useMutationToast } from '../../hooks/useMutationToast'
import { estadoCivil } from '../../enums/estadoCivil'
import { useInvestidor } from '../../store/investidor'
import { ocupacoesProfissionais } from '../../enums/ocupacoesProfissionais'
import { ConjuntoDeCheckboxes } from '../../@beegin/react/components/ConjuntoDeCheckboxes'
import { SexoEnum } from '../../types/enums/SexoEnum'
import { EstadoCivilEnum } from '../../types/enums/EstadoCivilEnum'
import { DadosPessoais } from '../../types/InvestidorDTO'
import { useValidation } from '../../hooks/useValidation'
import { validator } from './validator'
import { toast } from 'react-toastify'
import { getErrorMessageProps } from '../../utils/getErrorMessageProps'
import { Flex } from '../Flex'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copiarParaAreaDeTransferencia } from '../../utils/copiarParaAreaDeTransferencia'
import { useAuth } from '../../hooks/auth'

interface Props {
  investidorId: string
}

export function FormularioDeDadosPessoaisDoInvestidor({ investidorId }: Props) {
  const { isInAnyOfTheseRoles } = useAuth()

  const [form, setForm] = useState({} as DadosPessoais)

  const { data, isError, isLoading, mutation } = useInvestidor(investidorId)

  const { errors, validateAsync } = useValidation<DadosPessoais>({
    validator: validator,
    model: form,
    mutationError: mutation.error,
  })

  useMutationToast({
    mutation: mutation,
    sucesso: 'Dados atualizados com sucesso!',
    erro: 'Erro ao atualizar os dados.',
  })

  useEffect(() => {
    if (!investidorId) {
      return
    }
    if (!data) {
      return
    }
    setForm({
      ...data.dadosPessoais,
      dataNascimento: data.dadosPessoais?.dataNascimento
        ? formatarData(new Date(data.dadosPessoais?.dataNascimento))
        : '',
    })
  }, [investidorId, data])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { isValid } = await validateAsync()
    if (!isValid) {
      toast.error('Há um erro no formulário.')
      return
    }
    const dataNoFormatoCorreto =
      transformarDataFormatadaEmTimestamp(form.dataNascimento ?? '') + 'T00:00:00'
    await mutation.mutateAsync({
      dadosPessoais: {
        ...form,
        dataNascimento: dataNoFormatoCorreto,
      },
    })
  }

  function copiarValor(valor: string) {
    copiarParaAreaDeTransferencia(valor)
    toast.success('Copiado para área de transferência!')
  }

  const desabilitarInputs =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const desabilitarBotao =
    mutation.isLoading ||
    !isInAnyOfTheseRoles(['Investidores.ReadWrite', 'Administrador', 'Invest.Admin'])

  const pessoaEstaCasada = form?.estadoCivil?.toString().toLowerCase() === 'casado' ?? false

  useEffect(() => {
    if (!pessoaEstaCasada) {
      setForm((x) => ({ ...x, cpfDoConjuge: '', nomeDoConjuge: '' }))
    }
  }, [pessoaEstaCasada])

  if (isError) {
    return <p>Erro.</p>
  }

  if (!data || isLoading) {
    return (
      <PilhaDeItens gap={3}>
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='56px' />
        <Esqueleto height='36px' width='120px' />
      </PilhaDeItens>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <PilhaDeItens gap={3} margin='0 0 32px 0'>
        <Flex alignItems='center' justifyContent='space-between' gap='8px'>
          <Input label='ID' value={data.id ?? ''} fullWidth disabled />
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => copiarValor(data.id ?? '')}
          />
        </Flex>
        <Flex alignItems='center' justifyContent='space-between' gap='8px'>
          <Input label='E-mail' value={data.email ?? ''} fullWidth disabled />
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => copiarValor(data.email ?? '')}
          />
        </Flex>
        <Input
          {...getErrorMessageProps(errors, 'nome')}
          label='Nome completo'
          value={form.nome ?? ''}
          onChange={(e) => setForm({ ...form, nome: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'nomeSocial')}
          label='Nome social (opcional)'
          value={form.nomeSocial ?? ''}
          onChange={(e) => setForm({ ...form, nomeSocial: e.target.value })}
          disabled={desabilitarInputs}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'dataNascimento')}
          mascara='data'
          label='Data de nascimento'
          value={form.dataNascimento ?? ''}
          onValueChange={(e) => setForm({ ...form, dataNascimento: e.formattedValue })}
          required
          disabled={desabilitarInputs}
        />
        <ConjuntoDeRadios
          {...getErrorMessageProps(errors, 'sexo')}
          label='Gênero'
          value={form.sexo ?? ''}
          onChange={(e) => setForm({ ...form, sexo: e.target.value as SexoEnum })}
          options={[
            {
              value: 'M',
              label: 'Masculino',
            },
            {
              value: 'F',
              label: 'Feminino',
            },
            {
              value: 'N',
              label: 'Outro',
            },
          ]}
          disabled={desabilitarInputs}
        />
        <Flex alignItems='center' justifyContent='space-between' gap='8px'>
          <MaskedInput
            {...getErrorMessageProps(errors, 'cpf')}
            mascara='cpf'
            label='CPF'
            value={form.cpf ?? ''}
            fullWidth
            disabled
          />
          <ContentCopyIcon
            color='primary'
            cursor='pointer'
            onClick={() => copiarValor(form.cpf ?? '')}
          />
        </Flex>
        <ConjuntoDeRadios
          label='Tipo de documento'
          value={form.tipoDocumento ?? ''}
          onChange={(e) => setForm({ ...form, tipoDocumento: e.target.value })}
          options={[
            {
              value: 'RG',
              label: 'RG',
            },
            {
              value: 'CNH',
              label: 'CNH',
            },
          ]}
          disabled={desabilitarInputs}
          {...getErrorMessageProps(errors, 'tipoDocumento')}
        />
        <Input
          {...getErrorMessageProps(errors, 'numeroDocumento')}
          label='Número do documento'
          value={form.numeroDocumento ?? ''}
          onChange={(e) => setForm({ ...form, numeroDocumento: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'orgaoExpedidor')}
          label='Órgão expedidor'
          value={form.orgaoExpedidor ?? ''}
          onChange={(e) => setForm({ ...form, orgaoExpedidor: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'nomeMae')}
          label='Nome da mãe'
          value={form.nomeMae ?? ''}
          onChange={(e) => setForm({ ...form, nomeMae: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'nomePai')}
          label='Nome do pai (opcional)'
          value={form.nomePai ?? ''}
          onChange={(e) => setForm({ ...form, nomePai: e.target.value })}
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'nacionalidade')}
          label='Nacionalidade'
          value={form.nacionalidade ?? ''}
          onChange={(e) => setForm({ ...form, nacionalidade: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'naturalidade')}
          label='Naturalidade'
          value={form.naturalidade ?? ''}
          onChange={(e) => setForm({ ...form, naturalidade: e.target.value })}
          required
          disabled={desabilitarInputs}
        />
        <Select
          {...getErrorMessageProps(errors, 'estadoCivil')}
          label='Estado civil'
          value={form.estadoCivil ?? ''}
          onChange={(e) => setForm({ ...form, estadoCivil: e.target.value as EstadoCivilEnum })}
          options={Object.values(estadoCivil).map((item) => ({
            label: item.title,
            value: item.asString,
          }))}
          required
          disabled={desabilitarInputs}
        />
        {pessoaEstaCasada && (
          <>
            <Input
              {...getErrorMessageProps(errors, 'nomeDoConjuge')}
              required
              label='Nome do cônjuge'
              value={form.nomeDoConjuge ?? ''}
              onChange={(e) => setForm({ ...form, nomeDoConjuge: e.target.value })}
              disabled={desabilitarInputs}
            />
            <MaskedInput
              {...getErrorMessageProps(errors, 'cpfDoConjuge')}
              required
              mascara='cpf'
              label='CPF do cônjuge'
              value={form.cpfDoConjuge ?? ''}
              onValueChange={(e) => setForm({ ...form, cpfDoConjuge: e.value })}
              disabled={desabilitarInputs}
            />
          </>
        )}
        <SelectComAutocomplete
          {...getErrorMessageProps(errors, 'ocupacaoProfissional')}
          required
          label='Ocupação profissional'
          value={form.ocupacaoProfissional ?? ''}
          onChange={(valor) =>
            setForm({
              ...form,
              ocupacaoProfissional: valor as string,
            })
          }
          disabled={desabilitarInputs}
          options={[
            { value: '', label: '' },
            ...Object.keys(ocupacoesProfissionais)
              .map((item) => ({
                // @ts-ignore
                label: ocupacoesProfissionais[item],
                value: item,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          ]}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'cnpjEmpregador')}
          mascara='cnpj'
          label='CNPJ do empregador (opcional)'
          value={form.cnpjEmpregador ?? ''}
          onValueChange={(e) => setForm({ ...form, cnpjEmpregador: e.value })}
          disabled={desabilitarInputs}
        />
        <Input
          {...getErrorMessageProps(errors, 'razaoSocialEmpregador')}
          label='Razão social do empregador (opcional)'
          value={form.razaoSocialEmpregador ?? ''}
          onChange={(e) => setForm({ ...form, razaoSocialEmpregador: e.target.value })}
          disabled={desabilitarInputs}
        />
        <ConjuntoDeCheckboxes
          label='Eu me declaro'
          options={[
            {
              disabled: desabilitarInputs,
              checked: form.isVinculadoBeegin ?? false,
              onChange: () =>
                setForm({
                  ...form,
                  isVinculadoBeegin: !form.isVinculadoBeegin,
                }),
              label: 'Vinculado à Beegin',
            },
            {
              disabled: desabilitarInputs,
              checked: form.isPoliticamenteExposto ?? false,
              onChange: () =>
                setForm({
                  ...form,
                  isPoliticamenteExposto: !form.isPoliticamenteExposto,
                }),
              label: 'É politicamente exposto',
            },
            {
              disabled: desabilitarInputs,
              checked: form.isAdministradorFundosCarteiras ?? false,
              onChange: () =>
                setForm({
                  ...form,
                  isAdministradorFundosCarteiras: !form.isAdministradorFundosCarteiras,
                }),
              label: 'É administrador de fundos de carteiras',
            },
          ]}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'rendaAnual')}
          required
          mascara='dinheiro'
          label='Renda anual'
          value={form.rendaAnual ?? ''}
          onValueChange={(e) => setForm({ ...form, rendaAnual: e.floatValue ?? undefined })}
          disabled={desabilitarInputs}
        />
        <MaskedInput
          {...getErrorMessageProps(errors, 'valorPatrimonial')}
          required
          mascara='dinheiro'
          label='Valor patrimonial'
          value={form.valorPatrimonial ?? ''}
          onValueChange={(e) => setForm({ ...form, valorPatrimonial: e.floatValue ?? undefined })}
          disabled={desabilitarInputs}
        />
      </PilhaDeItens>
      <Button type='submit' carregando={mutation.isLoading} disabled={desabilitarBotao}>
        Salvar
      </Button>
    </form>
  )
}
